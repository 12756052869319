import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
//-----------------------------------------------------------MUI----------------------------------------------------
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { ExitToApp as ExitToAppIcon, Add as AddIcon } from "@material-ui/icons";
import InfoIcon from "@material-ui/icons/Info";
import HistoryIcon from "@material-ui/icons/History";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Collapse,
  IconButton,
  TablePagination,
  TableHead,
  CircularProgress,
  Select,
  MenuItem,
  TextField,
  Tooltip,
  Button,
} from "@mui/material";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Chat as ChatIcon,
  Description as DescriptionIcon,
  MailOutline as MailOutlineIcon,
  MonetizationOn as MonetizationOnIcon,
  TextFields as TextFieldsIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  InsertDriveFile as DriveFileIcon,
  Close as CloseIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import Co2Icon from "@material-ui/icons/Opacity";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ListIcon from "@mui/icons-material/List";
import Box from "@mui/material/Box";
import CalculateIcon from "@mui/icons-material/Calculate";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import Filter3Icon from "@mui/icons-material/Filter3";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const drawerWidth = "10%";

//-----------------------------------------------------------STYLE----------------------------------------------------
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: "100%",
    overflowX: "auto",
    position: "relative",
  },
  tableContainer: {
    minWidth: 700,
    maxHeight: "calc(100vh - 180px)",
    overflowY: "auto",
  },
  tableHeaderRow: {
    backgroundColor: "#e1f5fe",
    position: "sticky",
    top: 0,
    zIndex: 1,
    fontSize: "18px",
    fontWeight: 800,
  },
  tableHeaderLabel: {
    fontSize: "10px",
    fontWeight: "bold",
  },
  tableTitle: {
    textAlign: "center",
  },
  tableHeaderCell: {
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  loaderContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(255, 255, 255, 0.8)",
  },
  addIcon: {
    marginRight: theme.spacing(1),
  },
  lightRedBackground: {
    backgroundColor: "#ffcccc",
  },
  historySection: {
    fontFamily: "Fonto Bold, Arial, sans-serif",
    fontWeight: "bold",
  },
  boldText: {
    fontWeight: 800,
  },
  customTooltip: {
    fontSize: "30px",
  },
  textFieldRow: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  textField: {
    flex: "0 0 19%",
    marginBottom: theme.spacing(2),
  },
  firstTextField: {
    flex: "0 0 19%",
    marginBottom: theme.spacing(2),
  },

  sortingButtons: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  sortingButton: {
    background: "none",
    border: "none",
    cursor: "pointer",
    padding: 0,
    margin: 0,
    fontSize: "16px",
    color: theme.palette.text.secondary,
    "&.active": {
      color: theme.palette.primary.main,
    },
  },
  redIcon: {
    color: "red",
    fontSize: "18px",
  },
  greenIcon: {
    color: "green",
  },
  blueIcon: {
    color: "#3498db",
    fontSize: "18px",
  },
  filterGroup: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2), // Adjust margin as needed
    overflow: "hidden",
    transition: "max-height 0.3s ease-out", // Transition effect for max-height property
    maxHeight: "500px", // Set max-height to a suitable value when checkboxes are visible
  },

  showCheckboxes: {
    maxHeight: "500px", // Set max-height to a suitable value when checkboxes are visible
  },
}));

const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

const columns = [
  { id: "history", label: "Historia", Width: 50 },
  { id: "edit", label: "Edit", minWidth: 50 },
  { id: "info", label: "Info", minWidth: 50 },
  { id: "znaczniki", label: "Znaczniki", minWidth: 50 },
  { id: "nrRef", label: "Nr. Ref", minWidth: 170 },
  { id: "importer", label: "Importer", minWidth: 170 },
  { id: "eksporter", label: "Eksporter", minWidth: 170 },
  { id: "rodzaj", label: "Rodzaj", minWidth: 170 },
  {
    id: "osobaWysylajacaOdprawe",
    label: "Osoba wysyłająca odprawę",
    minWidth: 170,
  },
  {
    id: "osobaPrzygotowujacaOdprawe",
    label: "Osoba przygotowujaca odprawę",
    minWidth: 170,
  },
  { id: "SAD", label: "SAD", minWidth: 130 },
  { id: "nrAuta", label: "Nr. Auta", minWidth: 170 },
  { id: "lokalizacjaTowaru", label: "Lok. Towaru", minWidth: 170 },
  { id: "statusRealizacji", label: "Status", minWidth: 170 },
  { id: "dokumenty", label: "Dokumenty", minWidth: 170 },
  { id: "dataT1", label: "Data wygaśnięcia T1", minWidth: 170 },
  { id: "utworzono", label: "Data utworzenia", minWidth: 150 },
  { id: "endDate", label: "Data zakończenia", minWidth: 150 },
  { id: "ileWSystemie", label: "Ile w systemie", minWidth: 170 },
  { id: "usun", label: "Usuń", minWidth: 170 },
];

// Function to calculate the differenceString
const calculateDifferenceString = (createdAt, status) => {
  if (
    status === "Zrealizowane" ||
    status === "zrealizowane" ||
    status === "Anulowane"
  ) {
    return "Zakończono";
  }

  const now = moment();
  const createdDate = moment(createdAt);
  const difference = now.diff(createdDate);

  const duration = moment.duration(difference);
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();

  if (days >= 1) {
    return `${days}d ${hours}h ${minutes}m`;
  } else {
    return `${hours}h ${minutes}m`;
  }
};

const generateFilteredColumns = (username) => {
  const originalColumns = [
    { id: "history", label: "Historia", Width: 50 },
    { id: "edit", label: "Edit", minWidth: 50 },
    { id: "info", label: "Info", minWidth: 50 },
    { id: "znaczniki", label: "Znaczniki", minWidth: 50 },
    { id: "nrRef", label: "Nr. Ref", minWidth: 170 },
    { id: "importer", label: "Importer", minWidth: 170 },
    { id: "eksporter", label: "Eksporter", minWidth: 170 },
    { id: "rodzaj", label: "Rodzaj", minWidth: 170 },

    {
      id: "osobaPrzygotowujacaOdprawe",
      label: "Osoba przygotowujaca odprawę",
      minWidth: 170,
    },
    {
      id: "osobaWysylajacaOdprawe",
      label: "Osoba wysyłająca odprawę",
      minWidth: 170,
    },
    {
      id: "osobaMonitorujacaOdprawe",
      label: "Osoba monitorująca odprawę",
      minWidth: 170,
    },
    { id: "SAD", label: "SAD", minWidth: 130 },
    { id: "nrAuta", label: "Nr. Auta", minWidth: 170 },
    { id: "lokalizacjaTowaru", label: "Lok. Towaru", minWidth: 170 },
    { id: "adresatDokumentow", label: "Adresat dokumentów", minWidth: 170 },
    { id: "statusRealizacji", label: "Status", minWidth: 170 },
    { id: "dokumenty", label: "Dokumenty", minWidth: 170 },
    { id: "dataT1", label: "Data wygaśnięcia T1", minWidth: 170 },
    { id: "utworzono", label: "Data utworzenia", minWidth: 150 },
    { id: "endDate", label: "Data zakończenia", minWidth: 150 },
    { id: "ileWSystemie", label: "Ile w systemie", minWidth: 170 },
    { id: "usun", label: "Usuń", minWidth: 170 },
  ];

  // Conditionally filter the columns based on the user's role
  if (
    username === "plomza" ||
    username === "dczernikowska" ||
    username === "Maciej" ||
    username === "adobrowolska"
  ) {
    // If the user is an admin, show all columns
    return originalColumns;
  } else {
    // For other users, exclude the "Usun" column
    return originalColumns.filter((column) => column.id !== "usun");
  }
};

function checkUsername() {
  // Get the username from localStorage
  const storedUsername = localStorage.getItem("username");

  // Define the allowed usernames
  const allowedUsernames = [
    "plomza",
    "dczernikowska",
    "adobrowolska",
    "Maciej",
  ];

  // Check if the stored username is in the allowed usernames
  if (allowedUsernames.includes(storedUsername)) {
    return true;
  } else {
    return false;
  }
}
const isUsernameAllowed = checkUsername(); // Check if the username is allowed

const Row = ({ row }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [differenceString, setDifferenceString] = useState("");
  const [editHistory, setEditHistory] = useState([]);
  const [comments, setComments] = useState([]);

  useEffect(() => {
    // Calculate the initial differenceString
    setDifferenceString(
      calculateDifferenceString(row.utworzono, row.statusRealizacji)
    );
    const intervalId = setInterval(() => {
      setDifferenceString(
        calculateDifferenceString(row.utworzono, row.statusRealizacji)
      );
    }, 60000);

    return () => clearInterval(intervalId);
  }, [row.utworzono, row.statusRealizacji]);

  const handleInfoClick = (event) => {
    // Check if the middle mouse button (button code 1) was clicked
    if (event.button === 1) {
      // Middle mouse button click: Open the link in a new tab
      const url = `/detail/${encodeURIComponent(
        row.nrRef
      )}/${encodeURIComponent(row.id)}`;
      window.open(url, "_blank");
    } else {
      navigate(
        `/detail/${encodeURIComponent(row.nrRef)}/${encodeURIComponent(row.id)}`
      );
    }
  };

  const handleExpandClick = async () => {
    if (!open) {
      try {
        const response = await axios.get(
          `https://wega-api.com/history/${row.id}`,
          {
            headers: headers,
          }
        );
        setEditHistory(response.data);
      } catch (error) {
        console.error("Error fetching edit history:", error);
      }
      try {
        const response = await axios.get(
          `https://wega-api.com/comments/${row.id}`,
          {
            headers: headers,
          }
        );
        setComments(response.data);
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    }

    setOpen(!open);
  };

  const formatDateColumn = (dateTimeString) => {
    // Parse the original date time string using moment
    const originalDateTime = moment(dateTimeString, "YYYY-MM-DDTHH:mm:ss.SSSZ");

    // Format the date in the desired format
    return originalDateTime.format("DD-MM-YYYY, HH:mm");
  };

  const formatDateColumnT1 = (dateTimeString) => {
    // Parse the original date time string using moment
    const originalDateTime = moment(dateTimeString, "YYYY-MM-DDTHH:mm:ss.SSSZ");

    // Format the date in the desired format
    return originalDateTime.format("DD-MM-YYYY, HH:mm");
  };

  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteClick = () => {
    setIsDeleting(!isDeleting);
  };

  const handleConfirmDelete = async () => {
    try {
      // Make an API DELETE request to delete the row by its ID
      await axios.delete(`https://wega-api.com/delete/${row.id}`, {
        headers: headers, // Include your headers here
      });

      setIsDeleting(false);
    } catch (error) {
      console.error("Error deleting row:", error);
      toast.error("Wystąpił błąd podczas usuwania.", {
        autoClose: 3000,
      });
    }
    toast.success("Poprawnie usunięto zgłoszenie!", {
      autoClose: 3000,
    });
    window.location.reload();
  };

  const LightGrayLedIcon = () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="10" fill="#CCCCCC" />
      <circle
        cx="12"
        cy="12"
        r="7"
        fill="none"
        stroke="#CCCCCC"
        strokeWidth="3"
      />
    </svg>
  );
  const RedLedIcon = () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="10" fill="#FF0000" />
    </svg>
  );
  const YellowLedIcon = () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="10" fill="#FFFF00" />
    </svg>
  );
  const WhiteLedIcon = () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="10" fill="#FFFFFF" />
    </svg>
  );

  return (
    <>
      <TableRow hover role="checkbox" tabIndex={-1}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleExpandClick}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell align="center">
          <IconButton
            aria-label="info"
            size="small"
            onMouseDown={handleInfoClick}
          >
            <InfoIcon />
          </IconButton>
        </TableCell>
        <TableCell align="center">
          {row.rodzajTransportu === "Zakończono" ? (
            <Tooltip title="Zakończono">
              <span style={{ fontSize: "16px" }}>
                <WhiteLedIcon />
              </span>
            </Tooltip>
          ) : (
            <Tooltip title={row.rodzajTransportu}>
              <span style={{ fontSize: "16px" }}>
                {row.rodzajTransportu === "Kurier" ? (
                  <YellowLedIcon />
                ) : row.rodzajTransportu === "Na Kołach" ? (
                  <RedLedIcon />
                ) : (
                  <LightGrayLedIcon />
                )}
              </span>
            </Tooltip>
          )}
        </TableCell>

        <TableCell align="center">
          <Tooltip
            title={<span style={{ fontSize: "16px" }}>Faktura</span>}
            arrow
          >
            {row.fakturaBox === 1 ? <DescriptionIcon /> : null}
          </Tooltip>
          <Tooltip
            title={<span style={{ fontSize: "16px" }}>List przewozowy</span>}
            arrow
          >
            {row.listPrzewozowyBox === 1 ? <MailOutlineIcon /> : null}
          </Tooltip>
          <Tooltip
            title={<span style={{ fontSize: "16px" }}>Koszty transportu</span>}
            arrow
          >
            {row.kosztyTransportuBox === 1 ? <MonetizationOnIcon /> : null}
          </Tooltip>
          <Tooltip
            title={
              <span style={{ fontSize: "16px" }}>Informacje o towarze</span>
            }
            arrow
          >
            {row.informacjeOTowarzeBox === 1 ? <InfoIcon /> : null}
          </Tooltip>
          <Tooltip title={<span style={{ fontSize: "16px" }}>T1</span>} arrow>
            {row.T1Box === 1 ? <TextFieldsIcon /> : null}
          </Tooltip>
          <Tooltip
            title={<span style={{ fontSize: "16px" }}>Świadectwo</span>}
            arrow
          >
            {row.swiadectwoBox === 1 ? <AssignmentTurnedInIcon /> : null}
          </Tooltip>
          <Tooltip
            title={
              <span style={{ fontSize: "16px" }}>Pliki do zaczytania</span>
            }
            arrow
          >
            {row.plikiBox === 1 ? (
              <DriveFileIcon className={classes.greenIcon} />
            ) : null}
          </Tooltip>
          <Tooltip
            title={<span style={{ fontSize: "16px" }}>Sankcje</span>}
            arrow
          >
            {row.sankcjeBox === 1 ? (
              <CloseIcon className={classes.redIcon} />
            ) : null}
          </Tooltip>
          <Tooltip title={<span style={{ fontSize: "16px" }}>CBAM</span>} arrow>
            {row.CBAMBox === 1 ? <Co2Icon className={classes.redIcon} /> : null}
          </Tooltip>
          <Tooltip title={<span style={{ fontSize: "16px" }}>CE</span>} arrow>
            {row.CEBox === 1 ? (
              <span className={classes.redIcon}>CE</span>
            ) : null}
          </Tooltip>
          <Tooltip
            title={
              <span style={{ fontSize: "16px" }}>
                Uwagi do działu finansowego - wysłano kolejne zgłoszenie
              </span>
            }
            arrow
          >
            {row.kilkaRazyBox === 1 ? (
              <ContactEmergencyIcon className={classes.blueIcon} />
            ) : null}
          </Tooltip>
          <Tooltip
            title={
              <span style={{ fontSize: "16px" }}>
                Uwagi do działu finansowego - więcej niż 3 pozycje ręcznie
              </span>
            }
            arrow
          >
            {row.wiecejNiz3Box === 1 ? (
              <Filter3Icon className={classes.blueIcon} />
            ) : null}
          </Tooltip>
          <Tooltip
            title={
              <span style={{ fontSize: "16px" }}>
                Uwagi do działu finansowego - odprawa zlecona po godzinie 16:00
              </span>
            }
            arrow
          >
            {row.odprawaPo16Box === 1 ? (
              <AccessTimeIcon className={classes.blueIcon} />
            ) : null}
          </Tooltip>
        </TableCell>
        <TableCell align="center">{row.nrRef}</TableCell>
        <TableCell align="center">{row.importer}</TableCell>
        <TableCell align="center">{row.eksporter}</TableCell>
        <TableCell align="center">{row.rodzaj}</TableCell>
        <TableCell align="center">{row.osobaPrzygotowujacaOdprawe}</TableCell>
        <TableCell align="center">{row.osobaWysylajacaOdprawe}</TableCell>
        <TableCell align="center">{row.osobaMonitorujacaOdprawe}</TableCell>
        <TableCell align="center">{row.SAD}</TableCell>
        <TableCell align="center">{row.nrRejestracyjnyPojazdyKurier}</TableCell>
        <TableCell align="center">{row.lokalizacjaTowaru}</TableCell>
        <TableCell align="center">{row.adresatDokumentow}</TableCell>
        <TableCell align="center">{row.statusRealizacji}</TableCell>
        <TableCell align="center">{row.dokPrzedPrzybyciem}</TableCell>
        <TableCell align="center">
          {formatDateColumn(row.data) === "Invalid date" ||
          formatDateColumn(row.data) === "0000-00-00"
            ? "Brak danych"
            : formatDateColumnT1(row.data)}
        </TableCell>
        <TableCell align="center">
          {formatDateColumn(row.utworzono) === "Invalid date"
            ? "Brak danych"
            : formatDateColumn(row.utworzono)}
        </TableCell>
        <TableCell align="center">
          {formatDateColumn(row.endDate) === "Invalid date" ||
          formatDateColumn(row.endDate) === "0000-00-00"
            ? "Brak danych"
            : formatDateColumnT1(row.endDate)}
        </TableCell>
        <TableCell align="center">{differenceString}</TableCell>
        {isUsernameAllowed && (
          <TableCell align="center">
            {isDeleting ? (
              <>
                <IconButton onClick={handleConfirmDelete}>
                  <DeleteIcon color="secondary" />
                </IconButton>
              </>
            ) : (
              <IconButton onClick={handleDeleteClick}>
                <DeleteIcon />
              </IconButton>
            )}
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: "16px" }}
          colSpan={columns.length + 1}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Typography
              variant="body2"
              gutterBottom
              component="div"
              className={classes}
            >
              <strong className={classes.historySection}>
                Historia zmian w zgłoszeniu:
              </strong>
              <ul>
                {editHistory &&
                  editHistory.map((change) => (
                    <li key={change.id}>
                      <span style={{ fontWeight: 800 }}>Pole: </span>
                      {change.fieldName},{" "}
                      <span style={{ fontWeight: 800 }}> Stara wartość: </span>{" "}
                      {change.oldValue},
                      <span style={{ fontWeight: 800 }}> Nowa wartość: </span>
                      {change.newValue},
                      <span style={{ fontWeight: 800 }}>
                        {" "}
                        Edytowana przez:{" "}
                      </span>{" "}
                      {change.editedBy},
                      <span style={{ fontWeight: 800 }}> Zmieniono dnia: </span>
                      {formatDateColumnT1(change.editedAt)}
                    </li>
                  ))}
              </ul>
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              component="div"
              className={classes}
            >
              <strong className={classes.historySection}>
                Komentarze w zgłoszeniu:
              </strong>
              <ul>
                {comments &&
                  comments.map((comment) => (
                    <li key={comment.id}>
                      <span style={{ fontWeight: 800 }}>Użytkownik: </span>
                      {comment.username},{" "}
                      <span style={{ fontWeight: 800 }}> Komentarz: </span>{" "}
                      {comment.comment},
                      <span style={{ fontWeight: 800 }}> Utworzono: </span>
                      {moment(comment.created_at).format("HH:mm, DD-MM-YYYY")}
                    </li>
                  ))}
              </ul>
            </Typography>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const statusValues = [
  "Wszystko",
  "Do przygotowania",
  "Przygotowane",
  "Do wysłania",
  "Wysłane",
  "Zrealizowane",
  "Anulowane",
];

const rodzajValues = [
  "Wszystko",
  "Import",
  "Eksport",
  "Tranzyt",
  "Import i Eksport",
  "Wyprowadzenie ze składu",
  "Wprowadzenie na skład",
  "Uszlachetnianie czynne",
  "Uszlachetnianie bierne",
  "Powrotny przywóz",
  "Powrotny wywóz",
  "Eksport - wpis do rejestru",
  "Import - wpis do rejestru",
];

const rodzajTransportu = [
  "Wszystko",
  "Kurier",
  "Na Kołach",
  "W drodze",
  "Zakończono",
];
const yesOrNo = ["Wszystko", "Tak", "Nie"];

const Tranzyt = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [notificationCount, setNotificationCount] = useState(0);

  const [osobaWysylajacaOdpraweFilter, setOsobaWysylajacaOdpraweFilter] =
    useState([]);
  const [
    osobaPrzygotowujacaOdpraweFilter,
    setOsobaPrzygotowujacaOdpraweFilter,
  ] = useState([]);
  const [osobaMonitorujacaOdpraweFilter, setosobaMonitorujacaOdpraweFilter] =
    useState([]);
  const [nrRefFilter, setNrRefFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState([]);
  const [nrFakturyFilter, setnrFakturyFilter] = useState("");
  const [rodzajFilter, setRodzajFilter] = useState([]);
  const [lokalizacjaFilter, setLokalizacjaFilter] = useState([]);
  const [rodzajTransportuFilter, setrodzajTransportuFilter] = useState([]);
  const [dateFilter, setDateFilter] = useState("");
  const [importerFilter, setimporterFilter] = useState("");
  const [eksporterFilter, seteksporterFilter] = useState("");
  const [searchText, setSearchText] = useState("");
  const [autoFilter, setAutoFilter] = useState("");
  const [MRNFilter, setMRNFilter] = useState("");
  const [SADFilter, setSADFilter] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dokumentyFilter, setDokumentyFilter] = useState([]);
  const [dataUtworzeniaFilter, setdataUtworzeniaFilter] = useState("");
  const [dataZakonczeniaFilter, setdataZakonczeniaFilter] = useState("");
  const [dataZakonczenia1Filter, setdataZakonczenia1Filter] = useState("");
  const [adresatDokumentow, setAdresatDokumentow] = useState("");

  const username = localStorage.getItem("username");
  const filteredColumns = generateFilteredColumns(username);

  const [lokalizacjTransportu, setlokalizacjTransportu] = useState([]);
  const [osobaWysylajacaOdprawe, setosobaWysylajacaOdprawe] = useState([]);
  const [osobaPrzygotowujacaOdprawe, setosobaPrzygotowujacaOdprawe] = useState(
    []
  );
  const [osobaMonitorujacaOdprawe, setosobaMonitorujacaOdprawe] = useState([]);

  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [showT1, setShowT1] = useState(false);
  const [showEnd, setShowEnd] = useState(false);

  //-------------ZNACZNIKI-----------------
  const [fakturaCheckboxState, setFakturaCheckboxState] = useState(false);
  const [listPrzewozowyCheckboxState, setListPrzewozowyCheckboxState] =
    useState(false);
  const [kosztyTransportuCheckboxState, setKosztyTransportuCheckboxState] =
    useState(false);
  const [informacjeOTowarzeCheckboxState, setInformacjeOTowarzeCheckboxState] =
    useState(false);
  const [T1CheckboxState, setT1CheckboxState] = useState(false);
  const [swiadectwoCheckboxState, setSwiadectwoCheckboxState] = useState(false);
  const [sankcjeCheckboxState, setSankcjeCheckboxState] = useState(false);
  const [CBAMCheckboxState, setCBAMCheckboxState] = useState(false);
  const [CECheckboxState, setCECheckboxState] = useState(false);
  const [plikCheckboxState, setPlikCheckboxState] = useState(false);
  const [kilkaRazyCheckboxState, setKilkaRazyCheckboxState] = useState(false);
  const [wiecejNiz3CheckboxState, setWiecejNiz3CheckboxState] = useState(false);

  //Wyswietlanie odprawe danego uzytkownika
  const [filtersApplied, setFiltersApplied] = useState(
    localStorage.getItem("filtersApplied") === "true" || false
  );

  const [filtersAppliedSAD, setFiltersAppliedSAD] = useState(
    localStorage.getItem("filtersAppliedSAD") === "true" || false
  );

  const [buttonT1Clicked, setButtonT1Clicked] = useState(
    localStorage.getItem("buttonT1Clicked") === "true"
  );

  const [buttonDataClicked, setButtonDataClicked] = useState(
    localStorage.getItem("buttonDataClicked") === "true"
  );

  const handleKilkaRazyCheckboxChange = (event) => {
    setKilkaRazyCheckboxState(event.target.checked);
    window.location.reload();
  };

  const handleWiecejNiz3CheckboxChange = (event) => {
    setWiecejNiz3CheckboxState(event.target.checked);
    window.location.reload();
  };

  // Handle checkbox click
  const handleFakturaCheckboxChange = (event) => {
    setFakturaCheckboxState(event.target.checked);
    window.location.reload();
  };
  // Handle checkbox click
  const handleListPrzewozowyCheckboxChange = (event) => {
    setListPrzewozowyCheckboxState(event.target.checked);
    window.location.reload();
  };
  // Handle checkbox click
  const handlekosztyTransportuCheckboxChange = (event) => {
    setKosztyTransportuCheckboxState(event.target.checked);
    window.location.reload();
  };
  // Handle checkbox click
  const handleInformacjaOTowarzeCheckboxChange = (event) => {
    setInformacjeOTowarzeCheckboxState(event.target.checked);
    window.location.reload();
  };
  // Handle checkbox click
  const handleT1CheckboxChange = (event) => {
    setT1CheckboxState(event.target.checked);
    window.location.reload();
  };
  // Handle checkbox click
  const handleSwiadectwoCheckboxChange = (event) => {
    setSwiadectwoCheckboxState(event.target.checked);
    window.location.reload();
  };
  // Handle checkbox click
  const handleSankcjeCheckboxChange = (event) => {
    setSankcjeCheckboxState(event.target.checked);
    window.location.reload();
  };
  // Handle checkbox click
  const handleCBAMCheckboxChange = (event) => {
    setCBAMCheckboxState(event.target.checked);
    window.location.reload();
  };
  // Handle checkbox click
  const handleCECheckboxChange = (event) => {
    setCECheckboxState(event.target.checked);
    window.location.reload();
  };
  // Handle checkbox click
  const handlePlikiCheckboxChange = (event) => {
    setPlikCheckboxState(event.target.checked);
    window.location.reload();
  };

  const toggleCheckboxes = () => {
    setShowCheckboxes(!showCheckboxes);
  };

  const toggleT1 = () => {
    setButtonT1Clicked((prevValue) => !prevValue);
    setShowT1(!showT1);
  };

  const toggleEnd = () => {
    setButtonDataClicked((prevValue) => !prevValue);
    setShowEnd(!showEnd);
  };

  const inputFormat = "DD-MM-YYYY, HH:mm";
  const formattedDataUtworzeniaFilter = moment(
    dataUtworzeniaFilter,
    inputFormat
  );

  // Define a function to save filter data to localStorage
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveFilterDataToLocalStorage = () => {
    const filters = {
      osobaWysylajacaOdpraweFilter,
      osobaPrzygotowujacaOdpraweFilter,
      osobaMonitorujacaOdpraweFilter,
      lokalizacjaFilter,
      nrRefFilter,
      statusFilter,
      nrFakturyFilter,
      rodzajFilter,
      rodzajTransportuFilter,
      dateFilter,
      importerFilter,
      eksporterFilter,
      autoFilter,
      MRNFilter,
      SADFilter,
      startDate,
      endDate,
      dokumentyFilter,
      dataUtworzeniaFilter,
      dataZakonczeniaFilter,
      dataZakonczenia1Filter,
      fakturaCheckboxState,
      listPrzewozowyCheckboxState,
      kosztyTransportuCheckboxState,
      informacjeOTowarzeCheckboxState,
      T1CheckboxState,
      swiadectwoCheckboxState,
      sankcjeCheckboxState,
      CBAMCheckboxState,
      CECheckboxState,
      plikCheckboxState,
      showCheckboxes,
      showT1,
      showEnd,
      wiecejNiz3CheckboxState,
      kilkaRazyCheckboxState,
      adresatDokumentow,
    };

    localStorage.setItem("filters", JSON.stringify(filters));
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    setNrRefFilter("");
    setStatusFilter([]);
    setnrFakturyFilter("");
    setRodzajFilter([]);
    setrodzajTransportuFilter([]);
    setDateFilter("");
    setimporterFilter("");
    seteksporterFilter("");
    setMRNFilter("");
    setSADFilter("");
    setStartDate("");
    setEndDate("");
    setdataUtworzeniaFilter("");
    setdataZakonczeniaFilter("");
    setdataZakonczenia1Filter("");
    setFakturaCheckboxState(false);
    setListPrzewozowyCheckboxState(false);
    setKosztyTransportuCheckboxState(false);
    setInformacjeOTowarzeCheckboxState(false);
    setT1CheckboxState(false);
    setSwiadectwoCheckboxState(false);
    setSankcjeCheckboxState(false);
    setCBAMCheckboxState(false);
    setCECheckboxState(false);
    setPlikCheckboxState(false);
    setKilkaRazyCheckboxState(false);
    setWiecejNiz3CheckboxState(false);
    setAdresatDokumentow("");
  };

  const handleClearFilters = () => {
    setOsobaWysylajacaOdpraweFilter([]);
    setOsobaPrzygotowujacaOdpraweFilter([]);
    setosobaMonitorujacaOdpraweFilter([]);
    setLokalizacjaFilter([]);
    setStatusFilter([]);
    setRodzajFilter([]);
    setrodzajTransportuFilter([]);
    setDokumentyFilter([]);
  };

  const handleClearData = () => {
    setSearchText("");
    setNrRefFilter("");
    setnrFakturyFilter("");
    setDateFilter("");
    setimporterFilter("");
    seteksporterFilter("");
    setAutoFilter("");
    setMRNFilter("");
    setSADFilter("");
    setStartDate("");
    setEndDate("");
    setAdresatDokumentow("");
    setdataUtworzeniaFilter("");
    setdataZakonczeniaFilter("");
    setdataZakonczenia1Filter("");
    setFakturaCheckboxState(false);
    setListPrzewozowyCheckboxState(false);
    setKosztyTransportuCheckboxState(false);
    setInformacjeOTowarzeCheckboxState(false);
    setT1CheckboxState(false);
    setSwiadectwoCheckboxState(false);
    setSankcjeCheckboxState(false);
    setCBAMCheckboxState(false);
    setCECheckboxState(false);
    setPlikCheckboxState(false);
    setKilkaRazyCheckboxState(false);
    setWiecejNiz3CheckboxState(false);
  };

  // Define a function to load filter data from localStorage
  const loadFilterDataFromLocalStorage = () => {
    const filters = JSON.parse(localStorage.getItem("filters"));

    if (filters) {
      setOsobaWysylajacaOdpraweFilter(filters.osobaWysylajacaOdpraweFilter);
      setosobaMonitorujacaOdpraweFilter(filters.osobaMonitorujacaOdpraweFilter);
      setOsobaPrzygotowujacaOdpraweFilter(
        filters.osobaPrzygotowujacaOdpraweFilter
      );
      setLokalizacjaFilter(filters.lokalizacjaFilter);
      setNrRefFilter(filters.nrRefFilter);
      setStatusFilter(filters.statusFilter);
      setnrFakturyFilter(filters.nrFakturyFilter);
      setRodzajFilter(filters.rodzajFilter);
      setrodzajTransportuFilter(filters.rodzajTransportuFilter);
      setDateFilter(filters.dateFilter);
      setimporterFilter(filters.importerFilter);
      seteksporterFilter(filters.eksporterFilter);
      setAutoFilter(filters.autoFilter);
      setMRNFilter(filters.MRNFilter);
      setSADFilter(filters.SADFilter);
      setStartDate(filters.startDate);
      setEndDate(filters.endDate);
      setDokumentyFilter(filters.dokumentyFilter);
      setdataUtworzeniaFilter(filters.dataUtworzeniaFilter);
      setdataZakonczeniaFilter(filters.dataZakonczeniaFilter);
      setdataZakonczenia1Filter(filters.dataZakonczenia1Filter);
      setFakturaCheckboxState(filters.fakturaCheckboxState);
      setListPrzewozowyCheckboxState(filters.listPrzewozowyCheckboxState);
      setKosztyTransportuCheckboxState(filters.kosztyTransportuCheckboxState);
      setInformacjeOTowarzeCheckboxState(
        filters.informacjeOTowarzeCheckboxState
      );
      setT1CheckboxState(filters.T1CheckboxState);
      setSwiadectwoCheckboxState(filters.swiadectwoCheckboxState);
      setSankcjeCheckboxState(filters.sankcjeCheckboxState);
      setCBAMCheckboxState(filters.CBAMCheckboxState);
      setCECheckboxState(filters.CECheckboxState);
      setPlikCheckboxState(filters.plikCheckboxState);
      setShowCheckboxes(filters.showCheckboxes);
      setShowT1(filters.showT1);
      setShowEnd(filters.showEnd);
      setKilkaRazyCheckboxState(filters.kilkaRazyCheckboxState);
      setWiecejNiz3CheckboxState(filters.wiecejNiz3CheckboxState);
      setAdresatDokumentow(filters.adresatDokumentow);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem("filters");
    localStorage.removeItem("filtersApplied");
    localStorage.removeItem("filtersAppliedSAD");
    navigate("/login");
    toast.success("Logged out successfully!", {
      autoClose: 3000,
    });
  };

  const fetchProtectedData = async () => {
    try {
      const updatedResponse = await axios.get(
        `https://wega-api.com/tranzytData`,
        {
          headers: headers,
        }
      );
      setApiData(updatedResponse.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching protected data:", error.response?.data);
      if (error.response?.status === 401) {
        handleLogout();
      } else {
        setLoading(false);
      }
    }
  };
  const fetchTransportLocalization = async () => {
    try {
      const response = await axios.get(
        `https://wega-api.com/list/lokalizacjaTransportow`,
        {
          headers: headers,
        }
      );
      // Sort the data alphabetically by name using localeCompare
      const sortedData = response.data.sort((a, b) => {
        return a.name.localeCompare(b.name, "pl", { sensitivity: "base" });
      });
      // Set the sorted data in the state
      setlokalizacjTransportu(sortedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchOsobaRealizujaca = async () => {
    try {
      const response = await axios.get(
        `https://wega-api.com/list/uzytkownicy`,
        {
          headers: headers,
        }
      );
      // Sort the data alphabetically by name using localeCompare
      const sortedData = response.data.sort((a, b) => {
        return a.name.localeCompare(b.name, "pl", { sensitivity: "base" });
      });
      setosobaWysylajacaOdprawe(sortedData);
      setosobaPrzygotowujacaOdprawe(sortedData);
      setosobaMonitorujacaOdprawe(sortedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    // Fetch the protected data when the component mounts
    fetchProtectedData();
    // Load filter data from localStorage when the component mounts
    loadFilterDataFromLocalStorage();
    fetchTransportLocalization();
    fetchOsobaRealizujaca();
    // Fetch notifications when the component mounts
    //fetchNotifications();
    // Set up an interval to fetch notifications every 30 seconds
    //const interval = setInterval(() => {
    //  fetchNotifications();
    //}, 30000);
    // Clean up the interval on component unmount to prevent memory leaks
    //return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const applyFilter = (data) => {
    let filteredData = data;

    if (
      (osobaWysylajacaOdpraweFilter &&
        osobaWysylajacaOdpraweFilter.length > 0) ||
      (osobaPrzygotowujacaOdpraweFilter &&
        osobaPrzygotowujacaOdpraweFilter.length > 0) ||
      (osobaMonitorujacaOdpraweFilter &&
        osobaMonitorujacaOdpraweFilter.length > 0)
    ) {
      filteredData = filteredData.filter(
        (item) =>
          (osobaWysylajacaOdpraweFilter &&
            osobaWysylajacaOdpraweFilter.includes(
              item.osobaWysylajacaOdprawe
            )) ||
          (osobaPrzygotowujacaOdpraweFilter &&
            osobaPrzygotowujacaOdpraweFilter.includes(
              item.osobaPrzygotowujacaOdprawe
            )) ||
          (osobaMonitorujacaOdpraweFilter &&
            osobaMonitorujacaOdpraweFilter.includes(
              item.osobaMonitorujacaOdprawe
            ))
      );
    } else {
      if (
        osobaWysylajacaOdpraweFilter &&
        osobaWysylajacaOdpraweFilter.length > 0
      ) {
        filteredData = filteredData.filter((item) =>
          osobaWysylajacaOdpraweFilter.includes(item.osobaWysylajacaOdprawe)
        );
      }

      if (osobaPrzygotowujacaOdpraweFilter.length > 0) {
        filteredData = filteredData.filter((item) =>
          osobaPrzygotowujacaOdpraweFilter.includes(
            item.osobaPrzygotowujacaOdprawe
          )
        );
      }

      if (
        osobaMonitorujacaOdpraweFilter &&
        osobaMonitorujacaOdpraweFilter.length > 0
      ) {
        filteredData = filteredData.filter((item) =>
          osobaMonitorujacaOdpraweFilter.includes(item.osobaMonitorujacaOdprawe)
        );
      }
    }

    if (lokalizacjaFilter && lokalizacjaFilter.length > 0) {
      filteredData = filteredData.filter((item) =>
        lokalizacjaFilter.includes(item.lokalizacjaTowaru)
      );
    }

    if (statusFilter.length > 0) {
      filteredData = filteredData.filter((item) =>
        statusFilter.includes(item.statusRealizacji)
      );
    }

    if (rodzajFilter.length > 0) {
      filteredData = filteredData.filter((item) =>
        rodzajFilter.includes(item.rodzaj)
      );
    }
    if (dokumentyFilter.length > 0) {
      filteredData = filteredData.filter((item) =>
        dokumentyFilter.includes(item.dokPrzedPrzybyciem)
      );
    }

    if (nrRefFilter) {
      filteredData = filteredData.filter((item) =>
        item.nrRef.toLowerCase().includes(nrRefFilter.toLowerCase())
      );
    }
    if (
      nrFakturyFilter &&
      filteredData.some(
        (item) => item.nrFaktury !== null && item.nrFaktury !== undefined
      )
    ) {
      filteredData = filteredData.filter(
        (item) =>
          item.nrFaktury &&
          item.nrFaktury.toUpperCase().includes(nrFakturyFilter.toUpperCase())
      );
    }

    if (
      adresatDokumentow &&
      filteredData.some(
        (item) =>
          item.adresatDokumentow !== null &&
          <item className="adr"></item> !== undefined
      )
    ) {
      filteredData = filteredData.filter(
        (item) =>
          item.adresatDokumentow &&
          item.adresatDokumentow
            .toUpperCase()
            .includes(adresatDokumentow.toUpperCase())
      );
    }

    if (dateFilter) {
      filteredData = filteredData.filter((item) =>
        moment(item.utworzono).format("DD-MM-YYYY").includes(dateFilter)
      );
    }

    if (
      importerFilter &&
      filteredData.some(
        (item) => item.importer !== null && item.importer !== undefined
      )
    ) {
      const lowerImporterFilter = importerFilter.toLowerCase();
      filteredData = filteredData.filter(
        (item) =>
          item.importer &&
          item.importer.toLowerCase().includes(lowerImporterFilter)
      );
    }
    if (
      eksporterFilter &&
      filteredData.some(
        (item) => item.eksporter !== null && item.eksporter !== undefined
      )
    ) {
      const lowerEksporterFilter = eksporterFilter.toLowerCase();
      filteredData = filteredData.filter(
        (item) =>
          item.eksporter &&
          item.eksporter.toLowerCase().includes(lowerEksporterFilter)
      );
    }
    if (
      autoFilter &&
      filteredData.some(
        (item) =>
          item.nrRejestracyjnyPojazdyKurier !== null &&
          item.nrRejestracyjnyPojazdyKurier !== undefined
      )
    ) {
      const lowerAutoFilter = autoFilter.toLowerCase();
      filteredData = filteredData.filter(
        (item) =>
          item.nrRejestracyjnyPojazdyKurier &&
          item.nrRejestracyjnyPojazdyKurier
            .toLowerCase()
            .includes(lowerAutoFilter)
      );
    }
    if (
      MRNFilter &&
      filteredData.some((item) => item.MRN !== null && item.MRN !== undefined)
    ) {
      const lowerMRNFilter = MRNFilter.toLowerCase();
      filteredData = filteredData.filter(
        (item) => item.MRN && item.MRN.toLowerCase().includes(lowerMRNFilter)
      );
    }

    if (SADFilter === "empty") {
      // If SADFilter is "empty", filter rows with empty SAD values
      filteredData = filteredData.filter((item) => !item.SAD);
    } else if (SADFilter !== "") {
      // If SADFilter is not empty and not "empty", filter based on SAD values
      const lowerSADFilter = SADFilter.toLowerCase();
      filteredData = filteredData.filter(
        (item) => item.SAD && item.SAD.toLowerCase().includes(lowerSADFilter)
      );
    }

    if (rodzajTransportuFilter.length > 0) {
      filteredData = filteredData.filter((item) =>
        rodzajTransportuFilter.includes(item.rodzajTransportu)
      );
    }

    if (startDate && endDate) {
      // Set selectedStartDate to the beginning of the day (00:00:00) in Europe/Warsaw time zone
      const selectedStartDate = new Date(startDate + "T00:00:00");

      // Set selectedEndDate to the end of the day (23:59:59.999) in Europe/Warsaw time zone
      const selectedEndDate = new Date(endDate + "T23:59:59.999");

      // Convert Date objects to string representations in Europe/Warsaw time zone
      const startDateString = selectedStartDate.toLocaleString(undefined, {
        timeZone: "Europe/Warsaw",
      });
      const endDateString = selectedEndDate.toLocaleString(undefined, {
        timeZone: "Europe/Warsaw",
      });

      console.log("Data 1 T1", startDateString);
      console.log("Data 2 T1", endDateString);

      filteredData = filteredData.filter((item) => {
        const dataWygasniecia = new Date(item["data"]);

        // Include data from selectedStartDate to selectedEndDate (inclusive)
        return (
          dataWygasniecia >= selectedStartDate &&
          dataWygasniecia <= selectedEndDate
        );
      });
    }

    if (dataUtworzeniaFilter) {
      filteredData = filteredData.filter((item) =>
        moment(item.utworzono, inputFormat).isSame(
          formattedDataUtworzeniaFilter,
          "day"
        )
      );
    }

    // if (dataZakonczeniaFilter) {
    //   filteredData = filteredData.filter((item) =>
    //     moment(item.endDate, inputFormat).isSame(
    //       formattedDataZakonczeniaFilter,
    //       "day"
    //     )
    //   );
    // }

    if (dataZakonczeniaFilter && dataZakonczenia1Filter) {
      // Set selectedStartDate to the beginning of the day (00:00:00)
      const selectedStartDate = new Date(dataZakonczenia1Filter + "T00:00:00");

      // Set selectedEndDate to the end of the day (23:59:59.999)
      const selectedEndDate = new Date(dataZakonczeniaFilter + "T23:59:59.999");

      // Convert Date objects to string representations
      const startDateString = selectedStartDate.toISOString();
      const endDateString = selectedEndDate.toISOString();
      console.log("Data 1 ", startDateString);
      console.log("Data 2 ", endDateString);

      filteredData = filteredData.filter((item) => {
        const dataWygasniecia = new Date(item["endDate"]);

        // Include data from selectedStartDate to selectedEndDate (inclusive)
        return (
          dataWygasniecia >= selectedStartDate &&
          dataWygasniecia <= selectedEndDate
        );
      });
    }

    // Checkboxes filter logic
    const checkboxFilters = {
      faktura: fakturaCheckboxState,
      listPrzewozowy: listPrzewozowyCheckboxState,
      kosztyTransportu: kosztyTransportuCheckboxState,
      informacjeOTowarze: informacjeOTowarzeCheckboxState,
      T1: T1CheckboxState,
      swiadectwo: swiadectwoCheckboxState,
      sankcje: sankcjeCheckboxState,
      CBAM: CBAMCheckboxState,
      CE: CECheckboxState,
      pliki: plikCheckboxState,
      wiecejNiz3: wiecejNiz3CheckboxState,
      kilkaRazy: kilkaRazyCheckboxState,
    };

    // Get an array of checkbox names that are checked
    const checkedCheckboxes = Object.entries(checkboxFilters)
      .filter(([checkbox, isChecked]) => isChecked)
      .map(([checkbox]) => checkbox);

    // Filter rows based on checked checkboxes
    if (checkedCheckboxes.length > 0) {
      filteredData = filteredData.filter((item) => {
        // Check if the row contains all checked checkboxes
        return checkedCheckboxes.every((checkbox) => {
          const checkboxPropertyName = `${checkbox}Box`; // Adjust property name
          return item[checkboxPropertyName] === 1; // Assuming 1 means checked in your data
        });
      });
    }

    return filteredData;
  };

  const filteredData = applyFilter(apiData).filter((row) =>
    Object.values(row)
      .join(" ")
      .toLowerCase()
      .includes(searchText.toLowerCase())
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const pageCount = Math.ceil(filteredData.length / rowsPerPage);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  function convertToCSV(data) {
    const header = Object.keys(data[0]);
    const csv = [
      header.join(";"),
      ...data.map((row) =>
        header.map((fieldName) => JSON.stringify(row[fieldName])).join(";")
      ),
    ].join("\n");
    return csv;
  }

  function handleExportCSV() {
    const filteredAndPaginatedData = applyFilter(apiData)
      .filter((row) =>
        Object.values(row)
          .join(" ")
          .toLowerCase()
          .includes(searchText.toLowerCase())
      )
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const csvData = convertToCSV(filteredAndPaginatedData);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }
  useEffect(() => {
    localStorage.setItem("filtersApplied", filtersApplied);
    localStorage.setItem("buttonT1Clicked", buttonT1Clicked);
    localStorage.setItem("buttonDataClicked", buttonDataClicked);
    localStorage.setItem("filtersAppliedSAD", filtersAppliedSAD);
  }, [filtersApplied, buttonT1Clicked, buttonDataClicked, filtersAppliedSAD]);

  const applyFiltersSAD = () => {
    // Apply filters
    setrodzajTransportuFilter(["Kurier", "Na Kołach", "W drodze"]);
    setSADFilter("empty"); // Set SADFilter to an empty string

    // Set filtersApplied state to true
    setFiltersAppliedSAD(true);

    window.location.reload();
  };

  const clearFiltersSAD = () => {
    // Clear filters
    setrodzajTransportuFilter([]);
    setSADFilter("");

    // Set filtersApplied state to false
    setFiltersAppliedSAD(false);

    window.location.reload();
  };

  useEffect(() => {
    // Save filter data to localStorage whenever any filter value changes
    saveFilterDataToLocalStorage();
  }, [
    osobaMonitorujacaOdpraweFilter,
    osobaWysylajacaOdpraweFilter,
    osobaPrzygotowujacaOdpraweFilter,
    lokalizacjaFilter,
    nrRefFilter,
    statusFilter,
    nrFakturyFilter,
    rodzajFilter,
    rodzajTransportuFilter,
    dateFilter,
    importerFilter,
    eksporterFilter,
    autoFilter,
    saveFilterDataToLocalStorage,
    dataUtworzeniaFilter,
    dataZakonczeniaFilter,
    dataZakonczenia1Filter,
    fakturaCheckboxState,
    listPrzewozowyCheckboxState,
    kosztyTransportuCheckboxState,
    informacjeOTowarzeCheckboxState,
    T1CheckboxState,
    swiadectwoCheckboxState,
    sankcjeCheckboxState,
    CBAMCheckboxState,
    CECheckboxState,
    plikCheckboxState,
    showCheckboxes,
    showT1,
    showEnd,
    kilkaRazyCheckboxState,
    wiecejNiz3CheckboxState,
    adresatDokumentow,
  ]);

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar style={{ minHeight: 3 }} />
        <List>
          <ListItem button onClick={() => navigate("/user")}>
            <ListItemIcon>
              <ArrowBackIcon />
            </ListItemIcon>
            <ListItemText primary="Powrót do tabeli" />
          </ListItem>
          <ListItem button onClick={() => navigate("/login")}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Wyloguj" />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <Toolbar style={{ minHeight: 3 }} />
        <Typography variant="h5" component="h1" className={classes.tableTitle}>
          DutyStation Tranzyt
        </Typography>
        <div className={classes.textFieldRow}>
          <TextField
            className={classes.firstTextField}
            label="Wyszukaj"
            variant="outlined"
            size="small"
            value={searchText}
            onChange={handleSearchChange}
            autoComplete="off"
          />
          <TextField
            className={classes.textField}
            label="Nr. Ref"
            variant="outlined"
            size="small"
            value={nrRefFilter}
            onChange={(e) => setNrRefFilter(e.target.value)}
            autoComplete="off"
          />
          <TextField
            className={classes.textField}
            label="Nr. Faktury"
            variant="outlined"
            size="small"
            value={nrFakturyFilter}
            onChange={(e) => setnrFakturyFilter(e.target.value)}
            autoComplete="off"
          />
          <TextField
            className={classes.textField}
            label="Wyszukaj po dacie"
            variant="outlined"
            size="small"
            style={{ width: "350px" }}
            value={dateFilter}
            onChange={(e) => setDateFilter(e.target.value)}
            autoComplete="off"
          />
          <TextField
            className={classes.textField}
            label="Importer"
            variant="outlined"
            size="small"
            value={importerFilter}
            onChange={(e) => setimporterFilter(e.target.value)}
            autoComplete="off"
          />
          <TextField
            className={classes.textField}
            label="Eksporter"
            variant="outlined"
            size="small"
            value={eksporterFilter}
            onChange={(e) => seteksporterFilter(e.target.value)}
            autoComplete="off"
          />
          <TextField
            className={classes.textField}
            label="Nr. Auta"
            variant="outlined"
            size="small"
            value={autoFilter}
            onChange={(e) => setAutoFilter(e.target.value)}
            autoComplete="off"
          />
          <TextField
            className={classes.textField}
            label="MRN"
            variant="outlined"
            size="small"
            value={MRNFilter}
            onChange={(e) => setMRNFilter(e.target.value)}
            autoComplete="off"
          />
          <TextField
            className={classes.textField}
            label="SAD"
            variant="outlined"
            size="small"
            value={SADFilter}
            onChange={(e) => setSADFilter(e.target.value)}
            autoComplete="off"
          />
          <TextField
            className={classes.textField}
            label="Data utworzenia"
            variant="outlined"
            size="small"
            type="date"
            value={dataUtworzeniaFilter}
            autoComplete="off"
            onChange={(e) => {
              setdataUtworzeniaFilter(e.target.value);
              window.location.reload();
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            className={classes.textField}
            label="Adresat dokumentów"
            variant="outlined"
            size="small"
            value={adresatDokumentow}
            onChange={(e) => setAdresatDokumentow(e.target.value)}
            autoComplete="off"
          />
        </div>
        <div className={classes.textFieldRow}>
          <div
            className={
              classes.filterGroup +
              (showCheckboxes ? " " + classes.showCheckboxes : "")
            }
          >
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button
                variant="contained"
                color={buttonDataClicked ? "success" : "primary"}
                onClick={toggleEnd}
              >
                {buttonDataClicked
                  ? "Filtrowanie dat zakończenia"
                  : "Filtrowanie dat zakończenia"}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={toggleCheckboxes}
              >
                Filtrowanie znaczników
              </Button>
              {filtersAppliedSAD ? (
                <Button
                  variant="contained"
                  sx={{ bgcolor: "#cc0000" }}
                  onClick={clearFiltersSAD}
                >
                  Pokaż wszystkie odprawy
                </Button>
              ) : (
                <Button variant="contained" onClick={applyFiltersSAD}>
                  Pokaż odprawy bez numeru SAD
                </Button>
              )}
            </Box>
          </div>
          {showEnd && (
            <>
              <div
                className={classes.textFieldRow}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="h7" style={{ marginRight: "10px" }}>
                  Data zakończenia: Podaj przedział dat od{" "}
                </Typography>
                <TextField
                  className={classes.textField}
                  label="Data zakończenia 1"
                  variant="outlined"
                  size="small"
                  type="date"
                  value={dataZakonczenia1Filter}
                  autoComplete="off"
                  onChange={(e) => {
                    setdataZakonczenia1Filter(e.target.value);
                    //window.location.reload();
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Typography variant="h7" style={{ marginRight: "10px" }}>
                  do{" "}
                </Typography>
                <TextField
                  className={classes.textField}
                  label="Data zakończenia 2"
                  variant="outlined"
                  size="small"
                  type="date"
                  value={dataZakonczeniaFilter}
                  autoComplete="off"
                  onChange={(e) => {
                    setdataZakonczeniaFilter(e.target.value);
                    window.location.reload();
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Typography variant="subtitle2" style={{ marginRight: "10px" }}>
                  *Musisz podać obie daty aby poprawnie wyfiltorwać
                </Typography>
              </div>
            </>
          )}

          {showCheckboxes && (
            <>
              <div className={classes.textFieldRow}>
                <div className={classes.filterGroup}>
                  <Typography variant="h7" style={{ marginRight: "10px" }}>
                    Braki w odprawie celnej:{" "}
                  </Typography>
                  <FormControlLabel
                    control={<Checkbox name="faktura" color="primary" />}
                    label="Faktura"
                    checked={fakturaCheckboxState}
                    onChange={handleFakturaCheckboxChange}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox name="List przewozowy" color="primary" />
                    }
                    label="List przewozowy"
                    checked={listPrzewozowyCheckboxState}
                    onChange={handleListPrzewozowyCheckboxChange}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox name="Koszty transportu" color="primary" />
                    }
                    label="Koszty transportu"
                    checked={kosztyTransportuCheckboxState}
                    onChange={handlekosztyTransportuCheckboxChange}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox name="Informacje o towarze" color="primary" />
                    }
                    label="Informacje o towarze"
                    checked={informacjeOTowarzeCheckboxState}
                    onChange={handleInformacjaOTowarzeCheckboxChange}
                  />
                  <FormControlLabel
                    control={<Checkbox name="T1" color="primary" />}
                    label="T1"
                    checked={T1CheckboxState}
                    onChange={handleT1CheckboxChange}
                  />
                  <FormControlLabel
                    control={<Checkbox name="Świadectwo" color="primary" />}
                    label="Świadectwo"
                    checked={swiadectwoCheckboxState}
                    onChange={handleSwiadectwoCheckboxChange}
                  />
                </div>
              </div>
              <div className={classes.textFieldRow}>
                <div className={classes.filterGroup}>
                  <Typography variant="h7" style={{ marginRight: "10px" }}>
                    Towary podlegające dodatkowym ograniczeniom:
                  </Typography>
                  <FormControlLabel
                    control={<Checkbox name="Sankcje" color="primary" />}
                    label="Sankcje"
                    checked={sankcjeCheckboxState}
                    onChange={handleSankcjeCheckboxChange}
                  />
                  <FormControlLabel
                    control={<Checkbox name="CBAM" color="primary" />}
                    label="CBAM"
                    checked={CBAMCheckboxState}
                    onChange={handleCBAMCheckboxChange}
                  />
                  <FormControlLabel
                    control={<Checkbox name="CE" color="primary" />}
                    label="CE"
                    checked={CECheckboxState}
                    onChange={handleCECheckboxChange}
                  />
                </div>
              </div>
              <div className={classes.textFieldRow}>
                <div className={classes.filterGroup}>
                  <Typography variant="h7" style={{ marginRight: "10px" }}>
                    Przygotowany plik do zaczytania:
                  </Typography>
                  <FormControlLabel
                    control={<Checkbox name="Plik" color="primary" />}
                    label="Plik do zaczytania"
                    checked={plikCheckboxState}
                    onChange={handlePlikiCheckboxChange}
                  />
                </div>
              </div>
              <div className={classes.textFieldRow}>
                <div className={classes.filterGroup}>
                  <Typography variant="h7" style={{ marginRight: "10px" }}>
                    Uwagi do działu finansowego:
                  </Typography>
                  <FormControlLabel
                    control={<Checkbox name="wiecejNiz3" color="primary" />}
                    label="Więcej niż 3 pozycje na fakturze wpisane ręcznie"
                    checked={wiecejNiz3CheckboxState}
                    onChange={handleWiecejNiz3CheckboxChange}
                  />
                  <FormControlLabel
                    control={<Checkbox name="kilkaRazy" color="primary" />}
                    label="Odprawa wysłana kilka razy ze wzgędu na błąd klienta"
                    checked={kilkaRazyCheckboxState}
                    onChange={handleKilkaRazyCheckboxChange}
                  />
                </div>
              </div>
            </>
          )}
        </div>

        <div className={classes.textFieldRow}>
          <Button
            className=""
            variant="contained"
            color="primary"
            onClick={handleClearData}
          >
            Wyczyść pola <ArrowDropUpIcon />
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClearFilters}
          >
            Wyczyść filtry <ArrowDropDownIcon />
          </Button>

          <Button variant="contained" color="primary" onClick={handleExportCSV}>
            Export to CSV
          </Button>
        </div>

        <TableContainer component={Paper} className={classes.tableContainer}>
          {loading && (
            // Show loader container only when loading is true
            <div className={classes.loaderContainer}>
              <CircularProgress color="secondary" />
              <Typography variant="body1">Loading...</Typography>
            </div>
          )}
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {filteredColumns.map((column) => (
                  <TableCell
                    key={column.id}
                    align="center"
                    className={classes.tableHeaderCell}
                    style={{ backgroundColor: "#e1f5fe" }}
                  >
                    {column.id === "info" ? (
                      <>
                        <Tooltip
                          title={
                            <span style={{ fontSize: "16px" }}>
                              Jeśli lampka jest w kolorze czerwony to znaczy, że
                              odprawa jest na kołach i czeka na odprawe. Kolor
                              żółty lampki oznacza, że jest to kurier, a kolor
                              szary to transport w drodze.
                            </span>
                          }
                          arrow
                          classes={{ tooltip: classes.customTooltip }}
                        >
                          <Typography
                            variant="h6"
                            style={{ fontWeight: 800, fontSize: "16px" }}
                          >
                            {column.label}
                          </Typography>
                        </Tooltip>
                        <Select
                          multiple
                          value={rodzajTransportuFilter}
                          onChange={(e) => {
                            const selectedValues = e.target.value;
                            if (selectedValues.includes("Wszystko")) {
                              setrodzajTransportuFilter([]);
                            } else {
                              setrodzajTransportuFilter(selectedValues);
                              window.location.reload();
                            }
                          }}
                          variant="outlined"
                          size="small"
                          displayEmpty
                        >
                          {rodzajTransportu.map((value) => (
                            <MenuItem key={value} value={value}>
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    ) : column.id === "znaczniki" ? (
                      <>
                        <Tooltip
                          title={
                            <span style={{ fontSize: "16px" }}>
                              Kolor czarny ikon - braki w odprawie, czerwony -
                              dodatkowe ograniczenia, zielony - przygotowano
                              plik .xls. Kolor niebieski - uwagi do działu
                              finansowego
                            </span>
                          }
                          arrow
                          classes={{ tooltip: classes.customTooltip }}
                        >
                          <Typography
                            variant="h6"
                            style={{ fontWeight: 800, fontSize: "16px" }}
                          >
                            {column.label}
                          </Typography>
                        </Tooltip>
                      </>
                    ) : (
                      <>
                        <Typography
                          variant="h6"
                          style={{ fontWeight: 800, fontSize: "16px" }}
                        >
                          {column.label}
                        </Typography>
                      </>
                    )}

                    {column.id === "osobaWysylajacaOdprawe" && (
                      <Select
                        multiple
                        value={osobaWysylajacaOdpraweFilter}
                        onChange={(e) => {
                          const selectedValues = e.target.value;
                          if (selectedValues.includes("Wszystko")) {
                            setOsobaWysylajacaOdpraweFilter([]);
                          } else {
                            setOsobaWysylajacaOdpraweFilter(selectedValues);
                            window.location.reload();
                          }
                        }}
                        variant="outlined"
                        size="small"
                        displayEmpty
                      >
                        <MenuItem value="Wszystko">Wszystko</MenuItem>
                        {osobaWysylajacaOdprawe.map((item) => (
                          <MenuItem key={item.id} value={item.name}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}

                    {column.id === "osobaMonitorujacaOdprawe" && (
                      <Select
                        multiple
                        value={osobaMonitorujacaOdpraweFilter}
                        onChange={(e) => {
                          const selectedValues = e.target.value;
                          if (selectedValues.includes("Wszystko")) {
                            setosobaMonitorujacaOdpraweFilter([]);
                          } else {
                            setosobaMonitorujacaOdpraweFilter(selectedValues);
                            window.location.reload();
                          }
                        }}
                        variant="outlined"
                        size="small"
                        displayEmpty
                      >
                        <MenuItem value="Wszystko">Wszystko</MenuItem>
                        {osobaMonitorujacaOdprawe.map((item) => (
                          <MenuItem key={item.id} value={item.name}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}

                    {column.id === "statusRealizacji" && (
                      <Select
                        multiple
                        value={statusFilter}
                        onChange={(e) => {
                          const selectedValues = e.target.value;
                          if (selectedValues.includes("Wszystko")) {
                            setStatusFilter([]);
                          } else {
                            setStatusFilter(selectedValues);
                            window.location.reload();
                          }
                        }}
                        variant="outlined"
                        size="small"
                        displayEmpty
                      >
                        {statusValues.map((value) => (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                    )}

                    {column.id === "osobaPrzygotowujacaOdprawe" && (
                      <Select
                        multiple
                        value={osobaPrzygotowujacaOdpraweFilter}
                        onChange={(e) => {
                          const selectedValues = e.target.value;
                          if (selectedValues.includes("Wszystko")) {
                            setOsobaPrzygotowujacaOdpraweFilter([]);
                          } else {
                            setOsobaPrzygotowujacaOdpraweFilter(selectedValues);
                            window.location.reload();
                          }
                        }}
                        variant="outlined"
                        size="small"
                        displayEmpty
                      >
                        <MenuItem value="Wszystko">Wszystko</MenuItem>
                        {osobaPrzygotowujacaOdprawe.map((item) => (
                          <MenuItem key={item.id} value={item.name}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}

                    {column.id === "lokalizacjaTowaru" && (
                      <Select
                        multiple
                        value={lokalizacjaFilter}
                        onChange={(e) => {
                          const selectedValues = e.target.value;
                          if (selectedValues.includes("Wszystko")) {
                            setLokalizacjaFilter([]);
                          } else {
                            setLokalizacjaFilter(selectedValues);
                            window.location.reload();
                          }
                        }}
                        variant="outlined"
                        size="small"
                        displayEmpty
                      >
                        <MenuItem value="Wszystko">Wszystko</MenuItem>
                        {lokalizacjTransportu.map((item) => (
                          <MenuItem key={item.id} value={item.name}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}

                    {column.id === "dokumenty" && (
                      <Select
                        multiple
                        value={dokumentyFilter}
                        onChange={(e) => {
                          const selectedValues = e.target.value;
                          if (selectedValues.includes("Wszystko")) {
                            setDokumentyFilter([]);
                          } else {
                            setDokumentyFilter(selectedValues);
                            window.location.reload();
                          }
                        }}
                        variant="outlined"
                        size="small"
                        displayEmpty
                      >
                        {yesOrNo.map((value) => (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((rowData) => (
                  <Row key={rowData.nrRef} row={rowData} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          nextIconButtonProps={{
            disabled: page >= pageCount - 1,
          }}
        />
      </main>
      <ToastContainer position="top-right" />
    </div>
  );
};

export default Tranzyt;
