import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import styles from "./styles.module.css";

const Signup = () => {
  const [data, setData] = useState({
    username: "",
    password: "",
    email: "",
  });
  const [error, setError] = useState("");
  const [isSignUpSuccess, setSignUpSuccess] = useState(false);
  const navigate = useNavigate();

  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input.name]: input.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Clear the error state when submitting

    try {
      const url = "https://wega-api.com/register";
      const { data: res } = await axios.post(url, data);

      console.log(res.message);
      setSignUpSuccess(true);
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setError(error.response.data.message);
        toast.error(error.response.data.message); // Show error toast
      }
    }
  };

  // Use useEffect to handle redirection after login success
  useEffect(() => {
    if (isSignUpSuccess) {
      // Show a toast notification for successful login
      toast.success("Register successful!", {
        autoClose: 2000, // Set the timeout for the toast (in milliseconds)
        onClose: () => {
          // Navigate to /user after toast is closed
          navigate("/login");
        },
      });
    }
  }, [isSignUpSuccess, navigate]);

  return (
    <div className={styles.signup_container}>
      <div className={styles.signup_form_container}>
        <div className={styles.left}>
          <h1>Masz już konto?</h1>
          <p>
            Zaloguj się używając adresu email i hasła podanego przy rejestracji.
          </p>
          <Link to="/login">
            <button type="button" className={styles.login_btn}>
              Logowanie
            </button>
          </Link>
        </div>
        <div className={styles.right}>
          <form className={styles.form_container} onSubmit={handleSubmit}>
            <h1>Rejestracja</h1>
            <input
              type="text"
              placeholder="Nazwa użytkownika"
              name="username"
              onChange={handleChange}
              value={data.username}
              required
              className={styles.input}
            />
            <input
              type="password"
              placeholder="Hasło"
              name="password"
              onChange={handleChange}
              value={data.password}
              required
              className={styles.input}
            />
            <input
              type="email"
              placeholder="Email"
              name="email"
              onChange={handleChange}
              value={data.email}
              required
              className={styles.input}
            />

            {error && <div className={styles.error_msg}>{error}</div>}
            <button type="submit" className={styles.register_btn}>
              Zarejestruj się
            </button>
            <ToastContainer />
          </form>
        </div>
      </div>
    </div>
  );
};
export default Signup;
