import React, { useState } from "react";
import axios from "axios";

const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

const AgregateData = () => {
  const [file, setFile] = useState();

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        "https://wega-api.com/uploadAndAgregateData",
        formData,
        {
          responseType: "blob", // Important for handling the binary file download
        },
        { headers }
      );
      // Create a URL for the blob to trigger download
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/zip" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "skumulowane_kody.zip"); // Change the file name to reflect .zip download
      document.body.appendChild(link);
      link.click();

      // Cleanup: remove the link after triggering download
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <div style={{ maxWidth: "500px", margin: "0", padding: "10px" }}>
      <h2 style={{ textAlign: "left", color: "#333", fontSize: "18px" }}>
        Agregacja tych samych kodów do różnych plików csv
      </h2>
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <input
          type="file"
          onChange={handleFileChange}
          style={{
            margin: "10px 0",
            padding: "5px",
            border: "1px solid #ddd",
            borderRadius: "4px",
            fontSize: "14px",
          }}
        />
        <button
          type="submit"
          style={{
            padding: "5px 15px",
            border: "none",
            borderRadius: "4px",
            background: "#007bff",
            color: "#fff",
            cursor: "pointer",
            fontSize: "14px",
          }}
        >
          Prześlij plik
        </button>
      </form>
    </div>
  );
};

export default AgregateData;
