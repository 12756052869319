// HalfDiagram.js
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Typography } from "@material-ui/core";

const HalfDiagramRealizujaca = ({ userData, property, allowedNames }) => {
  console.log("allowedNames in HalfDiagramRealizujaca:", allowedNames);

  const filteredData = userData.filter((user) =>
    allowedNames.some((allowedUser) => allowedUser.name === user[property])
  );

  console.log("filteredData in HalfDiagramRealizujaca:", filteredData);

  const usernameCounts = filteredData.reduce((counts, user) => {
    const username = user[property] || "Brak przypisanych spraw";
    counts[username] = (counts[username] || 0) + 1;
    return counts;
  }, {});

  const data = {
    labels: Object.keys(usernameCounts),
    datasets: [
      {
        data: Object.values(usernameCounts),
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#8e5ea2",
          "#3cba9f",
          "#f4511e",
          "#4caf50",
          "#9c27b0",
          "#607d8b",
          // Add more colors as needed
        ],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div
      style={{
        height: "450px",
        width: "450px",
        margin: "20px",
        paddingBottom: "20px",
        marginBottom: "20px",
      }}
    >
      <Typography variant="subtitle1" style={{ marginBottom: "20px" }}>
        Wykres podziału osob wysyłających
      </Typography>
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default HalfDiagramRealizujaca;
