import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  makeStyles,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@material-ui/core";
import {
  ExitToApp as ExitToAppIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";

import FileUpload from "./FileUpload";
import AgregateData from "./AgregateData";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    overflowX: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  formContainer: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: theme.spacing(1),
    width: "90%",
  },
}));

const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

const drawerWidth = "10%";

const Traces = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleBackToTableClick = () => {
    navigate("/user");
  };

  const handleLogoutClick = () => {
    navigate("/login");
  };

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar style={{ minHeight: 3 }} />
        <List>
          <ListItem button onClick={handleBackToTableClick}>
            <ListItemIcon>
              <ArrowBackIcon />
            </ListItemIcon>
            <ListItemText primary="Powrót do tabeli" />
          </ListItem>
          <ListItem button onClick={handleLogoutClick}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Wyloguj" />
          </ListItem>
        </List>
      </Drawer>
      <main
        className={classes.content}
        style={{ width: "90%", padding: "10px" }}
      >
        <div style={{ display: "flex" }}>
          {/* Left Half */}
          <Paper
            elevation={3}
            className={classes.formContainer}
            style={{ marginRight: "20px", width: "45%", paddingBottom: "5%" }}
          >
            <FileUpload />
          </Paper>

          {/* Middle Half */}
          <Paper
            elevation={3}
            className={classes.formContainer}
            style={{ width: "45%", paddingBottom: "5%", marginRight: "20px" }}
          >
            <AgregateData />
          </Paper>
        </div>
      </main>
      <ToastContainer position="top-right" />
    </div>
  );
};

export default Traces;
