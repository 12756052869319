import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@material-ui/core";

const UserEditPasswordModal = ({ user, open, onClose, onSave }) => {
  const [editedUser, setEditedUser] = useState({ ...user });

  useEffect(() => {
    setEditedUser({ ...user });
  }, [user]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditedUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Edytuj użytkownika</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          name="password"
          label="Hasło użytkownika"
          type="text"
          fullWidth
          value={editedUser.password || ""}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Anuluj
        </Button>
        <Button onClick={() => onSave(editedUser)} color="primary">
          Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserEditPasswordModal;
