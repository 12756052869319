import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  MenuItem,
} from "@material-ui/core";

const UserCreateModal = ({ open, onClose, onSave }) => {
  const [newUser, setNewUser] = useState({
    username: "",
    email: "",
    role: "",
    password: "", // Dodano nowe pole dla hasła
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleSave = () => {
    onSave(newUser);
    setNewUser({ username: "", email: "", role: "", password: "" }); // Reset form
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Nowy użytkownik</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="username"
          label="Nazwa użytkownika"
          type="text"
          fullWidth
          value={newUser.username}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="email"
          label="Email"
          type="email"
          fullWidth
          value={newUser.email}
          onChange={handleChange}
        />
        <TextField
          select
          margin="dense"
          name="role"
          label="Rola"
          fullWidth
          value={newUser.role}
          onChange={handleChange}
        >
          {/* Dodaj tutaj opcje dla roli, np. Administrator, Użytkownik */}
          <MenuItem value="admin">admin</MenuItem>
          <MenuItem value="menager">menager</MenuItem>
          <MenuItem value="agent">agent</MenuItem>
        </TextField>
        <TextField
          margin="dense"
          name="password"
          label="Hasło"
          type="password" // Typ ustawiony na "password" dla bezpieczeństwa
          fullWidth
          value={newUser.password}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Anuluj</Button>
        <Button onClick={handleSave}>Zapisz</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserCreateModal;
