import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parse } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

const timeZone = "Europe/Warsaw"; // Set your desired time zone here, for example, 'Europe/Warsaw'

const DatePickerField = ({ label, name, value, onChange, ...rest }) => {
  const handleDateChange = (date) => {
    // Format the date string to 'dd-MM-yyyy' before parsing
    const formattedDateString = format(date, "dd-MM-yyyy", {
      awareOfUnicodeTokens: true,
    });

    const parsedDate = parse(formattedDateString, "dd-MM-yyyy", new Date());
    if (isNaN(parsedDate)) {
      console.error("Invalid date:", formattedDateString);
      return;
    }

    const zonedDate = utcToZonedTime(parsedDate, timeZone);
    const formattedZonedDate = format(zonedDate, "dd-MM-yyyy", { timeZone });
    onChange(formattedZonedDate);
  };

  return (
    <DatePicker
      label={label}
      name={name}
      value={value} // Assuming 'value' is already in the format 'dd-MM-yyyy'
      onChange={handleDateChange}
      format="dd-MM-yyyy"
      {...rest}
    />
  );
};

export default DatePickerField;
