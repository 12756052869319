import React, { useState } from "react";
import axios from "axios";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
  textarea: {
    width: "100%",
    height: "80px",
    resize: "none",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    width: "100%",
  },
}));

function formatEmailContent(text) {
  return text.replace(/\n/g, "<br>");
}

const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

function EmailSender({
  nrRef,
  odbiorca,
  rodzaj,
  importer,
  eksporter,
  lokalizacjaTowaru,
}) {
  const classes = useStyles();
  const [emailContent, setEmailContent] = useState("");

  const handleSendEmail = async () => {
    if (!emailContent.trim()) {
      toast.error("Nie można wysłać pustego maila");
      return;
    }
    try {
      const formattedContent = formatEmailContent(emailContent);

      await axios.post(
        `https://wega-api.com/sendEmailForm`,
        {
          content: formattedContent,
          odbiorca,
          nrRef,
          rodzaj,
          importer,
          eksporter,
          lokalizacjaTowaru,
        },
        { headers }
      );

      toast.success("Poprawnie wysłano emial do odbiorcy!");
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Email nie został wysłany. coś poszło nie tak");
    }
  };

  return (
    <div className={classes.container}>
      {/* <textarea
        value={emailContent}
        onChange={(e) => setEmailContent(e.target.value)}
        placeholder="Wpisz swoj text do maila..."
        className={classes.textarea}
      />

      <Button
        variant="contained"
        color="primary"
        onClick={handleSendEmail}
        className={classes.button}
      >
        WYślij Email
      </Button> */}
      <ToastContainer
        onClose={() => setTimeout(() => window.location.reload(), 100)} // Refresh the page after the toast notification is closed/>
      />
    </div>
  );
}

export default EmailSender;
