import React, { useState, useEffect } from "react";
import {
  Paper,
  makeStyles,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@material-ui/core";
import {
  ExitToApp as ExitToAppIcon,
  ArrowBack as ArrowBackIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import UserEditModal from "./UserEditModal";
import UserCreateModal from "./UserCreateModal";
import UserEditPasswordModal from "./UserEditPasswordModal";
import {
  fetchUsers,
  deleteUser,
  saveUser,
  updateUserPassword,
} from "./UserService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PasswordIcon from "@mui/icons-material/Password";

const useStyles = makeStyles((theme) => ({
  root: { display: "flex" },
  drawer: { width: 240, flexShrink: 0 },
  drawerPaper: { width: 240 },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    overflowX: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  formContainer: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: theme.spacing(1),
    width: "90%",
  },
}));

const AdminPanel = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null); // null for new user, object for edit

  const loadUsers = async () => {
    try {
      const usersData = await fetchUsers();
      setUsers(usersData);
    } catch (error) {
      toast.error("Problem z pobraniem użytkowników.");
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      loadUsers();
    }, 500); // Opóźnienie 1 sekundy

    return () => clearTimeout(timer); // Oczyszczenie timera przy odmontowywaniu komponentu
  }, []);

  const handleUserSubmit = async (user, isEditing) => {
    try {
      await saveUser(
        user,
        isEditing ? "PUT" : "POST",
        isEditing ? user.id : undefined
      );
      toast.success(
        `Użytkownik został ${isEditing ? "zaktualizowany" : "utworzony"}!`
      );
      loadUsers();
    } catch (error) {
      toast.error(
        `Problem z ${isEditing ? "aktualizacją" : "dodaniem"} użytkownika.`
      );
    } finally {
      setModalOpen(false);
      setCurrentUser(null);
    }
  };

  const handleDelete = async (userId) => {
    try {
      await deleteUser(userId);
      toast.success("Użytkownik usunięty.");
      loadUsers();
    } catch (error) {
      toast.error("Problem z usunięciem użytkownika.");
    }
  };

  const openModalForEdit = (user) => {
    setCurrentUser(user);
    setModalOpen(true);
  };

  const openModalForNew = () => {
    setCurrentUser(null); // Ensure modal opens for new user creation
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCurrentUser(null);
  };

  const openPasswordModal = (user) => {
    setCurrentUser(user);
    setPasswordModalOpen(true); // Otwórz modal zmiany hasła
  };

  const closePasswordModal = () => {
    setPasswordModalOpen(false);
    setCurrentUser(null);
  };

  const handlePasswordSubmit = async (editedUser) => {
    try {
      await updateUserPassword(editedUser.id, editedUser.password);
      toast.success("Hasło zostało pomyślnie zaktualizowane."); // Informuj użytkownika o sukcesie
      loadUsers(); // Opcjonalnie, odśwież listę użytkowników jeśli jest taka potrzeba
    } catch (error) {
      console.error("Błąd aktualizacji hasła:", error);
      toast.error("Nie udało się zaktualizować hasła."); // Informuj użytkownika o błędzie
    } finally {
      closePasswordModal(); // Zamknij modal niezależnie od wyniku operacji
    }
  };

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{ paper: classes.drawerPaper }}
      >
        <Toolbar />
        <List>
          <ListItem button onClick={() => navigate("/user")}>
            <ListItemIcon>
              <ArrowBackIcon />
            </ListItemIcon>
            <ListItemText primary="Powrót do tabeli" />
          </ListItem>
          <ListItem button onClick={() => navigate("/login")}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Wyloguj" />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <Button
          variant="contained"
          color="primary"
          onClick={openModalForNew}
          style={{ marginBottom: "20px" }}
        >
          Dodaj nowego użytkownika
        </Button>
        <TableContainer component={Paper} className={classes.formContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Username</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Rola</TableCell>
                <TableCell>Data dodania</TableCell>
                <TableCell>Dostęp</TableCell>
                <TableCell>Data odebrania dostępu</TableCell>
                <TableCell>Akcje</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => {
                const formattedDateAdded = user.date_added
                  ? new Date(user.date_added).toLocaleDateString("pl-PL", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })
                  : "";
                const formattedDateDenied = user.access_denied
                  ? new Date(user.access_denied).toLocaleDateString("pl-PL", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })
                  : "";

                return (
                  <TableRow key={user.id}>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.role}</TableCell>
                    <TableCell>{formattedDateAdded}</TableCell>
                    <TableCell>{user.access}</TableCell>
                    <TableCell>{formattedDateDenied}</TableCell>
                    <TableCell>
                      <Button onClick={() => openModalForEdit(user)}>
                        <EditIcon />
                      </Button>
                      <Button onClick={() => handleDelete(user.id)}>
                        <DeleteIcon />
                      </Button>
                      <Button onClick={() => openPasswordModal(user)}>
                        <PasswordIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {modalOpen &&
          (currentUser ? (
            <UserEditModal
              user={currentUser}
              open={modalOpen}
              onClose={closeModal}
              onSave={(user) => handleUserSubmit(user, true)}
            />
          ) : (
            <UserCreateModal
              open={modalOpen}
              onClose={closeModal}
              onSave={(user) => handleUserSubmit(user, false)}
            />
          ))}
        {passwordModalOpen && (
          <UserEditPasswordModal
            user={currentUser}
            open={passwordModalOpen}
            onClose={closePasswordModal}
            onSave={handlePasswordSubmit}
          />
        )}
      </main>
      <ToastContainer />
    </div>
  );
};

export default AdminPanel;
