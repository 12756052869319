import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Paper,
  TextField,
  Button,
  Divider,
} from "@material-ui/core";
import { ExitToApp as ExitToAppIcon } from "@material-ui/icons";

const drawerWidth = "10%";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },

  drawerPaper: {
    width: drawerWidth,
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: "100%",
    overflowX: "auto",
  },

  section: {
    marginBottom: theme.spacing(3),
  },

  usersList: {
    maxHeight: "calc(100vh - 180px)",
    overflowY: "auto",
  },

  chatWindow: {
    height: "calc(100vh - 180px)",
    padding: theme.spacing(2),
    overflowY: "auto",
  },

  messageInput: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
  },

  inputField: {
    flex: 1,
  },

  scrollableDiv: {
    maxHeight: "350px", // Set the height as needed
    overflow: "auto",
  },

  chatWindowContainer: {
    height: "calc(100vh - 210px)",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1),
  },

  userMessageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginBottom: theme.spacing(1),
  },

  otherUserMessageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginBottom: theme.spacing(1),
  },

  userMessage: {
    backgroundColor: "#E1F5FE", // Light blue background
    padding: theme.spacing(1),
    borderRadius: "17px",
    textAlign: "right", // Align timestamp to the right for user messages
    fontSize: "17px", // Updated font size for user messages
  },
  otherUserMessage: {
    backgroundColor: "#ECECEC", // Light gray background
    padding: theme.spacing(1),
    borderRadius: "17px",
    textAlign: "left", // Align timestamp to the left for other user messages
    fontSize: "17px", // Updated font size for other user messages
  },

  timestamp: {
    fontSize: "12px",
    color: "#666",
    marginTop: theme.spacing(1), // Adjust spacing to push the timestamp below the message
  },
}));

const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("token")}`, // Include the token here
};

const ChatApp = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [messages, setMessages] = useState([]); // Initialize with an empty array
  const [messageText, setMessageText] = useState("");
  // State to keep track of the selected user
  const [selectedUser, setSelectedUser] = useState(null);
  const [notifications, setNotifications] = useState([]);
  // New state variable to keep track of whether the chat window is visible
  const [chatWindowVisible, setChatWindowVisible] = useState(false);
  // Ref for the input field to send messages on Enter key press
  const inputRef = useRef(null);
  const chatWindowRef = useRef(null);

  const updateMessages = (newMessage) => {
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);

    // Clear the notifications for the selected user from the notifications state
    setNotifications((prevNotifications) =>
      prevNotifications.filter(
        (notification) =>
          notification.sender !== user.username ||
          notification.receiver !== getSenderUsernameFromLocalStorage()
      )
    );

    // Fetch messages for the selected user to refresh the chat window
    fetchMessages(user.username);

    // Remove the notification from the database for the selected user
    handleNotificationRemove(user);
  };

  const handleNotificationRemove = async (user) => {
    try {
      const senderUsername = getSenderUsernameFromLocalStorage();
      console.log("Attempting to remove notification:", {
        sender: user.username,
        receiver: senderUsername,
      });

      // Send a request to the backend to remove the notification for the selected user
      await axios.post(
        "https://wega-api.com/removeNotification",
        {
          sender: user.username,
          receiver: senderUsername,
        },
        {
          headers: headers,
        }
      );

      // Fetch notifications again to update the notifications state
      fetchNotifications();

      // Fetch messages for the selected user to refresh the chat window
      fetchMessages(user.username);
    } catch (error) {
      console.error("Error removing notification:", error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    navigate("/login");
    toast.success("Logged out successfully!", {
      autoClose: 3000,
    });
  };

  // Function to get the sender's username from localStorage
  const getSenderUsernameFromLocalStorage = () => {
    const senderUsername = localStorage.getItem("username");
    return senderUsername || ""; // Return an empty string if the username is not found in localStorage
  };

  const handleSendMessage = async () => {
    try {
      const senderUsername = getSenderUsernameFromLocalStorage();

      // If the sender's username is not available, prompt the user to log in
      if (!senderUsername) {
        toast.error("Please log in to send a message.");
        return;
      }

      // Prevent sending a message if no user is selected
      if (!selectedUser) {
        toast.error("Please select a user to send a message.");
        return;
      }

      // Send the message to the server with the correct sender and receiver
      const response = await axios.post(
        "https://wega-api.com/sendMessage",
        {
          sender: senderUsername,
          receiver: selectedUser.username,
          text: messageText,
        },
        {
          headers: headers,
        }
      );

      setNotifications((prevNotifications) => [
        ...prevNotifications,
        {
          sender: senderUsername,
          receiver: selectedUser.username,
        },
      ]);
      setMessageText("");

      updateMessages({
        id: response.data.id,
        sender: senderUsername,
        receiver: selectedUser.username,
        text: messageText,
        timestamp: new Date().toLocaleString(),
      });
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get("https://wega-api.com/getUsers", {
        headers: headers,
      });
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchMessages = async (receiver) => {
    try {
      const senderUsername = getSenderUsernameFromLocalStorage();

      if (!receiver || !senderUsername) {
        console.log("No user selected or sender username not found.");
        return;
      }

      const response = await axios.get(
        `https://wega-api.com/messages?sender=${senderUsername}&receiver=${receiver}`,
        {
          headers: headers,
        }
      );

      const messagesWithTimestamp = response.data.map((message) => ({
        ...message,
        timestamp: new Date(message.timestamp).toLocaleString(),
      }));

      setMessages(messagesWithTimestamp);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  // Fetch notifications for the logged-in user
  const fetchNotifications = async () => {
    try {
      const senderUsername = getSenderUsernameFromLocalStorage();
      if (!senderUsername) return;

      const response = await axios.get(
        `https://wega-api.com/notifications?receiver=${senderUsername}`,
        {
          headers: headers,
        }
      );
      setNotifications(response.data);

      if (selectedUser) {
        setNotifications((prevNotifications) =>
          prevNotifications.filter(
            (notification) =>
              notification.sender !== selectedUser.username ||
              notification.receiver !== senderUsername
          )
        );

        fetchMessages(selectedUser.username);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  // Function to handle user click event when the user has a notification
  const handleUserClickWithNotification = (user) => {
    setSelectedUser(user);

    setNotifications((prevNotifications) =>
      prevNotifications.filter(
        (notification) =>
          notification.sender !== user.username ||
          notification.receiver !== getSenderUsernameFromLocalStorage()
      )
    );
    handleNotificationRemove(user);
    setChatWindowVisible(true);
  };

  // New function to handle displaying notifications
  const displayNotifications = () => {
    const senderUsername = getSenderUsernameFromLocalStorage();

    return users
      .map((user) => {
        if (user.username === senderUsername) {
          return null;
        }

        const hasNotification =
          notifications.filter(
            (notification) =>
              notification.sender === user.username &&
              notification.receiver === senderUsername
          ).length > 0;
        return (
          <ListItem
            key={user.id}
            button
            onClick={() =>
              hasNotification
                ? handleUserClickWithNotification(user)
                : handleUserSelect(user)
            }
            selected={selectedUser?.id === user.id}
          >
            <ListItemText
              primary={user.username}
              secondary={
                hasNotification && !chatWindowVisible ? ( // Only display notifications if chat window is not visible
                  <span
                    style={{ color: "red", cursor: "pointer" }}
                    onClick={() => handleNotificationRemove(user)}
                  >
                    Nowa wiadomość
                  </span>
                ) : null
              }
            />
          </ListItem>
        );
      })
      .filter(Boolean);
  };

  useEffect(() => {
    fetchUsers();
    fetchNotifications();
    if (selectedUser) {
      fetchMessages(selectedUser.username);
    }

    const interval = setInterval(() => {
      fetchNotifications();
      if (selectedUser) {
        fetchMessages(selectedUser.username);
      }
    }, 30000); // Fetch notifications every 30 seconds

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);

  // Function to scroll the chat window to the bottom
  const scrollToBottom = () => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  };

  // Update chatWindowVisible state when selectedUser changes
  useEffect(() => {
    scrollToBottom();
    setChatWindowVisible(false); // Hide the chat window when a new user is selected
  }, [selectedUser, messages]);

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.section}>
          <List>
            <ListItem button onClick={() => navigate("/user")}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Powrót do tabeli" />
            </ListItem>
            <ListItem button onClick={handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Wyloguj" />
            </ListItem>
          </List>
        </div>
        <div className={classes.usersList}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="h6">Wybierz użytkownika</Typography>
          </div>
          <div className={classes.scrollableDiv}>
            <List>{displayNotifications()}</List>
          </div>
        </div>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.section}>
          <Typography variant="h5" component="h1">
            Okno Czatu
          </Typography>
          <Divider />
          <Paper className={classes.chatWindowContainer} ref={chatWindowRef}>
            {messages.map((message) => (
              <div
                key={message.id}
                className={
                  message.sender === getSenderUsernameFromLocalStorage()
                    ? classes.userMessageContainer
                    : classes.otherUserMessageContainer
                }
              >
                <Typography
                  className={
                    message.sender === getSenderUsernameFromLocalStorage()
                      ? classes.userMessage
                      : classes.otherUserMessage
                  }
                >
                  {message.text}
                </Typography>
                <Typography className={classes.timestamp}>
                  {message.timestamp}
                </Typography>
              </div>
            ))}
          </Paper>
        </div>
        <div className={classes.section}>
          <Typography variant="h5" component="h1">
            Napisz wiadomość
          </Typography>
          <Divider />
          <div className={classes.messageInput}>
            <TextField
              ref={inputRef}
              className={classes.inputField}
              label="Message"
              variant="outlined"
              value={messageText}
              onChange={(e) => setMessageText(e.target.value)}
              onKeyDown={handleKeyPress} // Use onKeyDown instead of onKeyPress
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSendMessage}
            >
              Wyślij
            </Button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ChatApp;
