import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment-timezone";
import { jwtDecode } from "jwt-decode";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  CircularProgress,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import {
  ExitToApp as ExitToAppIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import {
  fetchRowData,
  fetchComments,
  addComment,
  updateField,
  logHistory,
  mapPodpisanyToUsername,
} from "./RejestrFunctions"; // Import funkcji z pliku apiService.js
const drawerWidth = "10%";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: "100%",
    overflowX: "auto",
  },
  fieldBlocksContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
    alignItems: "flex-start",
  },
  fieldBlock: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    cursor: "pointer",
    transition: "background-color 0.3s",
    textAlign: "center",
    color: "white",
    minWidth: "150px",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  category: {
    marginBottom: theme.spacing(4),
  },
  categoryHeading: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  dialog: {
    padding: theme.spacing(3),
    backgroundColor: "white",
    boxShadow: theme.shadows[5],
    borderRadius: theme.shape.borderRadius,
    maxWidth: "500px",
    margin: "0 auto",
  },
  dialogTitle: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
  },
}));

const categories = {
  "Informacje Podstawowe": [
    "data_wpisania",
    "powod_wpisania",
    "nr_MRN",
    "nr_akt",
  ],
  "Szczegóły wniosku": [
    "nadawca_odbiorca",
    "odbiorca",
    "podpisany",
    "rodzaj_SAD",
  ],
  "Procedura i Kontrola": [
    "procedura",
    "kontrolujacy",
    "nieprawidlowosci",
    "korekta",
  ],
  Terminy: ["data_SAD", "dias", "data_wyslania", "createdAt"],
};

const fieldLabels = {
  data_wpisania: "Data wpisania",
  powod_wpisania: "Powód wpisania",
  nr_MRN: "MRN",
  nr_akt: "Numer akt",
  nadawca_odbiorca: "Nadawca/Odbiorca",
  odbiorca: "Odbiorca",
  podpisany: "Podpisany",
  rodzaj_SAD: "Rodzaj SAD",
  procedura: "Procedura",
  kontrolujacy: "Kontrolujący",
  nieprawidlowosci: "Nieprawidłowości",
  korekta: "Korekta",
  dias: "DIAS",
  data_SAD: "Data SAD",
  data_wyslania: "Data wysłania",
  createdAt: "Data utworzenia",
};

const kontrolujacyOptions = [
  "BRUŚ BARBARA",
  "CZERNIKOWSKA DOMINIKA",
  "DOBROWOLSKA-MITRĘGA ANNA",
  "MAZUR SABINA",
  "MUNIK PIOTR",
  "NOWAK PRZEMYSŁAW",
  "REDUCHA KATARZYNA",
  "WOJTAŚ MILENA",
];

const takNieOptions = ["TAK", "NIE"];

const EditWniosek1 = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [rowData, setRowData] = useState(null);
  const [editField, setEditField] = useState(null);
  const [editedValue, setEditedValue] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [commentsData, setCommentsData] = useState([]);
  const [commentValue, setCommentValue] = useState("");

  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  let username;
  let role;
  if (token) {
    const decoded = jwtDecode(token);
    username = decoded.username;
    role = decoded.role;
  }

  const checkAndUpdateStatus = async () => {
    if (rowData && rowData.status !== "Wysłany") {
      if (!rowData.data_wyslania) {
        // Sprawdza, czy pole data_wyslania jest wypełnione
        try {
          await updateField(id, { status: "Wysłany" });
          toast.success("Status został zaktualizowany na 'Wysłany'");
          fetchRowData(id).then(setRowData).catch(console.error); // Pobierz ponownie dane po aktualizacji
        } catch (error) {
          console.error("Error updating status:", error);
          toast.error("Wystąpił błąd podczas aktualizacji statusu.");
        }
      }
    }
  };

  useEffect(() => {
    if (id) {
      fetchRowData(id)
        .then((data) => {
          setRowData(data);
          checkAndUpdateStatus(); // Sprawdź status po pobraniu danych
        })
        .catch(console.error);
      fetchComments(id).then(setCommentsData).catch(console.error);
    }
  }, [id]);

  // Definicja funkcji handleFieldSelect
  const handleFieldSelect = (field) => {
    setEditField(field);
    setEditedValue(rowData[field] || "");
    setIsEditing(true);
  };

  const handleAddComment = async () => {
    if (!commentValue) return;

    const newComment = {
      username: username,
      comment: commentValue,
    };

    try {
      await addComment(id, newComment);
      setCommentsData([...commentsData, newComment]);
      setCommentValue(""); // Wyczyszczenie pola komentarza po dodaniu
      toast.success("Komentarz został dodany!");
    } catch (error) {
      console.error("Error adding comment:", error);
      toast.error("Wystąpił błąd podczas dodawania komentarza.");
    }
  };

  const handleFinishClick = async () => {
    if (rowData) {
      const currentDateTime = new Date().toISOString();

      try {
        await updateField(id, {
          status: "Zakończony",
          data_zakonczenia: currentDateTime,
        });

        toast.success("Wniosek został zakończony.");
        fetchRowData(id).then(setRowData).catch(console.error);
      } catch (error) {
        console.error("Error finishing request:", error);
        toast.error("Wystąpił błąd podczas zakończania wniosku.");
      }
    } else {
      toast.error("Nie można zakończyć wniosku. Dane są niepoprawne.");
    }
  };

  const handleEditSave = async () => {
    try {
      const oldValue = rowData[editField];
      const newValue = ["data_wpisania", "data_SAD", "data_wyslania"].includes(
        editField
      )
        ? moment(editedValue).format("YYYY-MM-DD")
        : editedValue;

      await updateField(id, { [editField]: newValue });

      await logHistory(id, {
        field: editField,
        oldValue: oldValue,
        newValue: newValue,
        username: username,
        timestamp: new Date().toISOString(),
      });

      toast.success("Poprawnie zedytowano dane!");
      setIsEditing(false);
      fetchRowData(id)
        .then((data) => {
          setRowData(data);
          checkAndUpdateStatus(); // Sprawdź status po aktualizacji danych
        })
        .catch(console.error);
    } catch (error) {
      console.error("Error updating data:", error);
      toast.error("Error updating data.");
    }
  };

  const handleEditCancel = () => {
    setIsEditing(false);
  };

  const isButtonEnabled = (role, rowData, username) => {
    const mappedUsername = mapPodpisanyToUsername(rowData.podpisany);
    return role === "admin" || mappedUsername === username;
  };

  if (!rowData || !id) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress color="secondary" />
        <Typography variant="body1">Loading...</Typography>
      </div>
    );
  }

  const handleBack = () => {
    navigate("/rejestrWnioskow");
  };

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{ paper: classes.drawerPaper }}
      >
        <Toolbar />
        <List>
          <ListItem button onClick={handleBack} key="back">
            <ListItemIcon>
              <ArrowBackIcon />
            </ListItemIcon>
            <ListItemText primary="Powrót do tabeli " />
          </ListItem>
          <ListItem button onClick={() => navigate("/login")} key="logout">
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Wyloguj" />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        <Typography variant="h4" component="h1" className={classes.tableTitle}>
          Szczegóły wniosku o numerze: {rowData.unique_number}
        </Typography>

        {isEditing && (
          <div className={classes.dialog}>
            <Typography variant="h6" className={classes.dialogTitle}>
              Edytuj: {fieldLabels[editField] || editField}
            </Typography>
            {["data_wpisania", "data_SAD", "data_wyslania"].includes(
              editField
            ) ? (
              <TextField
                label={fieldLabels[editField] || editField}
                type="date"
                variant="outlined"
                fullWidth
                value={moment(editedValue).format("YYYY-MM-DD")}
                onChange={(event) => setEditedValue(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            ) : ["kontrolujacy", "podpisany"].includes(editField) ? (
              <FormControl variant="outlined" fullWidth>
                <InputLabel>{fieldLabels[editField]}</InputLabel>
                <Select
                  value={editedValue}
                  onChange={(event) => setEditedValue(event.target.value)}
                  label={fieldLabels[editField]}
                >
                  {kontrolujacyOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : ["nieprawidlowosci", "korekta", "dias"].includes(editField) ? (
              <FormControl variant="outlined" fullWidth>
                <InputLabel>{fieldLabels[editField]}</InputLabel>
                <Select
                  value={editedValue}
                  onChange={(event) => setEditedValue(event.target.value)}
                  label={fieldLabels[editField]}
                >
                  {takNieOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <TextField
                label={fieldLabels[editField] || editField}
                variant="outlined"
                fullWidth
                value={editedValue}
                onChange={(event) => setEditedValue(event.target.value)}
              />
            )}
            <div className={classes.buttonGroup}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleEditSave}
              >
                Zapisz
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleEditCancel}
              >
                Anuluj
              </Button>
            </div>
          </div>
        )}

        {!isEditing &&
          Object.keys(categories).map((category) => (
            <div key={category} className={classes.category}>
              <Typography variant="h6" className={classes.categoryHeading}>
                {category}:
              </Typography>
              <div className={classes.fieldBlocksContainer}>
                {categories[category].map((field) =>
                  field === "podpisany" && role !== "admin" ? null : (
                    <div
                      key={field}
                      className={classes.fieldBlock}
                      onClick={() => handleFieldSelect(field)}
                    >
                      <Typography className={classes.fieldLabel}>
                        {fieldLabels[field] || field}
                      </Typography>
                    </div>
                  )
                )}
              </div>
            </div>
          ))}

        {rowData?.status === "Wysłany" && (
          <>
            <hr className={classes.wider} />
            <Button
              onClick={handleFinishClick}
              disabled={
                rowData?.status === "Zakończony" ||
                !isButtonEnabled(role, rowData, username)
              }
              style={{ marginTop: "10px" }}
              variant="contained"
              color="secondary"
            >
              Zakończ wniosek
            </Button>
          </>
        )}

        <hr className={classes.wider} />

        <Typography variant="h6">
          <strong>Numer wniosku:</strong> {rowData.unique_number}
        </Typography>
        <Typography variant="h6">
          <strong>Status:</strong> {rowData.status}
        </Typography>
        <Typography variant="h6">
          <strong>Data wpisania:</strong>{" "}
          {moment(rowData.data_wpisania).format("YYYY-MM-DD HH:mm")}
        </Typography>
        <Typography variant="h6">
          <strong>Powód wpisania:</strong> {rowData.powod_wpisania}
        </Typography>
        <Typography variant="h6">
          <strong>Data SAD-u:</strong>{" "}
          {moment(rowData.data_SAD).format("YYYY-MM-DD ")}
        </Typography>
        <hr className={classes.wider} />
        <Typography variant="h6">
          <strong>MRN:</strong> {rowData.nr_MRN}
        </Typography>
        <Typography variant="h6">
          <strong>Nr. akt:</strong> {rowData.nr_akt}
        </Typography>
        <Typography variant="h6">
          <strong>Nadawca/odbiorca:</strong> {rowData.nadawca_odbiorca}
        </Typography>
        <Typography variant="h6">
          <strong>Odbiorca:</strong> {rowData.odbiorca}
        </Typography>
        <Typography variant="h6">
          <strong>Podpisany:</strong> {rowData.podpisany}
        </Typography>
        <Typography variant="h6">
          <strong>Rodzaj SAD-u:</strong> {rowData.rodzaj_SAD}
        </Typography>
        <hr className={classes.wider} />
        <Typography variant="h6">
          <strong>Procedura:</strong> {rowData.procedura}
        </Typography>
        <Typography variant="h6">
          <strong>Kontrolujący:</strong> {rowData.kontrolujacy}
        </Typography>
        <Typography variant="h6">
          <strong>Nieprawidłowości:</strong> {rowData.nieprawidlowosci}
        </Typography>
        <Typography variant="h6">
          <strong>Czy kwalifikuje się do korekty?:</strong> {rowData.korekta}
        </Typography>
        <Typography variant="h6">
          <strong>Czy trzeba zgłosić do DIAS?:</strong> {rowData.DIAS}
        </Typography>
        <Typography variant="h6">
          <strong>Data wysłania do UC:</strong>{" "}
          {rowData.data_wyslania
            ? moment(rowData.data_wyslania).format("YYYY-MM-DD")
            : ""}
        </Typography>

        <Typography variant="h6">
          <strong>Data zakończenia:</strong>{" "}
          {rowData.data_zakonczenia
            ? moment(rowData.data_zakonczenia).format("YYYY-MM-DD HH:mm")
            : ""}
        </Typography>

        <hr className={classes.wider} />

        {!isEditing && (
          <div>
            <TextField
              label="Dodaj komentarz"
              variant="outlined"
              fullWidth
              value={commentValue}
              onChange={(event) => setCommentValue(event.target.value)}
              style={{ marginTop: "10px" }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddComment}
              style={{ marginTop: "10px" }}
            >
              Dodaj komentarz
            </Button>
            <Typography
              variant="h5"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              Komentarze:
            </Typography>
            <div>
              {commentsData.map((comment, index) => (
                <div
                  key={index}
                  style={{
                    marginBottom: "20px", // Większy odstęp między komentarzami
                    paddingBottom: "10px", // Opcjonalne: Dodatkowy odstęp wewnątrz komentarza
                    borderBottom: "1px solid #ccc", // Opcjonalne: Linia oddzielająca komentarze
                  }}
                >
                  <Typography variant="body1" style={{ fontSize: "1.1rem" }}>
                    <strong>{comment.username}</strong> - {comment.comment}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    style={{ fontSize: "0.9rem" }}
                  >
                    {moment(comment.created_at).format("YYYY-MM-DD HH:mm")}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        )}
      </main>
      <ToastContainer />
    </div>
  );
};

export default EditWniosek1;
