import axios from 'axios';

const getHeaders = async () => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Token is not available");
  }
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
};

export const fetchData = async (id) => {
  try {
    const response = await axios.get(
      `https://wega-api.com/fetch-przywoz-rejestr/${id}`,
      { headers: await getHeaders() }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const getPrzygotowane = async (id) => {
  try {
    const response = await axios.get(
      `https://wega-api.com/fetch-przygotowane-przywoz-rejestr/${id}`,
      { headers: await getHeaders() }
    );
    // Załóżmy, że response.data ma strukturę { przygotowane: 1 }
    console.log(`Przygotowane z funkji: ${response.data.przygotowane}`)
    return response.data.przygotowane;
  } catch (error) {
    console.error("Error fetching Przygotowane:", error);
    throw error;
  }
};

export const getZweryfikowane = async (id) => {
  try {
    const response = await axios.get(
      `https://wega-api.com/fetch-zweryfikowane-przywoz-rejestr/${id}`,
      { headers: await getHeaders() }
    );
    // Załóżmy, że response.data ma strukturę { przygotowane: 1 }
    console.log(`Zweryfikowane z funkji: ${response.data.zweryfikowane}`)
    return response.data.zweryfikowane;
  } catch (error) {
    console.error("Error fetching Zweryfikowane:", error);
    throw error;
  }
};


export const saveOrUpdateData = async (id, payload) => {
  try {
    const response = await axios.post(
      `https://wega-api.com/saveOrUpdatePrzywozRejestr/${id}`,
      payload,
      { headers: await getHeaders() }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to save or update data:", error);
    throw error;
  }
};

export const updateStatus = async (id, payload, statusType) => {
  try {
    const response = await axios.post(
      `https://wega-api.com/update-status-${statusType}/${id}`,
      payload,
      { headers: await getHeaders() }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to update status:", error);
    throw error;
  }
};

export const updateZweryfikowanePrzygotowaneDoWyslania = async (id, payload, statusType) => {
  try {
    const response = await axios.post(
      `https://wega-api.com/update-przygotowane-zweryfikowane-status-${statusType}/${id}`,
      payload,
      { headers: await getHeaders() }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to update status:", error);
    throw error;
  }
};

export const updatePrzygotowaneZweryfikowane = async (id, payload, statusType) => {
  try {
    const response = await axios.post(
      `https://wega-api.com/update-przygotowane-zweryfikowane-${statusType}/${id}`,
      payload,
      { headers: await getHeaders() }
    );
    console.log(response.data); // Logowanie odpowiedzi dla debugowania
    return response.data;
  } catch (error) {
    console.error("Failed to update status:", error);
    throw error;
  }
};


export const updateStatusDoWeryfikacji = async (id, payload, statusType) => {
  try {
    const response = await axios.post(
      `https://wega-api.com/update-status-${statusType}-weryfikacja/${id}`,
      payload,
      { headers: await getHeaders() }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to update status:", error);
    throw error;
  }
};

export const updateStatusDoWyslania = async (id, payload, statusType) => {
  try {
    const response = await axios.post(
      `https://wega-api.com/update-status-${statusType}-do-wyslania/${id}`,
      payload,
      { headers: await getHeaders() }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to update status:", error);
    throw error;
  }
};

export const updateStatusDoPoprawy = async (id, payload, statusType) => {
  try {
    const response = await axios.post(
      `https://wega-api.com/update-status-${statusType}-do-poprawy/${id}`,
      payload,
      { headers: await getHeaders() }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to update status:", error);
    throw error;
  }
};

export const updatePrzygotowane = async (id, payload, statusType) => {
  try {
    const response = await axios.post(
      `https://wega-api.com/update-status-${statusType}-przygotowane/${id}`,
      payload,
      { headers: await getHeaders() }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to update status:", error);
    throw error;
  }
};

export const saveVerifyingData1 = async (id, payload) => {
  try {
    const response = await axios.post(
      `https://wega-api.com/saveVerifyingData-przywoz-rejestr/${id}`,
      payload,
      { headers: await getHeaders() }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to save verifying data:", error);
    throw error;
  }
};

export const saveComment = async (id, payload) => {
  try {
    const response = await axios.post(
      `https://wega-api.com/comments/${id}`,
      payload,
      { headers: await getHeaders() }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to save comment:", error);
    throw error;
  }
};

export const saveCheckbox = async (id, payload) => {
  try {
    const response = await axios.put(
      `https://wega-api.com/saveCheckbox/${id}`,
      payload,
      { headers: await getHeaders() }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to save checkbox:", error);
    throw error;
  }
};

export const getSADAndDrugaWartosc = async (id) => {
  try {
    const response = await axios.get(
      `https://wega-api.com/get-SAD-Pik/${id}`,
      { headers: await getHeaders() }
    );
    return {
      SAD: response.data.SAD, // Adjust this based on actual API response
      Plik: response.data.plikiBox // Adjust this based on actual API response
    };
  } catch (error) {
    console.error("Error fetching SAD and drugaWartosc:", error);
    throw error;
  }
};
