// Calculator.js
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  makeStyles,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@material-ui/core";
import {
  ExitToApp as ExitToAppIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import HalfDiagram from "../HalfDiagram"; // Import HalfDiagram component
import HalfTable from "../HalfTable"; // Import HalfTable component
import HalfDiagramRealizujaca from "../HalfDiagramRealizujaca";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    overflowX: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  formContainer: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: theme.spacing(1),
    width: "90%",
  },
}));

const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

const drawerWidth = "10%";

const Calculator = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);

  const [userName, setUserName] = useState([]);

  const handleBackToTableClick = () => {
    navigate("/user");
  };

  const handleLogoutClick = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("filters");
    localStorage.removeItem("filtersApplied");
    localStorage.removeItem("filtersAppliedSAD");
    navigate("/login");
  };

  const fetchData = async () => {
    try {
      const response = await axios.get("https://wega-api.com/calculator", {
        headers: headers,
      });
      setUserData(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error("Error fetching user data");
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        "https://wega-api.com/list/uzytkownicy",
        {
          headers: headers,
        }
      );
      setUserName(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error("Error fetching user data");
    }
  };

  const refreshData = () => {
    fetchData();

    fetchUsers();
  };

  useEffect(() => {
    // Initial data fetch
    refreshData();

    const intervalId = setInterval(() => {
      refreshData();
    }, 300000);

    return () => clearInterval(intervalId);
  }, []);

  const usernameCountsPrzygotowujaca = userData.reduce((counts, user) => {
    const username =
      user.osobaPrzygotowujacaOdprawe || "Brak przypisanych spraw";
    counts[username] = (counts[username] || 0) + 1;
    return counts;
  }, {});

  const usernameCountsRealizujaca = userData.reduce((counts, user) => {
    const username = user.osobaWysylajacaOdprawe || "Brak przypisanych spraw";
    counts[username] = (counts[username] || 0) + 1;
    return counts;
  }, {});

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar style={{ minHeight: 3 }} />
        <List>
          <ListItem button onClick={handleBackToTableClick}>
            <ListItemIcon>
              <ArrowBackIcon />
            </ListItemIcon>
            <ListItemText primary="Powrót do tabeli" />
          </ListItem>
          <ListItem button onClick={handleLogoutClick}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Wyloguj" />
          </ListItem>
        </List>
      </Drawer>
      <main
        className={classes.content}
        style={{ width: "90%", padding: "10px" }}
      >
        <div style={{ display: "flex" }}>
          {/* Left Half */}
          <Paper
            elevation={3}
            className={classes.formContainer}
            style={{ marginRight: "20px", width: "45%", paddingBottom: "5%" }}
          >
            <Typography variant="h4" style={{ marginBottom: "10px" }}>
              Kalkulator ilości spraw
            </Typography>
            <Typography variant="subtitle1" style={{ marginBottom: "20px" }}>
              osoba przygotowująca
            </Typography>
            <HalfTable
              usernameCounts={usernameCountsPrzygotowujaca}
              allowedNames={userName}
            />
            <HalfDiagram
              userData={userData}
              property="osobaPrzygotowujacaOdprawe"
              allowedNames={userName}
            />
          </Paper>

          {/* Middle Half */}
          <Paper
            elevation={3}
            className={classes.formContainer}
            style={{ width: "45%", paddingBottom: "5%", marginRight: "20px" }}
          >
            <Typography variant="h4" style={{ marginBottom: "10px" }}>
              Kalkulator ilości spraw
            </Typography>
            <Typography variant="subtitle1" style={{ marginBottom: "20px" }}>
              osoba wysyłająca
            </Typography>
            <HalfTable
              usernameCounts={usernameCountsRealizujaca}
              allowedNames={userName}
            />
            <HalfDiagramRealizujaca
              userData={userData}
              property="osobaWysylajacaOdprawe"
              allowedNames={userName}
            />
          </Paper>
        </div>
      </main>
      <ToastContainer position="top-right" />
    </div>
  );
};

export default Calculator;
