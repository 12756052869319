import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {
  TextField,
  Button,
  Typography,
  Grid,
  Paper,
  makeStyles,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
} from "@material-ui/core";
import {
  ExitToApp as ExitToAppIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import DatePickerField from "../DatePickerField"; // Import your custom DatePickerField
import { registerLocale, setDefaultLocale } from "react-datepicker";
import pl from "date-fns/locale/pl"; // Import the Polish locale

registerLocale("pl", pl); // Register the Polish locale
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    overflowX: "auto",
  },
  formContainer: {
    maxWidth: "900px", // Set a fixed width of 750px for the form container
    width: "100%", // Set the width of the form container to 100%
    margin: "auto",
    marginTop: theme.spacing(4),
    padding: theme.spacing(3), // Increase the padding to add more space between inputs
  },
  formTitle: {
    textAlign: "center",
  },
  marginBottom: {
    marginBottom: theme.spacing(2), // Add a margin bottom of 16px to all inputs
  },
  marginUpAndDown: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  stickySearchBox: {
    position: "sticky",
    top: 0,
    backgroundColor: "white",
    padding: "1rem",
    zIndex: 1,
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease, opacity 0.3s ease",
  },
  stickySearchBoxHidden: {
    transform: "translateY(-100%)",
    opacity: 0,
  },
}));
const drawerWidth = "10%";

const Form = () => {
  const classes = useStyles();
  setDefaultLocale("pl"); // Set the default locale to Polish

  const [generatedRefNumber, setGeneratedRefNumber] = useState(null);
  const [importerFilteredList, setImporterFilteredList] = useState([]);
  const [exporterFilteredList, setExporterFilteredList] = useState([]);
  const [uzytkownicyList, setUzytkownicyList] = useState([]);
  const [lokalizacjaList, setLokalizacjaList] = useState([]);
  const navigate = useNavigate();
  const [listType, setListType] = useState(null); // Track if it's Importer or Eksporter list
  const [listOpen, setListOpen] = useState(false); // Track if the list is open
  //const [listSearchTerm, setListSearchTerm] = useState(""); // Track the search term
  const [automatChecked, setAutomatChecked] = useState(false);
  // Define a state variable to track the dynamically added "Nr. Faktury" fields
  const [nrFakturyFields, setNrFakturyFields] = useState([{ nrFaktury: "" }]);

  const [eksporterFields, setEksporterFields] = useState([
    { eksporter: "" }, // Default Eksporter 1 field
  ]);

  const [fields, setFields] = useState([
    { nrTranzytu: "", expirationDate: "" }, // Default fields
  ]);

  // Function to add a new set of "Nr. Tranzytu" and "Data wygaśnięcia dokumentów" fields
  const addFields = () => {
    setFields([...fields, { nrTranzytu: "", expirationDate: "" }]);
  };

  // Function to remove a specific set of fields by index
  const removeFields = (index) => {
    if (index > 0) {
      const updatedFields = [...fields];
      updatedFields.splice(index, 1);
      setFields(updatedFields);
    }
  };

  // Function to handle changes in a specific set of fields by index
  const handleFieldsChange = (fieldName, value, index) => {
    const updatedFields = [...fields];
    updatedFields[index][fieldName] = value;
    console.log(updatedFields);
    setFields(updatedFields);
  };

  // State and functions for Status realizacji selects
  const [statusRealizacjiFields, setStatusRealizacjiFields] = useState([
    "", // Default Status realizacji 1 field
  ]);

  const handleStatusRealizacjiChange = (value, index) => {
    const updatedFields = [...statusRealizacjiFields];
    updatedFields[index] = value;
    setStatusRealizacjiFields(updatedFields);
  };

  const token = localStorage.getItem("token");
  let username; // Deklaracja zmiennej na wyższym poziomie zakresu
  let role; // Jeśli 'role' jest również używana później

  if (token) {
    const decoded = jwtDecode(token);
    username = decoded.username; // Przypisanie do wcześniej zadeklarowanej zmiennej
    role = decoded.role; // Przypisanie do wcześniej zadeklarowanej zmiennej
    console.log(username, role);
  }

  const [isFetching, setIsFetching] = useState(false);
  const [formData, setFormData] = useState({
    nrRef: "",
    importer: "",
    eksporter: "",
    nrFaktury: "",
    nrTranzytu: "",
    dokPrzedPrzybyciem: "",
    osobaWysylajacaOdprawe: "",
    osobaPrzygotowujacaOdprawe: "",
    nrRejestracyjnyPojazdyKurier: "",
    lokalizacjTowaru: "",
    SAD: "",
    MRN: "",
    Rewizja: "",
    statusRelizacji: "",
    rodzaj: "",
    pliki: "",
    rodzajTransportu: "",
    importersList: [], // Initialize importersList as an empty array
    eksporterList: [],
    fakturaBox: false,
    listPrzewozowyBox: false,
    kosztyTransportuBox: false,
    informacjeOTowarzeBox: false,
    T1Box: false,
    swiadectwoBox: false,
    createdBy: username,
  });

  const handleListOpen = (type) => {
    setListType(type);
    setListOpen(true);
  };

  const handleListClose = () => {
    setListOpen(false);
  };

  const handleListSelection = (value) => {
    if (listType === "importer") {
      setFormData((prevData) => ({
        ...prevData,
        importer: value,
      }));
    } else if (listType === "eksporter") {
      setFormData((prevData) => ({
        ...prevData,
        eksporter: value,
      }));
    }
    setListOpen(false);
  };

  const handleImportersFetch = async () => {
    setIsFetching(true);
    setTimeout(async () => {
      try {
        const response = await axios.get(
          "https://wega-api.com/list/importerImport",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        const importersData = response.data;

        const importersList = importersData.map((item) => item.name);
        importersList.sort();

        setImporterFilteredList(importersList); // Set the filtered list
      } catch (error) {
        console.error("Error fetching importers:", error);
      } finally {
        setIsFetching(false);
      }
    }, 100);
  };

  const handleExportersFetch = async () => {
    setIsFetching(true);
    setTimeout(async () => {
      try {
        const response = await axios.get(
          "https://wega-api.com/list/eksporterEksport",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        const exportersData = response.data;

        const exportersList = exportersData.map((item) => item.name);
        exportersList.sort();

        setExporterFilteredList(exportersList); // Set the filtered list
      } catch (error) {
        console.error("Error fetching exporters:", error);
      } finally {
        setIsFetching(false);
      }
    }, 100);
  };

  const handleUsersChange = async () => {
    setTimeout(async () => {
      try {
        const response = await axios.get(
          "https://wega-api.com/list/uzytkownicy",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );

        // Sort the data alphabetically by name using localeCompare
        const sortedData = response.data.sort((a, b) => {
          return a.name.localeCompare(b.name, "pl", { sensitivity: "base" });
        });

        setUzytkownicyList(sortedData); // Set the filtered list
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    }, 100);
  };

  const handleLokalizacjaChange = async () => {
    setTimeout(async () => {
      try {
        const response = await axios.get(
          "https://wega-api.com/list/lokalizacjaTransportow",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );

        // Sort the data alphabetically by name using localeCompare
        const sortedData = response.data.sort((a, b) => {
          return a.name.localeCompare(b.name, "pl", { sensitivity: "base" });
        });

        setLokalizacjaList(sortedData); // Set the filtered list
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    }, 100);
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;

    if (name === "rodzaj") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        importer: "",
        eksporter: "",
        importersList: [],
        eksporterList: [],
      }));

      if (value === "Import") {
        await handleImportersFetch();
      } else if (value === "Eksport") {
        await handleExportersFetch();
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    //setAutomatChecked(false);
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setFormData((prevData) => ({
        ...prevData,
        adresatDokumentow: "Automat",
      }));
    }

    setAutomatChecked(isChecked);
  };

  const handleCheckboxChangeUwagi = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.checked });
    console.log(formData);
  };

  const generateNewRefNumber = async () => {
    try {
      // Fetch the latest nrRef from the API
      const response = await axios.get("https://wega-api.com/getLatestNrRef");
      const latestNrRef = response.data[0]["MAX(id)"];

      // Increment the latest reference number
      const newRefNumber = latestNrRef - 5739;

      // Set the generated reference number in the state
      setGeneratedRefNumber(newRefNumber);

      return newRefNumber;
    } catch (error) {
      console.error("Error fetching latest nrRef:", error);
    }
  };

  const checkValuesInDatabase = async () => {
    const apiPromises = [];
    const existingValues = [];
    let eksporterValue; // Declare eksporterValue once outside the loop

    console.log("Starting to check values in database...");

    for (let i = 0; i < fields.length; i++) {
      if (typeof fields[i].nrFaktury === "undefined") {
        console.log(`Skipping undefined nrFaktury at index ${i}`);
        continue; // Skip this iteration if nrFaktury is undefined
      }
      const nrFakturyValues = fields[i].nrFaktury.split(",");
      const currentRodzaj = formData.rodzaj; // Get the current value of rodzaj

      if (currentRodzaj === "Eksport") {
        eksporterValue = formData.eksporter; // Use formData.eksporter when rodzaj is "Eksport"
      } else if (currentRodzaj === "Import") {
        eksporterValue = fields[i].eksporter.toUpperCase(); // Use fields[i].eksporter when rodzaj is "Import"
      }

      for (const nrFaktury of nrFakturyValues) {
        const trimmedNrFaktury = nrFaktury.trim();
        console.log(
          `Checking for eksporter: ${eksporterValue}, nrFaktury: ${trimmedNrFaktury}`
        );

        const apiPromise = axios
          .get("https://wega-api.com/checkValues", {
            params: {
              eksporter: eksporterValue,
              nrFaktury: trimmedNrFaktury,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          })
          .then((response) => ({ response, nrFaktury: trimmedNrFaktury }));

        apiPromises.push(apiPromise);
      }
    }

    try {
      const results = await Promise.all(apiPromises);
      results.forEach(({ response, nrFaktury }) => {
        console.log(
          `Response for eksporter: ${eksporterValue}, nrFaktury: ${nrFaktury}`,
          response.data
        );
        if (response.data.exists) {
          existingValues.push({
            eksporter: eksporterValue,
            nrFaktury: nrFaktury,
          });
        }
      });

      return existingValues;
    } catch (error) {
      console.error("Error checking values:", error);
      return [];
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let showAlert = false;

    // Validation checks
    if (
      formData.emailKorespondencyjny === "" ||
      formData.emailKorespondencyjny == null
    ) {
      alert("Proszę wpisać adres email do korespondencyjny.");
      return;
    }
    if (
      formData.adresatDokumentow === "" ||
      formData.adresatDokumentow == null
    ) {
      alert("Proszę wpisać/wybrać adresata dokumentów.");
      return;
    }
    if (formData.rodzajTransportu === "") {
      alert("Proszę wybrać rodzaj transportu.");
      return;
    }
    if (formData.rodzaj === "") {
      alert("Proszę wybrać rodzaj zgłoszenie Import, Eksport lub Tranzyt.");
      return;
    }
    if (formData.dokPrzedPrzybyciem === "") {
      alert("Proszę wybrać czy były dostarczone dokumenty przed przybyciem.");
      return;
    }
    console.log(formData.statusRelizacji);

    const existingValues = await checkValuesInDatabase();
    if (existingValues.length > 0) {
      showAlert = true;
      existingValues.forEach((value) => {
        const currentRodzaj = formData.rodzaj;
        let eksporterValue =
          currentRodzaj === "Eksport" ? formData.eksporter : value.eksporter;
        const alertMessage = `Nie można zapisać danych, ponieważ istnieją już rekordy z podanymi wartościami:\nEksporter: ${eksporterValue}, Nr. Faktury: ${value.nrFaktury}`;
        alert(alertMessage);
      });
      return;
    }

    if (showAlert) {
      return;
    }

    const newRefNumber = await generateNewRefNumber();

    try {
      // Convert checkbox values from true/false to 1/0
      const checkboxFields = [
        "fakturaBox",
        "listPrzewozowyBox",
        "kosztyTransportuBox",
        "informacjeOTowarzeBox",
        "T1Box",
        "swiadectwoBox",
      ];

      if (
        formData.rodzajTransportu === "Kurier" &&
        formData.lokalizacjTowaru === "MU MAN zakład"
      ) {
        formData.rodzajTransportu = "Kurier MU MAN zakład";
      }

      const convertedCheckboxes = checkboxFields.reduce((acc, field) => {
        acc[field] = formData[field] ? 1 : 0;
        return acc;
      }, {});

      const formDataWithDynamicFields = {
        ...formData,
        ...convertedCheckboxes, // Include the converted checkbox values
        nrRef: newRefNumber,
        created_at: new Date().toISOString(),
      };
      console.log(formDataWithDynamicFields);

      // console.log("Sprawdzanie statusu realizacji...");
      const requests = [];

      if (formData.rodzaj === "Tranzyt") {
        if (formData.waluta == null) {
          alert("Proszę wybrać walutę.");
          return;
        }
        if (formData.wagaNetto == null) {
          alert("Proszę wpisać wagę Netto.");
          return;
        }
        if (formData.wagaBrutto == null) {
          alert("Proszę wpisać wagę Brutto.");
          return;
        }
        if (formData.rodzajTranzyt == null) {
          alert("Proszę wybrać rodzaj Tranzytu.");
          return;
        }
        if (formData.urzadDocelowy == null) {
          alert("Proszę wpisać urząd docelowy.");
          return;
        }
        if (formData.kosztT1 == null) {
          alert("Proszę wpisać koszt T1.");
          return;
        }
        if (formData.nrObcegoTranzytu == null) {
          alert("Proszę wpisać numer obcego tranzytu.");
          return;
        }
        if (formData.DSK == null) {
          alert("Proszę wpisać DSK.");
          return;
        }
        if (formData.cloKwota == null) {
          alert("Proszę wpisać cło zabezpieczone/kwotę.");
          return;
        }
        if (formData.nrPojazdu == null) {
          alert("Proszę wpisać numer rejestracyjny pojazdu.");
          return;
        }
        if (formData.nrRejestracyjnyT1 == null) {
          alert("Proszę wpisać numer rejestracyjny do T1.");
          return;
        }
        if (formData.nrTranzytuWega == null) {
          alert("Proszę wpisać numer tranzytu WEGA.");
          return;
        }

        requests.push(
          axios.post(
            "https://wega-api.com/saveFormTranzyt",
            formDataWithDynamicFields,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          )
        );

        requests.push(
          axios.post(
            "https://wega-api.com/sendEmailTranzyt",
            formDataWithDynamicFields,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          )
        );
      } else {
        const isImport = formData.rodzaj === "Import";
        for (let i = 0; i < statusRealizacjiFields.length; i++) {
          const dynamicFieldsData = {
            nrFaktury: fields[i].nrFaktury,
            eksporter: isImport
              ? fields[i].eksporter.toUpperCase()
              : formData.eksporter.toUpperCase(),
            importer: isImport
              ? formData.importer.toUpperCase()
              : fields[i].importer.toUpperCase(),
            nrTranzytu: fields[i].nrTranzytu,
            expirationDate: fields[i].expirationDate,
            statusRealizacji: statusRealizacjiFields[i],
          };
          console.log("Dynamic fields data");
          console.log(dynamicFieldsData);
          const formDataWithDynamicFields = {
            ...formData,
            ...convertedCheckboxes,
            nrRef: newRefNumber,
            created_at: new Date().toISOString(),
            ...dynamicFieldsData,
          };

          if (
            formDataWithDynamicFields.statusRealizacji == null ||
            formDataWithDynamicFields.statusRealizacji === ""
          ) {
            alert("Proszę wybrać status realizacji.");
            return; // Teraz ta instrukcja zakończy działanie całej funkcji, nie tylko callbacku forEach
          }

          requests.push(
            axios.post(
              "https://wega-api.com/saveForm",
              formDataWithDynamicFields,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                  "Content-Type": "application/json",
                },
              }
            )
          );
          if (formDataWithDynamicFields.rodzaj === "Import") {
            requests.push(
              axios.post(
                "https://wega-api.com/sendEmailImport",
                formDataWithDynamicFields,
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "application/json",
                  },
                }
              )
            );
          }

          if (formDataWithDynamicFields.rodzaj === "Eksport") {
            requests.push(
              axios.post(
                "https://wega-api.com/sendEmailEksport",
                formDataWithDynamicFields,
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "application/json",
                  },
                }
              )
            );
          }
        }
      }

      // Execute all requests simultaneously
      const responses = await Promise.all(requests);
      const allSuccess = responses.every((response) => response.status === 200);

      if (allSuccess) {
        toast.success("Poprawnie zapisano nowe zgłoszenie i wysłano email!", {
          autoClose: 3000,
          onClose: () => navigate("/user"),
        });
      } else {
        toast.error(
          "Coś poszło nie tak, Skonaktuj się z administratorem storny i opowiedz o błędzie.",
          {
            autoClose: 3000,
          }
        );
      }
    } catch (error) {
      console.error("Error during form submission:", error);
      toast.error(
        "Wystąpil bląd podczas zapisywania danych, spróbuj ponownie później.",
        {
          autoClose: 3000,
        }
      );
    }
  };

  useEffect(() => {
    const fetchGeneratedRefNumber = async () => {
      const newRefNumber = await generateNewRefNumber();
      setGeneratedRefNumber(newRefNumber);
    };

    handleUsersChange();

    handleLokalizacjaChange();

    // Call the function to fetch the generated reference number when the component mounts
    fetchGeneratedRefNumber();
  }, []);

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <List>
          <ListItem button onClick={() => navigate("/user")}>
            <ListItemIcon>
              <ArrowBackIcon />
            </ListItemIcon>
            <ListItemText primary="Powrót do tabeli" />
          </ListItem>
          {/* Add the Logout button */}
          <ListItem button onClick={() => navigate("/login")}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Wyloguj" />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <Paper elevation={3} className={classes.formContainer}>
          <Typography variant="h5" className={classes.formTitle}>
            Dodaj nowe zgłoszenie
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3} justifyContent="center">
              {" "}
              {/* Set justifyContent to "center" */}
              <Grid item xs={12} sm={6}>
                <div>
                  {generatedRefNumber !== null && (
                    <Typography variant="h6" className={classes.formTitle}>
                      Numer referencyjny zgłoszenia: {generatedRefNumber}
                    </Typography>
                  )}
                </div>
                <FormControl
                  component="fieldset"
                  fullWidth
                  className={classes.marginUpAndDown}
                >
                  <FormLabel component="legend">Rodzaj transportu*</FormLabel>
                  <RadioGroup
                    name="rodzajTransportu"
                    value={formData.rodzajTransportu}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Kurier"
                      control={<Radio color="primary" />}
                      label="Kurier"
                    />
                    <FormControlLabel
                      value="Na Kołach"
                      control={<Radio color="primary" />}
                      label="Na Kołach"
                    />
                    <FormControlLabel
                      value="W drodze"
                      control={<Radio color="primary" />}
                      label="W drodze"
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl
                  component="fieldset"
                  fullWidth
                  className={classes.marginBottom}
                >
                  <FormLabel component="legend">
                    Jaki jest to rodzaj odprawy*
                  </FormLabel>
                  <RadioGroup
                    required // Add required attribute
                    aria-label="Czy zostały dostarczone dokumenty przed przybyciem?"
                    name="rodzaj"
                    value={formData.rodzaj}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Import"
                      control={<Radio color="primary" />}
                      label="Import"
                    />
                    <FormControlLabel
                      value="Eksport"
                      control={<Radio color="primary" />}
                      label="Eksport"
                    />
                    <FormControlLabel
                      value="Tranzyt"
                      control={<Radio color="primary" />}
                      label="Tranzyt"
                    />
                  </RadioGroup>
                </FormControl>
                {formData.rodzaj === "Import" && (
                  <>
                    <TextField
                      label="Importer*"
                      name="importer"
                      value={formData.importer}
                      onChange={handleChange}
                      autoComplete="off"
                      fullWidth
                      select
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                      onClick={() => handleListOpen("importer")}
                      onBlur={handleListClose}
                      onFocus={() => handleListOpen("importer")}
                      open={listOpen && listType === "importer"}
                    >
                      {importerFilteredList.map((importer, index) => (
                        <MenuItem
                          required // Add required attribute
                          key={index}
                          value={importer}
                          onClick={() => handleListSelection(importer)}
                        >
                          {importer}
                        </MenuItem>
                      ))}
                    </TextField>

                    {/* <TextField
                      label="Jeśli nie ma importera na liście wpisz go"
                      name="importer"
                      value={formData.importer}
                      onChange={handleChange}
                      fullWidth
                      required
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                    /> */}

                    {fields.map((field, index) => (
                      <div key={index}>
                        {/* Eksporter Fields */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "16px",
                            justifyContent: "space-between",
                          }}
                        >
                          <TextField
                            label={`Eksporter ${index + 1}*`}
                            name={`eksporter${index}`}
                            value={field.eksporter}
                            autoComplete="off"
                            onChange={(e) =>
                              handleFieldsChange(
                                "eksporter",
                                e.target.value,
                                index
                              )
                            }
                            fullWidth
                            style={{ marginRight: "8px" }}
                          />
                        </div>

                        {/* Nr. Tranzytu Fields */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "16px",
                          }}
                        >
                          <TextField
                            label={`Nr. Tranzytu ${index + 1}`}
                            name={`nrTranzytu${index}`}
                            value={field.nrTranzytu}
                            autoComplete="off"
                            onChange={(e) =>
                              handleFieldsChange(
                                "nrTranzytu",
                                e.target.value,
                                index
                              )
                            }
                            fullWidth
                            style={{ marginBottom: "8px" }}
                          />

                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Typography
                              variant="h8"
                              className={classes.formTitle}
                            >
                              Data wygaśnięcia T1
                            </Typography>
                            <DatePickerField
                              label={`Data wygaśnięcia dokumentów ${index + 1}`}
                              name={`expirationDate${index}`}
                              value={field.expirationDate}
                              autoComplete="off"
                              onChange={(date) =>
                                handleFieldsChange(
                                  "expirationDate",
                                  date,
                                  index
                                )
                              }
                              fullWidth
                              format="dd-MM-yyyy"
                              style={{ flex: 1 }}
                            />
                          </div>
                          <TextField
                            required // Add required attribute
                            label={`Nr. Faktury ${index + 1}`}
                            name={`nrFaktury${index}`}
                            value={field.nrFaktury}
                            autoComplete="off"
                            onChange={(e) =>
                              handleFieldsChange(
                                "nrFaktury",
                                e.target.value,
                                index
                              )
                            }
                            fullWidth
                            style={{ marginBottom: "16px" }}
                          />

                          {/* Status Realizacji Field */}
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "16px",
                              justifyContent: "space-between",
                            }}
                          >
                            <FormControl fullWidth>
                              <InputLabel id={`select-list-label-${index}`}>
                                Status realizacji {index + 1}
                              </InputLabel>
                              <Select
                                required
                                labelId={`select-list-label-${index}`}
                                id={`select-list-${index}`}
                                value={statusRealizacjiFields[index]} // Use statusRealizacjiFields[index] for value
                                onChange={(e) =>
                                  handleStatusRealizacjiChange(
                                    e.target.value,
                                    index
                                  )
                                }
                                name={`statusRealizacji${index}`}
                                className={classes.marginBottom}
                              >
                                <MenuItem value="Do przygotowania">
                                  Do przygotowania
                                </MenuItem>
                                <MenuItem value="Przygotowane">
                                  Przygotowane
                                </MenuItem>
                                <MenuItem value="Do weryfikacji">
                                  Do weryfikacji
                                </MenuItem>
                                <MenuItem value="Do wysłania">
                                  Do wysłania
                                </MenuItem>
                                <MenuItem value="Wysłane">Wysłane</MenuItem>
                                <MenuItem value="Zrealizowane">
                                  Zrealizowane
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        {/* Nr. Faktury Field */}
                        {index > 0 && (
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.marginBottom}
                            onClick={() => removeFields(index)}
                          >
                            -
                          </Button>
                        )}
                      </div>
                    ))}

                    {/* Common Add Fields button */}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={addFields}
                      className={classes.marginBottom}
                    >
                      +
                    </Button>
                  </>
                )}
                {formData.rodzaj === "Eksport" && (
                  <>
                    <TextField
                      label="Eksporter*"
                      name="eksporter"
                      value={formData.eksporter}
                      onChange={handleChange}
                      autoComplete="off"
                      fullWidth
                      select
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                      onClick={() => handleListOpen("eksporter")}
                      onBlur={handleListClose}
                      onFocus={() => handleListOpen("eksporter")}
                      open={listOpen && listType === "eksporter"}
                    >
                      {exporterFilteredList.map((exporter, index) => (
                        <MenuItem
                          key={index}
                          value={exporter}
                          onClick={() => handleListSelection(exporter)}
                        >
                          {exporter}
                        </MenuItem>
                      ))}
                    </TextField>

                    {/* <TextField
                      label="Jeśli nie ma eksportera na liście wpisz go"
                      name="eksporter"
                      value={formData.eksporter}
                      onChange={handleChange}
                      fullWidth
                      required
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                    /> */}

                    {fields.map((field, index) => (
                      <div key={index}>
                        {/* Eksporter Fields */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "16px",
                          }}
                        >
                          <TextField
                            label={`Importer ${index + 1}*`}
                            name={`importer${index}`}
                            value={field.importer}
                            autoComplete="off"
                            onChange={(e) =>
                              handleFieldsChange(
                                "importer",
                                e.target.value,
                                index
                              )
                            }
                            fullWidth
                            style={{ marginRight: "8px" }}
                          />
                        </div>

                        {/* Nr. Tranzytu Fields */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "16px",
                          }}
                        >
                          <TextField
                            required // Add required attribute
                            label={`Nr. Faktury ${index + 1}`}
                            name={`nrFaktury${index}`}
                            value={field.nrFaktury}
                            autoComplete="off"
                            onChange={(e) =>
                              handleFieldsChange(
                                "nrFaktury",
                                e.target.value,
                                index
                              )
                            }
                            fullWidth
                            style={{ marginBottom: "16px" }}
                          />

                          {/* Status Realizacji Field */}
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "16px",
                              justifyContent: "space-between",
                            }}
                          >
                            <FormControl fullWidth>
                              <InputLabel id={`select-list-label-${index}`}>
                                Status realizacji {index + 1}
                              </InputLabel>
                              <Select
                                labelId={`select-list-label-${index}`}
                                id={`select-list-${index}`}
                                value={statusRealizacjiFields[index]} // Use statusRealizacjiFields[index] for value
                                onChange={(e) =>
                                  handleStatusRealizacjiChange(
                                    e.target.value,
                                    index
                                  )
                                }
                                name={`statusRealizacji${index}`}
                                className={classes.marginBottom}
                              >
                                <MenuItem value="Do przygotowania">
                                  Do przygotowania
                                </MenuItem>
                                <MenuItem value="Przygotowane">
                                  Przygotowane
                                </MenuItem>
                                <MenuItem value="Do weryfikacji">
                                  Do weryfikacji
                                </MenuItem>
                                <MenuItem value="Do wysłania">
                                  Do wysłania
                                </MenuItem>
                                <MenuItem value="Wysłane">Wysłane</MenuItem>
                                <MenuItem value="Zrealizowane">
                                  Zrealizowane
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        {/* Nr. Faktury Field */}
                        {index > 0 && (
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.marginBottom}
                            onClick={() => removeFields(index)}
                          >
                            -
                          </Button>
                        )}
                      </div>
                    ))}

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={addFields}
                      className={classes.marginBottom}
                    >
                      +
                    </Button>
                  </>
                )}
                {formData.rodzaj === "Tranzyt" && (
                  <>
                    {/*
                  Dodac generowanie numer ref dla Tranzytu i zapisywanie w innej tabeli
                  */}
                    <TextField
                      label="Importer"
                      name="importer"
                      value={formData.importer}
                      onChange={handleChange}
                      fullWidth
                      autoComplete="off"
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                    />
                    <TextField
                      label="Eksporter"
                      name="eksporter"
                      value={formData.eksporter}
                      onChange={handleChange}
                      fullWidth
                      autoComplete="off"
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                    />
                    <TextField
                      label="nrFaktury"
                      name="nrFaktury"
                      value={formData.nrFaktury}
                      onChange={handleChange}
                      fullWidth
                      autoComplete="off"
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                    />
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel component="legend">Waluta</FormLabel>
                      <RadioGroup
                        aria-label="Waluta"
                        name="waluta"
                        value={formData.waluta}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="EUR"
                          control={<Radio color="primary" />}
                          label="Euro"
                        />
                        <FormControlLabel
                          value="USD"
                          control={<Radio color="primary" />}
                          label="USD"
                        />
                        <FormControlLabel
                          value="Zł"
                          control={<Radio color="primary" />}
                          label="Zł"
                        />
                        <FormControlLabel
                          value="GBP"
                          control={<Radio color="primary" />}
                          label="GBP"
                        />
                      </RadioGroup>
                    </FormControl>
                    <TextField
                      label="Waga Netto"
                      name="wagaNetto"
                      value={formData.wagaNetto}
                      onChange={handleChange}
                      fullWidth
                      autoComplete="off"
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                    />
                    <TextField
                      label="Waga Brutto"
                      name="wagaBrutto"
                      value={formData.wagaBrutto}
                      onChange={handleChange}
                      fullWidth
                      autoComplete="off"
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                    />

                    <FormControl component="fieldset" fullWidth>
                      <FormLabel component="legend">Rodzaj tranzytu</FormLabel>
                      <RadioGroup
                        aria-label="Rodzaj"
                        name="rodzajTranzyt"
                        value={formData.rodzajTranzyt}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="Import wega"
                          control={<Radio color="primary" />}
                          label="Import wega"
                        />
                        <FormControlLabel
                          value="Import inna agencja"
                          control={<Radio color="primary" />}
                          label="Import inna agencja"
                        />
                        <FormControlLabel
                          value="Tranzyt"
                          control={<Radio color="primary" />}
                          label="Tranzyt"
                        />
                        <FormControlLabel
                          value="Wprowadzenie na mcs"
                          control={<Radio color="primary" />}
                          label="Wprowadzenie na mcs"
                        />
                      </RadioGroup>
                    </FormControl>
                    <TextField
                      label="Urząd docelowy"
                      name="urzadDocelowy"
                      value={formData.urzadDocelowy}
                      onChange={handleChange}
                      fullWidth
                      autoComplete="off"
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                    />
                    <TextField
                      label="Koszt T1"
                      name="kosztT1"
                      value={formData.kosztT1}
                      onChange={handleChange}
                      fullWidth
                      autoComplete="off"
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                    />
                    <TextField
                      label="Numer obcego tranzytu"
                      name="nrObcegoTranzytu"
                      value={formData.nrObcegoTranzytu}
                      onChange={handleChange}
                      fullWidth
                      autoComplete="off"
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                    />
                    <TextField
                      label="DSK"
                      name="DSK"
                      value={formData.DSK}
                      onChange={handleChange}
                      fullWidth
                      autoComplete="off"
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                    />
                    <TextField
                      label="Cło zabezpieczone / Kwota"
                      name="cloKwota"
                      value={formData.cloKwota}
                      onChange={handleChange}
                      fullWidth
                      autoComplete="off"
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                    />
                    <TextField
                      label="Numer rejestracyjny pojazdu"
                      name="nrPojazdu"
                      value={formData.nrPojazdu}
                      onChange={handleChange}
                      fullWidth
                      autoComplete="off"
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                    />
                    <TextField
                      label="Numer rejestracyjny do T1"
                      name="nrRejestracyjnyT1"
                      value={formData.nrRejestracyjnyT1}
                      onChange={handleChange}
                      fullWidth
                      autoComplete="off"
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                    />
                    <TextField
                      label="SAD"
                      name="SAD"
                      value={formData.SAD}
                      onChange={handleChange}
                      fullWidth
                      autoComplete="off"
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                    />
                    <TextField
                      label="Numer tranzytu WEGA"
                      name="nrTranzytuWega"
                      value={formData.nrTranzytuWega}
                      onChange={handleChange}
                      fullWidth
                      autoComplete="off"
                      className={`${classes.marginBottom} ${
                        isFetching ? classes.fadeOut : classes.fadeIn
                      }`}
                      disabled={isFetching}
                    />
                    <FormControl fullWidth>
                      <InputLabel id={`select-list-label`}>
                        Status realizacji
                      </InputLabel>
                      <Select
                        labelId={`select-list-label`}
                        id={`select-list`}
                        value={formData.statusRealizacji} // Use statusRealizacjiFields[index] for value
                        onChange={handleChange}
                        name={`statusRealizacji`}
                        className={classes.marginBottom}
                      >
                        <MenuItem value="Do przygotowania">
                          Do przygotowania
                        </MenuItem>
                        <MenuItem value="Przygotowane">Przygotowane</MenuItem>
                        <MenuItem value="Do weryfikacji">
                          Do weryfikacji
                        </MenuItem>
                        <MenuItem value="Do wysłania">Do wysłania</MenuItem>
                        <MenuItem value="Wysłane">Wysłane</MenuItem>
                        <MenuItem value="Zrealizowane">Zrealizowane</MenuItem>
                      </Select>
                    </FormControl>
                  </>
                )}

                <FormControl component="fieldset" fullWidth>
                  <FormLabel component="legend">
                    Czy zostały dostarczone dokumenty przed przybyciem*
                  </FormLabel>
                  <RadioGroup
                    aria-label="Czy zostały dostarczone dokumenty przed przybyciem?"
                    name="dokPrzedPrzybyciem"
                    value={formData.dokPrzedPrzybyciem}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Tak"
                      control={<Radio color="primary" />}
                      label="Tak"
                    />
                    <FormControlLabel
                      value="Nie"
                      control={<Radio color="primary" />}
                      label="Nie"
                    />
                  </RadioGroup>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="select-list-label">
                    Osoba przygotowująca odprawe
                  </InputLabel>
                  <Select
                    labelId="select-list-label"
                    id="select-list"
                    value={formData.osobaPrzygotowujacaOdprawe} // Update this line to use the correct state field
                    onChange={handleChange} // Update the onChange event handler to use the handleChange function
                    name="osobaPrzygotowujacaOdprawe" // Update this line to use the correct state field name
                    className={classes.marginBottom} // Apply marginBottom style to the TextField
                  >
                    <MenuItem value="">Brak przypisania osoby</MenuItem>
                    {uzytkownicyList.map((item) => (
                      <MenuItem key={item.id} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="select-list-label">
                    Osoba wysyłająca odprawe
                  </InputLabel>
                  <Select
                    labelId="select-list-label"
                    id="select-list"
                    value={formData.osobaWysylajacaOdprawe} // Update this line to use the correct state field
                    onChange={handleChange} // Update the onChange event handler to use the handleChange function
                    name="osobaWysylajacaOdprawe" // Update this line to use the correct state field name
                    className={classes.marginBottom} // Apply marginBottom style to the TextField
                  >
                    <MenuItem value="">Brak przypisania osoby</MenuItem>
                    {uzytkownicyList.map((item) => (
                      <MenuItem key={item.id} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="select-list-label">
                    Osoba monitorująca odprawe
                  </InputLabel>
                  <Select
                    labelId="select-list-label"
                    id="select-list"
                    value={formData.osobaMonitorujacaOdprawe} // Update this line to use the correct state field
                    onChange={handleChange} // Update the onChange event handler to use the handleChange function
                    name="osobaMonitorujacaOdprawe" // Update this line to use the correct state field name
                    className={classes.marginBottom} // Apply marginBottom style to the TextField
                  >
                    <MenuItem value="">Brak przypisania osoby</MenuItem>
                    {uzytkownicyList.map((item) => (
                      <MenuItem key={item.id} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  label="Nr. Rejestracyjny Pojazdu/Kuriera"
                  name="nrRejestracyjnyPojazdyKurier"
                  value={formData.nrRejestracyjnyPojazdyKurier}
                  onChange={handleChange}
                  autoComplete="off"
                  fullWidth // Use fullWidth for TextField to match the width of the Paper container
                  className={classes.marginBottom} // Apply marginBottom style to the TextField
                />
                <FormControl fullWidth className={classes.marginBottom}>
                  <InputLabel id="select-list-label">
                    Lokalizacja towaru*
                  </InputLabel>
                  <Select
                    labelId="select-list-label"
                    id="select-list"
                    value={formData.lokalizacjTowaru} // Update this line to use the correct state field
                    onChange={handleChange} // Update the onChange event handler to use the handleChange function
                    name="lokalizacjTowaru" // Update this line to use the correct state field name
                    className={classes.marginBottom} // Apply marginBottom style to the TextField
                    required
                  >
                    {lokalizacjaList.map((item) => (
                      <MenuItem key={item.id} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <label>
                  Wybierz adresata dokumentów
                  <br />
                </label>
                <label>
                  Automat
                  <Checkbox
                    checked={automatChecked}
                    onChange={handleCheckboxChange}
                    //disabled={!!formData.adresatDokumentow} // Disable the checkbox when TextField has input
                  />
                </label>
                <TextField
                  label="Inny adresat"
                  name="adresatDokumentow"
                  value={formData.adresatDokumentow}
                  onChange={handleChange}
                  fullWidth
                  autoComplete="off"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.marginBottom}
                  disabled={automatChecked} // Disable the TextField when checkbox is checked
                />
                <TextField
                  label="Adres email do korespondencji"
                  name="emailKorespondencyjny"
                  value={formData.emailKorespondencyjny}
                  onChange={handleChange}
                  autoComplete="off"
                  fullWidth // Use fullWidth for TextField to match the width of the Paper container
                  className={classes.marginBottom} // Apply marginBottom style to the TextField
                />
                <label>
                  <br />
                  Oznacz braki w odprawie
                  <br />
                </label>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.fakturaBox}
                        onChange={handleCheckboxChangeUwagi}
                        name="fakturaBox"
                      />
                    }
                    label="Faktura"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.listPrzewozowyBox}
                        onChange={handleCheckboxChangeUwagi}
                        name="listPrzewozowyBox"
                      />
                    }
                    label="List Przewozowy"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.kosztyTransportuBox}
                        onChange={handleCheckboxChangeUwagi}
                        name="kosztyTransportuBox"
                      />
                    }
                    label="Koszty Transportu"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.informacjeOTowarzeBox}
                        onChange={handleCheckboxChangeUwagi}
                        name="informacjeOTowarzeBox"
                      />
                    }
                    label="Informacje o Towarze"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.T1Box}
                        onChange={handleCheckboxChangeUwagi}
                        name="T1Box"
                      />
                    }
                    label="T1"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.swiadectwoBox}
                        onChange={handleCheckboxChangeUwagi}
                        name="swiadectwoBox"
                      />
                    }
                    label="Świadectwo"
                  />
                </FormGroup>

                {/*}
                <TextField
                  label="Nr. SAD"
                  name="SAD"
                  value={formData.SAD}
                  onChange={handleChange}
                  fullWidth // Use fullWidth for TextField to match the width of the Paper container
                  className={classes.marginBottom} // Apply marginBottom style to the TextField
                />
                <TextField
                  label="Nr. MRN"
                  name="MRN"
                  value={formData.MRN}
                  onChange={handleChange}
                  fullWidth // Use fullWidth for TextField to match the width of the Paper container
                  className={classes.marginBottom} // Apply marginBottom style to the TextField
                />{*/}
                {/*<FormControl component="fieldset" fullWidth>
                  <FormLabel component="legend">
                    Czy została przeprowadzona rewizja?
                  </FormLabel>
                  <RadioGroup
                    aria-label=" Czy została przeprowadzona rewizja?"
                    name="Rewizja"
                    value={formData.Rewizja}
                    onChange={handleChange}>
                    <FormControlLabel
                      value="Tak"
                      control={<Radio color="primary" />}
                      label="Tak"
                    />
                    <FormControlLabel
                      value="Nie"
                      control={<Radio color="primary" />}
                      label="Nie"
                      className={classes.marginBottom} // Apply marginBottom style to the TextField
                    />
                  </RadioGroup>
              </FormControl>{*/}

                {/* Status realizacji fields */}
                {/*}
                {statusRealizacjiFields.map((field, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "16px",
                      justifyContent: "space-between",
                    }}>
                    <FormControl fullWidth>
                      <InputLabel id={`select-list-label-${index}`}>
                        Status realizacji
                      </InputLabel>
                      <Select
                        labelId={`select-list-label-${index}`}
                        id={`select-list-${index}`}
                        value={field}
                        onChange={(e) =>
                          handleStatusRealizacjiChange(e.target.value, index)
                        }
                        name={`statusRealizacji${index}`}
                        className={classes.marginBottom}>
                        <MenuItem value="Do przygotowania">
                          Do przygotowania
                        </MenuItem>
                        <MenuItem value="Przygotowane">Przygotowane</MenuItem>
                        <MenuItem value="Do wysłania">Do wysłania</MenuItem>
                        <MenuItem value="Wysłane">Wysłane</MenuItem>
                        <MenuItem value="Zrealizowane">Zrealizowane</MenuItem>
                      </Select>
                    </FormControl>
                    {index > 0 && (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => removeStatusRealizacjiField(index)}>
                        -
                      </Button>
                    )}
                  </div>
                    ))}

                <Button
                  variant="contained"
                  color="primary"
                  onClick={addStatusRealizacjiField}>
                  +
                </Button>{*/}

                {/* <TextField
                  label="Ścieżka do plików"
                  name="pliki"
                  value={formData.pliki}
                  onChange={handleChange}
                  fullWidth // Use fullWidth for TextField to match the width of the Paper container
                  className={classes.marginBottom} // Apply marginBottom style to the TextField
                /> */}

                {/* Add other input fields for the remaining data */}
              </Grid>
              {/* Add other Grid items for other input fields */}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </main>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default Form;
