import React, { useState } from "react";
import {
  makeStyles,
  Paper,
  TextField,
  Button,
  Typography,
  Container,
  CssBaseline,
  AppBar,
  Toolbar,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { createWniosek } from "./RejestrFunctions"; // Import funkcji API
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(10), // Dodaj margines od góry
  },
  paper: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: "800px", // Zwiększ maksymalną szerokość
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  header: {
    margin: theme.spacing(4, 0, 2),
    textAlign: "center",
  },
  textField: {
    width: "100%", // Ustaw szerokość na 100% szerokości kontenera
    "& .MuiOutlinedInput-root": {
      height: "auto", // Automatyczna wysokość
    },
  },
}));

const NowyWniosek = () => {
  const classes = useStyles();
  const [wniosek, setWniosek] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const result = await createWniosek(wniosek);
      if (result.status === 200) {
        toast.success("Wniosek utworzony pomyślnie!");
        setTimeout(() => {
          navigate("/rejestrWnioskow"); // Przekierowanie po utworzeniu wniosku
        }, 1500);
      }
    } catch (error) {
      console.log(error);
      toast.error("Błąd podczas tworzenia wniosku.");
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            Nowy Wniosek
          </Typography>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="sm" className={classes.container}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5" className={classes.header}>
            Nowy wniosek
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="wniosek"
              label="Wprowadź dane wniosku"
              name="wniosek"
              autoComplete="wniosek"
              autoFocus
              value={wniosek}
              onChange={(e) => setWniosek(e.target.value)}
              multiline
              rows={15} // Ustawienie wysokości na 15 wierszy
              className={classes.textField}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Utwórz
            </Button>
          </form>
        </Paper>
      </Container>
      <ToastContainer position="top-right" autoClose={1500} />
    </div>
  );
};

export default NowyWniosek;
