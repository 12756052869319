import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./styles.module.css";

const Login = ({ setAuthenticated }) => {
  const [data, setData] = useState({ username: "", password: "" });
  const [error, setError] = useState("");
  const [isLoginSuccess, setLoginSuccess] = useState(false); // Track login success state
  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input.name]: input.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Clear the error state when submitting

    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const url = "https://wega-api.com/login";
      const { data: res } = await axios.post(url, data, {
        headers: headers,
      });
      console.log(res.token); // Sprawdź, czy token jest otrzymywany
      localStorage.setItem("token", res.token);
      //localStorage.setItem("username", res.username);

      // Set login success state to true
      setLoginSuccess(true);
      setAuthenticated(true);
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setError(error.response.data.message);
      }
    }
  };

  // Inside the useEffect block
  useEffect(() => {
    if (isLoginSuccess) {
      toast.success("Login successful!", {
        autoClose: 2000,
        onClose: () => {
          navigate("/user");
        },
      });
    }
  }, [isLoginSuccess, navigate]);

  return (
    <div className={styles.login_container}>
      <div className={styles.login_form_container}>
        <div className={styles.left}>
          <form className={styles.form_container} onSubmit={handleSubmit}>
            <h1>Zaloguj się</h1>
            <input
              type="text"
              placeholder="Username"
              name="username"
              onChange={handleChange}
              value={data.username}
              required
              className={styles.input}
            />
            <input
              type="password"
              placeholder="Hasło"
              name="password"
              onChange={handleChange}
              value={data.password}
              required
              className={styles.input}
            />
            {error && <div className={styles.error_msg}>{error}</div>}
            <button
              type="submit"
              className={styles.login_btn}
              onClick={handleSubmit}
            >
              Zaloguj się
            </button>
            <ToastContainer />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
