// HalfTable.js
import React from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";

const HalfTable = ({ usernameCounts, allowedNames }) => {
  // Filter allowed usernames
  const filteredUsernames = Object.keys(usernameCounts).filter((username) =>
    allowedNames.some((allowedUser) => allowedUser.name === username)
  );

  // Sort the usernames alphabetically with "Brak przypisanych spraw" on top
  filteredUsernames.sort((a, b) =>
    a === "Brak przypisanych spraw"
      ? -1
      : b === "Brak przypisanych spraw"
      ? 1
      : a.localeCompare(b)
  );

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Użytkownik</TableCell>
            <TableCell>Ilość</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredUsernames.map((username) => (
            <TableRow key={username}>
              <TableCell>{username}</TableCell>
              <TableCell>{usernameCounts[username]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HalfTable;
