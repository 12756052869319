import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment-timezone";
import { jwtDecode } from "jwt-decode";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  CircularProgress,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  FormLabel,
} from "@material-ui/core";
import {
  ExitToApp as ExitToAppIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import EmailSender from "./EmailSender";
const drawerWidth = "10%";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: "100%",
    overflowX: "auto",
  },
  tableContainer: {
    minWidth: 700,
    maxHeight: "calc(100vh - 180px)",
    overflowY: "auto",
  },
  tableHeaderRow: {
    backgroundColor: "#e1f5fe",
    position: "sticky",
    top: 0,
    zIndex: 1,
    fontSize: "18px",
    fontWeight: 800,
  },
  tableHeaderLabel: {
    fontSize: "10px",
    fontWeight: "bold",
  },
  tableTitle: {
    textAlign: "center",
  },
  tableHeaderCell: {
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  loaderContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(255, 255, 255, 0.8)",
  },
  editForm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  inputField: {
    minWidth: "300px",
  },
  fieldList: {
    width: "100%", // Ustawienie szerokości na 100% ekranu
    maxWidth: "100%", // Zapobieganie rozciąganiu się poza ekran
    boxSizing: "border-box", // Uwzględnienie paddingu i bordera w szerokości
    // ... inne style, które mógłbyś mieć ...
  },
  fieldListItem: {
    marginRight: theme.spacing(2),
  },
  buttonsContainerTopLeft: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(2),
  },
  komentarzeHeading: {
    marginTop: theme.spacing(2),
    fontSize: "30px",
    fontWeight: 450,
  },
  komentarzeBody: {
    fontSize: "18px",
    fontWeight: 300,
  },
  link: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  fieldBlocksContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
    justifyContent: "center",
    width: "80%",
  },
  fieldBlock: {
    background: theme.palette.primary.main,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    cursor: "pointer",

    transition: "background 0.3s ease",
    "&:hover": {
      background: theme.palette.primary.dark,
    },
  },
  fieldLabel: {
    color: theme.palette.common.white,
    fontWeight: 500,
    textAlign: "center",
  },
  closeButton: {
    marginTop: theme.spacing(2),
  },
  formControlWidth: {
    width: "60%",
  },
  categoryRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start", // Wyrównanie do lewej strony
    flexWrap: "wrap", // Zapobieganie przewijaniu poprzez zawijanie elementów
    marginBottom: theme.spacing(2),
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
      paddingBottom: theme.spacing(2),
    },
  },
  categoryHeading: {
    fontWeight: "bold",
    marginRight: theme.spacing(2),
  },
  fieldBlocksRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap", // Pozwala elementom przechodzić do nowej linii, jeśli nie mieszczą się
    gap: theme.spacing(1),
  },
  fieldBlock: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main, // Przykładowy kolor z palety tematu
    borderRadius: theme.shape.borderRadius,
    cursor: "pointer",
    transition: "background-color 0.3s",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark, // Przykładowy ciemniejszy kolor z palety tematu
    },
  },
}));

const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};
const fieldLabels = {
  nrRef: "nr. Referencyjny",
  importer: "Importer",
  eksporter: "Eksporter",
  nrFaktury: "nr. Faktury",
  nrTranzytu: "nr. Tranzytu",
  dokPrzedPrzybyciem: "Dokumenty przed przybyciem",
  osobaWeryfikujacaOdprawe: "Osoba weryfikująca odprawe",
  osobaWysylajacaOdprawe: "Osoba wysyłająca odprawe",
  osobaPrzygotowujacaOdprawe: "Osoba przygotowujaca odprawe",
  osobaMonitorujacaOdprawe: "Osoba monitorująca odprawe",
  nrRejestracyjnyPojazdyKurier: "nr. Rejestracyjny pojazdu/kuriera",
  lokalizacjaTowaru: "Lokalizacja towaru",
  SAD: "SAD",
  MRN: "MRN",
  Rewizja: "Rewizja",
  statusRealizacji: "Status realizacji",
  rodzaj: "Rodzaj odprawy",
  pliki: "Ścieżka do plików",
  rodzajTransportu: "Rodzaj Transportu",
  data: "Data wygaśnięcia T1",
  adresatDokumentow: "Adresat dokumantów",
  wagaNetto: "Waga Netto",
  wagaBrutto: "Waga Brutto",
  waluta: "Waluta",
  rodzajTranzyt: "Rodzaj tranzytu",
  urzadDocelowy: "Urząd docelowy",
  kosztT1: "Koszt T1",
  nrObcegoTranzytu: "Nr. obcego tranzytu",
  cloKwota: "Cło/Kwota",
  nrPojazdu: "Nr. Pojazdu",
  nrRejestracyjnyT1: "Nr. rejestracyjny T1",
  nrTranzytuWega: "Nr. tranzytu Wega",
  emailKorespondencyjny: "Email korespondencyjny",
};

const categories = {
  "Informacje Podstawowe": [
    "nrRef",
    "importer",
    "eksporter",
    "nrFaktury",
    "nrTranzytu",
  ],
  "Szczegóły Odprawy": [
    "dokPrzedPrzybyciem",
    "osobaWeryfikujacaOdprawe",
    "osobaWysylajacaOdprawe",
    "osobaPrzygotowujacaOdprawe",
    "osobaMonitorujacaOdprawe",
    "statusRealizacji",
    "rodzaj",
    "Rewizja",
  ],
  "Transport i Lokalizacja": [
    "nrRejestracyjnyPojazdyKurier",
    "lokalizacjaTowaru",
    "rodzajTransportu",
    "nrPojazdu",
    "nrRejestracyjnyT1",
  ],
  "Dokumentacja i Pliki": [
    "SAD",
    "MRN",
    "pliki",
    "adresatDokumentow",
    "emailKorespondencyjny",
  ],
  "Szczegóły Finansowe i Tranzytu": [
    "wagaNetto",
    "wagaBrutto",
    "waluta",
    "rodzajTranzyt",
    "urzadDocelowy",
    "kosztT1",
    "nrObcegoTranzytu",
    "cloKwota",
    "nrTranzytuWega",
  ],
  "Daty i Terminy": ["data"],
};

const CommentSection = ({ id }) => {
  const classes = useStyles();
  const [comment, setComment] = useState("");
  const [showCommentPrompt, setShowCommentPrompt] = useState(false);
  const [fakturaBox, setFakturaBox] = useState(false);
  const [listPrzewozowyBox, setListPrzewozowyBox] = useState(false);
  const [kosztyTransportuBox, setKosztyTransportuBox] = useState(false);
  const [informacjeOTowarzeBox, setInformacjeOTowarzeBox] = useState(false);
  const [T1Box, setT1Box] = useState(false);
  const [swiadectwoBox, setSwiadectwoBox] = useState(false);
  const [plikiBox, setPlikiBox] = useState(false);
  const [sankcjeBox, setSankcjeBox] = useState(false);
  const [CBAMBox, setCBAMBox] = useState(false);
  const [CEBox, setCEBox] = useState(false);

  const [wagaMailBox, setwagaMailBox] = useState(false);
  const [wagaTabelaBox, setwagaTabelaBox] = useState(false);
  const [wagaPropBox, setwagaPropBox] = useState(false);
  const [wagaDokBox, setwagaDokpBox] = useState(false);

  const [kilkaRazyBox, setKilkaRazyBox] = useState(false);
  const [wiecejNiz3Box, setWiecejNiz3Box] = useState(false);
  const [odprawaPo16Box, setOdprawaPo16Box] = useState(false);
  const [zglosBladBox, setZglosBladBox] = useState(false);

  const [userCheckedOznaczUwagi, setUserCheckedOznaczUwagi] = useState(false);

  const [emailData, setEmailData] = useState([]);
  const { nrRef } = useParams();

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (name === "wagaMail") {
      setwagaMailBox(checked);
      setwagaTabelaBox(false);
      setwagaPropBox(false);
    } else if (name === "wagaTabela") {
      setwagaTabelaBox(checked);
      setwagaMailBox(false);
      setwagaPropBox(false);
    } else if (name === "wagaProp") {
      setwagaPropBox(checked);
      setwagaMailBox(false);
      setwagaTabelaBox(false);
    }

    const token = localStorage.getItem("token");
    const decoded = jwtDecode(token);
    const role = decoded.role;
    const username = decoded.username;
    const canModify = ["admin"].includes(role);

    if (
      ["zglosBlad", "kilkaRazy", "wiecejNiz3", "odprawaPo16"].includes(name)
    ) {
      setUserCheckedOznaczUwagi(checked);
    }

    if (zglosBladBox || kilkaRazyBox || wiecejNiz3Box || odprawaPo16Box) {
      setShowCommentPrompt(true);
    } else {
      setShowCommentPrompt(false);
    }

    switch (name) {
      case "faktura":
        setFakturaBox(checked);
        break;
      case "listPrzewozowy":
        setListPrzewozowyBox(checked);
        break;
      case "kosztyTransportu":
        setKosztyTransportuBox(checked);
        break;
      case "informacjeOTowarze":
        setInformacjeOTowarzeBox(checked);
        break;
      case "T1":
        setT1Box(checked);
        break;
      case "swiadectwo":
        setSwiadectwoBox(checked);
        break;
      case "pliki":
        setPlikiBox(checked);
        break;
      case "sankcje":
        setSankcjeBox(checked);
        break;
      case "CBAM":
        setCBAMBox(checked);
        break;
      case "CE":
        setCEBox(checked);
        break;
      case "wagaMail":
        setwagaMailBox(checked);
        break;
      case "wagaTabela":
        setwagaTabelaBox(checked);
        break;
      case "wagaProp":
        setwagaPropBox(checked);
        break;
      case "wagaDok":
        setwagaDokpBox(checked);
        break;
      case "kilkaRazy":
      case "wiecejNiz3":
      case "odprawaPo16":
      case "zglosBlad":
        if (checked || canModify) {
          // Set the corresponding state based on the checkbox name
          if (name === "kilkaRazy") setKilkaRazyBox(checked);
          else if (name === "wiecejNiz3") setWiecejNiz3Box(checked);
          else if (name === "odprawaPo16") setOdprawaPo16Box(checked);
          else if (name === "zglosBlad") setZglosBladBox(checked);

          // Set user interaction state and show comment prompt
          setUserCheckedOznaczUwagi(true);
          setShowCommentPrompt(true);
        }
        break;

      default:
        break;
    }
  };

  // Function to handle comment input change
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleSaveComment = async () => {
    try {
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      const username = decoded.username;
      // Make an API call to save the comment
      // eslint-disable-next-line no-unused-vars
      const response = await axios.post(
        `https://wega-api.com/comments/${id}`,
        { comment, username },
        {
          headers: headers,
        }
      );

      // Show a success toast notification when comment is saved
      toast.success("Dodano nowy komentarz!");

      // Clear the comment input field after successful save
      setComment("");
      // Reload the page after the toast notification is closed (1-second delay for a smoother experience)
      setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
      console.error("Error saving comment:", error);
      // Handle the error here (e.g., show an error message)
      toast.error("Error saving comment.");
    }
  };
  const notify = () => {
    toast.success("Dane zapisano poprawnie!", {
      autoClose: 1000, // Set autoClose to the number of milliseconds you want the notification to be visible, in this case, 1 second
    });
  };

  const handleSaveCheckboxData = async () => {
    try {
      // Convert the boolean values to 0 or 1
      const checkboxDataPayload = {
        fakturaChecked: fakturaBox ? 1 : 0,
        listPrzewozowyChecked: listPrzewozowyBox ? 1 : 0,
        kosztyTransportuChecked: kosztyTransportuBox ? 1 : 0,
        informacjeOTowarzeChecked: informacjeOTowarzeBox ? 1 : 0,
        T1Checked: T1Box ? 1 : 0,
        swiadectwoChecked: swiadectwoBox ? 1 : 0,
        plikiChecked: plikiBox ? 1 : 0,
        sankcjeChecked: sankcjeBox ? 1 : 0,
        CBAMChecked: CBAMBox ? 1 : 0,
        CEChecked: CEBox ? 1 : 0,
        wagaMailChecked: wagaMailBox ? 1 : 0,
        wagaTabelaChecked: wagaTabelaBox ? 1 : 0,
        wagaPropChecked: wagaPropBox ? 1 : 0,
        wagaDokChecked: wagaDokBox ? 1 : 0,
        kilkaRazyChecked: kilkaRazyBox ? 1 : 0,
        wiecejNiz3Checked: wiecejNiz3Box ? 1 : 0,
        odprawaPo16Checked: odprawaPo16Box ? 1 : 0,
        zglosBladChecked: zglosBladBox ? 1 : 0,
      };

      // Make the PUT request to update the checkbox data
      await axios.put(
        `https://wega-api.com/update-checkbox-data/${id}`,
        checkboxDataPayload,
        {
          headers: headers,
        }
      );

      // Show a success toast notification when data is updated
      notify();
    } catch (error) {
      console.error("Error saving checkbox data:", error);
      // Handle the error here (e.g., show an error message)
      toast.error("Wystąpił błąd przy zapisywaniu danych.");
    }
  };

  const handleSaveCommentAndCheckBox = async () => {
    try {
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      const username = decoded.username;

      // Check if the comment is empty
      if (!comment.trim()) {
        // Show a toast notification if the comment is empty
        toast.warning("Komentarz nie może być pusty!");
        return; // Exit the function without making the API call
      }
      // Make an API call to save the comment
      // eslint-disable-next-line no-unused-vars
      const response = await axios.post(
        `https://wega-api.com/comments/${id}`,
        { comment, username },
        {
          headers: headers,
        }
      );

      // Convert the boolean values to 0 or 1
      const checkboxDataPayload = {
        fakturaChecked: fakturaBox ? 1 : 0,
        listPrzewozowyChecked: listPrzewozowyBox ? 1 : 0,
        kosztyTransportuChecked: kosztyTransportuBox ? 1 : 0,
        informacjeOTowarzeChecked: informacjeOTowarzeBox ? 1 : 0,
        T1Checked: T1Box ? 1 : 0,
        swiadectwoChecked: swiadectwoBox ? 1 : 0,
        plikiChecked: plikiBox ? 1 : 0,
        sankcjeChecked: sankcjeBox ? 1 : 0,
        CBAMChecked: CBAMBox ? 1 : 0,
        CEChecked: CEBox ? 1 : 0,
        wagaMailChecked: wagaMailBox ? 1 : 0,
        wagaTabelaChecked: wagaTabelaBox ? 1 : 0,
        wagaPropChecked: wagaPropBox ? 1 : 0,
        wagaDokChecked: wagaDokBox ? 1 : 0,
        kilkaRazyChecked: kilkaRazyBox ? 1 : 0,
        wiecejNiz3Checked: wiecejNiz3Box ? 1 : 0,
        odprawaPo16Checked: odprawaPo16Box ? 1 : 0,
        zglosBladChecked: zglosBladBox ? 1 : 0,
      };

      // Make the PUT request to update the checkbox data
      await axios.put(
        `https://wega-api.com/update-checkbox-data/${id}`,
        checkboxDataPayload,
        {
          headers: headers,
        }
      );

      // Show a success toast notification when comment is saved
      toast.success("Zapisano znacznik oraz komentarz!");

      // Clear the comment input field after successful save
      setComment("");
      // Reload the page after the toast notification is closed (1-second delay for a smoother experience)
      setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
      console.error("Error saving comment:", error);
      // Handle the error here (e.g., show an error message)
      toast.error("Wystąpił błąd przy zapisie znaczniki oraz komentarz.");
    }
  };

  // Function to fetch checkbox data from the backend
  const fetchCheckboxData = async () => {
    try {
      const response = await axios.get(
        `https://wega-api.com/fetch-checkbox-data/${id}`,
        {
          headers: headers,
        }
      );

      // Assuming the response contains the checkbox data as an object
      const checkboxData = response.data;
      setFakturaBox(checkboxData.fakturaBox);
      setListPrzewozowyBox(checkboxData.listPrzewozowyBox);
      setKosztyTransportuBox(checkboxData.kosztyTransportuBox);
      setInformacjeOTowarzeBox(checkboxData.informacjeOTowarzeBox);
      setT1Box(checkboxData.T1Box);
      setSwiadectwoBox(checkboxData.swiadectwoBox);
      setPlikiBox(checkboxData.plikiBox);
      setSankcjeBox(checkboxData.sankcjeBox);
      setCBAMBox(checkboxData.CBAMBox);
      setCEBox(checkboxData.CEBox);
      setwagaMailBox(checkboxData.wagaMailBox);
      setwagaTabelaBox(checkboxData.wagaTabelaBox);
      setwagaPropBox(checkboxData.wagaPropBox);
      setwagaDokpBox(checkboxData.wagaDokBox);
      setKilkaRazyBox(checkboxData.kilkaRazyBox);
      setWiecejNiz3Box(checkboxData.wiecejNiz3Box);
      setOdprawaPo16Box(checkboxData.odprawaPo16Box);
      setZglosBladBox(checkboxData.zglosBladBox);
    } catch (error) {
      console.error("Error fetching checkbox data:", error);
    }
  };

  const fetchDataByNrRef = async () => {
    try {
      const response = await axios.get(
        `https://wega-api.com/getEmailDataByNrRef/${nrRef}`,
        {
          headers: headers,
        }
      );

      if (response.status === 200) {
        console.log("Dane pobrane pomyślnie:", response.data);
        return response.data; // Zwraca dane z odpowiedzi
      } else {
        // Obsługa odpowiedzi innej niż 200 OK
        console.error("Nie udało się pobrać danych dla nrRef:", nrRef);
      }
    } catch (error) {
      console.error("Błąd podczas pobierania danych:", error);
      throw error; // Rzucenie wyjątku do dalszej obsługi błędów
    }
  };

  function EmailTable({ emails }) {
    const tableContainerStyle = {
      display: "grid",
      // Adjusting column sizes: make "Treść wiadomości" wider and "Adres email" smaller
      gridTemplateColumns: "minmax(50px, auto) 3fr 1fr auto",
      gap: "1px",
      marginTop: "20px",
      backgroundColor: "#ddd",
    };
    const rowStyle = {
      display: "contents",
    };
    const cellStyle = {
      padding: "8px",
      textAlign: "left",
      backgroundColor: "#fff",
    };

    if (emails.length === 0) {
      return null;
    }

    return (
      <>
        <h2>Wysłane maile z DutyStation</h2>
        <div style={tableContainerStyle}>
          <div style={rowStyle}>
            <div style={cellStyle}>
              <strong>Lp.</strong>
            </div>
            <div style={cellStyle}>
              <strong>Treść wiadomości</strong>
            </div>
            <div style={cellStyle}>
              <strong>Adres email</strong>
            </div>
            <div style={cellStyle}>
              <strong>Data wysłania</strong>
            </div>
          </div>
          {emails.map((email, index) => (
            <div key={index} style={rowStyle}>
              <div style={cellStyle}>{index + 1}</div>
              <div
                style={cellStyle}
                dangerouslySetInnerHTML={{ __html: email.text }}
              />
              <div style={cellStyle}>{email.email}</div>
              <div style={cellStyle}>
                {new Date(email.dataWyslania).toLocaleString("pl-PL", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                })}
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }

  useEffect(() => {
    fetchCheckboxData();
    const fetchData = async () => {
      try {
        const data = await fetchDataByNrRef(nrRef);
        // console.log(data);
        setEmailData(data);
      } catch (error) {
        console.error("Error fetching email data:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
          <Typography variant="h6">
            <strong>Oznacz braki do odprawy celnej:</strong>
          </Typography>

          <FormControlLabel
            control={
              <Checkbox
                checked={fakturaBox}
                onChange={handleCheckboxChange}
                name="faktura"
                color="primary"
              />
            }
            label="Faktura"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={listPrzewozowyBox}
                onChange={handleCheckboxChange}
                name="listPrzewozowy"
                color="primary"
              />
            }
            label="List przewozowy"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={kosztyTransportuBox}
                onChange={handleCheckboxChange}
                name="kosztyTransportu"
                color="primary"
              />
            }
            label="Koszty transportu"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={informacjeOTowarzeBox}
                onChange={handleCheckboxChange}
                name="informacjeOTowarze"
                color="primary"
              />
            }
            label="Informacje o towarze"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={T1Box}
                onChange={handleCheckboxChange}
                name="T1"
                color="primary"
              />
            }
            label="T1"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={swiadectwoBox}
                onChange={handleCheckboxChange}
                name="swiadectwo"
                color="primary"
              />
            }
            label="Świadectwo"
          />
          <br />
          <Typography variant="h6">
            <strong>Przygotowano plik .xls do odprawy:</strong>
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={plikiBox}
                onChange={handleCheckboxChange}
                name="pliki"
                color="primary"
              />
            }
            label="Pliki do zaczytania"
          />
          <br />

          <Typography variant="h6">
            <strong>Oznacz czy towar podlega dodatkowym ograniczeniom:</strong>
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={sankcjeBox}
                onChange={handleCheckboxChange}
                name="sankcje"
                color="primary"
              />
            }
            label="Sankcje"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={CBAMBox}
                onChange={handleCheckboxChange}
                name="CBAM"
                color="primary"
              />
            }
            label="CBAM"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={CEBox}
                onChange={handleCheckboxChange}
                name="CE"
                color="primary"
              />
            }
            label="CE"
          />
          <br />

          {CBAMBox && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={wagaMailBox}
                    onChange={handleCheckboxChange}
                    name="wagaMail"
                    color="secondary"
                    disabled={wagaTabelaBox || wagaPropBox || wagaDokBox}
                  />
                }
                label="Waga wg informacji z maila"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={wagaTabelaBox}
                    onChange={handleCheckboxChange}
                    name="wagaTabela"
                    color="secondary"
                    disabled={wagaMailBox || wagaPropBox || wagaDokBox}
                  />
                }
                label="Waga wg danych z tabeli"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={wagaPropBox}
                    onChange={handleCheckboxChange}
                    name="wagaProp"
                    color="secondary"
                    disabled={wagaMailBox || wagaTabelaBox || wagaDokBox}
                  />
                }
                label="Waga proporcjonalnie"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={wagaDokBox}
                    onChange={handleCheckboxChange}
                    name="wagaDok"
                    color="secondary"
                    disabled={wagaMailBox || wagaTabelaBox || wagaPropBox}
                  />
                }
                label="Waga z dokumentów"
              />
            </>
          )}
          <Typography variant="h6">
            <strong>Oznacz uwagi</strong>
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={zglosBladBox}
                onChange={handleCheckboxChange}
                name="zglosBlad"
                color="primary"
              />
            }
            label="Zgłoś bląd w przygotowanej odprawie"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={kilkaRazyBox}
                onChange={handleCheckboxChange}
                name="kilkaRazy"
                color="primary"
              />
            }
            label="Odprawa wysłana kilka razy ze względu na błąd klienta"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={wiecejNiz3Box}
                onChange={handleCheckboxChange}
                name="wiecejNiz3"
                color="primary"
              />
            }
            label="Więcej niż 3 pozycje na fakturze zostały wprowadzone ręcznie"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={odprawaPo16Box}
                onChange={handleCheckboxChange}
                name="odprawaPo16"
                color="primary"
              />
            }
            label="Odprawa zlecona po godzinie 16:00"
          />

          {/* {userCheckedOznaczUwagi &&
            (zglosBladBox || kilkaRazyBox || wiecejNiz3Box || odprawaPo16Box) &&
            showCommentPrompt && (
              <>
                <Typography variant="h6">
                  <strong>
                    *Napisz opis do znacznika (Komentarz jest wymagany):
                  </strong>
                </Typography>
                <textarea
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  rows={4}
                  cols={50}
                  placeholder="Wpisz komentarz"
                  style={{
                    resize: "none",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                />
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveCommentAndCheckBox}
                  >
                    Zapisz komentarz oraz znacznik
                  </Button>
                </div>
              </>
            )} */}

          {userCheckedOznaczUwagi && showCommentPrompt && (
            <>
              <Typography variant="h6">
                <strong>
                  *Napisz opis do znacznika (Komentarz jest wymagany):
                </strong>
              </Typography>
              <textarea
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                rows={4}
                cols={50}
                placeholder="Wpisz komentarz"
                style={{
                  resize: "none",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              />
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveCommentAndCheckBox}
                >
                  Zapisz komentarz oraz znacznik
                </Button>
              </div>
            </>
          )}

          <br />

          {!userCheckedOznaczUwagi && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveCheckboxData}
            >
              Zapisz dane z checkboxów
            </Button>
          )}
        </div>
        <EmailTable emails={emailData} />
        <Typography
          variant="h6"
          className={classes.komentarzeHeading}
        ></Typography>
        <textarea
          value={comment}
          onChange={handleCommentChange}
          rows={4}
          cols={50}
          placeholder="Wpisz swój komentarz..."
          style={{ resize: "none", marginTop: "10px", marginBottom: "10px" }}
        />
        <Button variant="contained" color="primary" onClick={handleSaveComment}>
          Zapisz
        </Button>
      </div>
    </>
  );
};

const DetailPage = () => {
  const classes = useStyles();
  const { nrRef, id } = useParams();
  const [rowData, setRowData] = useState(null);
  const [editField, setEditField] = useState(null);
  const [availableFields, setAvailableFields] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [comments, setComments] = useState([]);
  const [uzytkownicyList, setUzytkownicyList] = useState([]);
  const [lokalizacjaList, setLokalizacjaList] = useState([]);
  const token = localStorage.getItem("token");
  let username; // Deklaracja zmiennej na wyższym poziomie zakresu
  let role; // Jeśli 'role' jest również używana później

  if (token) {
    const decoded = jwtDecode(token);
    username = decoded.username; // Przypisanie do wcześniej zadeklarowanej zmiennej
    role = decoded.role; // Przypisanie do wcześniej zadeklarowanej zmiennej
    console.log(username, role);
  }

  const importRodzajURL =
    "https://acwegacom.sharepoint.com/sites/AgencjaCelnaWega-ASp.zo.o/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FAgencjaCelnaWega%2DASp%2Ezo%2Eo%2FShared%20Documents%2FPRZYW%C3%93Z&viewid=c9ebcf23%2Dd938%2D4dfc%2D8ef2%2D74695f94ddee";
  const eksportRodzajURL =
    "https://acwegacom.sharepoint.com/sites/AgencjaCelnaWega-ASp.zo.o/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FAgencjaCelnaWega%2DASp%2Ezo%2Eo%2FShared%20Documents%2FWYW%C3%93Z&viewid=c9ebcf23%2Dd938%2D4dfc%2D8ef2%2D74695f94ddee";

  const navigate = useNavigate();

  const fetchComments = async () => {
    try {
      const response = await axios.get(`https://wega-api.com/comments/${id}`, {
        headers: headers,
      });
      setComments(response.data);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  const processRowData = async (rowData) => {
    if (rowData.rodzajTransportu !== "Kurier MU MAN zakład") {
      if (
        rowData.rodzajTransportu === "Kurier" &&
        rowData.lokalizacjaTowaru === "MU MAN zakład"
      ) {
        try {
          // Tutaj tworzymy ciało żądania zawierające tylko id i rodzaj
          const requestBody = {
            rodzaj: "Kurier MU MAN zakład",
          };

          const response = await axios.put(
            `https://wega-api.com/updateRodzajKurierMan/${id}`,
            requestBody,
            {
              headers: headers, // Zakładam, że `headers` są zdefiniowane gdzieś wcześniej
            }
          );
        } catch (error) {
          console.error("Błąd podczas aktualizacji danych:", error);
        }
      }
    }
  };

  // Funkcja do pobierania danych
  const fetchRowData = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(
          `https://wega-api.com/detail/${nrRef}/${id}`,
          {
            headers: headers,
          }
        );
        setRowData(response.data[0]);
        await processRowData(response.data[0]); // Wywołanie funkcji przetwarzającej po pobraniu danych
        resolve(response.data[0]);
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleUsersChange = async () => {
    setTimeout(async () => {
      try {
        const response = await axios.get(
          "https://wega-api.com/list/uzytkownicy",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );

        setUzytkownicyList(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    }, 100);
  };

  const handleLokalizacjaChange = async () => {
    setTimeout(async () => {
      try {
        const response = await axios.get(
          "https://wega-api.com/list/lokalizacjaTransportow",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );

        setLokalizacjaList(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    }, 100);
  };

  const updateRodzajTransport = (rowData) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          (rowData.statusRealizacji === "Zrealizowane" &&
            rowData.rodzajTransportu === "Zakończono") ||
          (rowData.statusRealizacji === "Anulowane" &&
            rowData.rodzajTransportu === "Zakończono")
        ) {
          resolve("Data not updated");
        } else if (
          rowData.statusRealizacji === "Zrealizowane" ||
          rowData.statusRealizacji === "Anulowane"
        ) {
          const token = localStorage.getItem("token");
          const decoded = jwtDecode(token);
          const username = decoded.username;

          const formattedDate = moment
            .tz("Europe/Warsaw")
            .format("YYYY-MM-DDTHH:mm:ss");

          const updateData = {
            id: rowData.id,
            rodzajTransportu: "Zakończono",
          };

          const updateData1 = {
            id: rowData.id,
            endDate: formattedDate,
          };

          await axios.put(
            `https://wega-api.com/update/${updateData.id}`,
            updateData,
            {
              headers: {
                username: username,
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          );

          await axios.put(
            `https://wega-api.com/update/${updateData1.id}`,
            updateData1,
            {
              headers: {
                username: username,
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          );

          toast.success("Poprawnie zaktualizowany status zgłoszenia!");
          toast.success("Poprawnie zaktualizowany date zakończenia!");
          resolve("Data updated successfully");
        } else {
          resolve("Data not updated");
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  useEffect(() => {
    if (id) {
      fetchRowData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (rowData) {
      const fields = Object.keys(rowData);
      setAvailableFields(fields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData]);

  useEffect(() => {
    fetchComments();
    handleLokalizacjaChange();
    handleUsersChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFieldSelect = (field) => {
    setEditField(field);
    setIsEditing(true);
    setEditedData((prevEditedData) => ({
      ...prevEditedData,
      [field]: rowData[field] || "",
    }));
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Only set the editedData if the value is different from the original data
    if (name !== "data" || (name === "data" && value !== rowData.data)) {
      setEditedData((prevEditedData) => ({
        ...prevEditedData,
        [name]: value,
      }));
    }
  };

  const notify1 = () => {
    toast.success("Poprawnie zedytowano dane!", {
      autoClose: 1000, // Set autoClose to the number of milliseconds you want the notification to be visible, in this case, 1 second
    });
  };

  async function fetchData(id, rodzaj) {
    let verificationResponse, przygotowaneResponse;

    switch (rodzaj) {
      case "Import":
        verificationResponse = await axios.get(
          `https://wega-api.com/get-verification-status-przywoz/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        przygotowaneResponse = await axios.get(
          `https://wega-api.com/get-przygotowane-status-przywoz/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        break;

      case "Import - wpis do rejestru":
        verificationResponse = await axios.get(
          `https://wega-api.com/get-verification-status-przywoz-rejestr/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        przygotowaneResponse = await axios.get(
          `https://wega-api.com/get-przygotowane-status-przywoz-rejestr/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        break;

      case "Eksport":
        verificationResponse = await axios.get(
          `https://wega-api.com/get-verification-status-wywoz/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        przygotowaneResponse = await axios.get(
          `https://wega-api.com/get-przygotowane-status-wywoz/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        break;

      case "Eksport - wpis do rejestru":
        verificationResponse = await axios.get(
          `https://wega-api.com/get-verification-status-wywoz-rejestr/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        przygotowaneResponse = await axios.get(
          `https://wega-api.com/get-przygotowane-status-wywoz-rejestr/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        break;

      case "Import i Eksport":
        verificationResponse = await axios.get(
          `https://wega-api.com/get-verification-status-przywoz/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        przygotowaneResponse = await axios.get(
          `https://wega-api.com/get-przygotowane-status-przywoz/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        break;

      case "Uszlachetnianie czynne":
        verificationResponse = await axios.get(
          `https://wega-api.com/get-verification-status-przywoz/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        przygotowaneResponse = await axios.get(
          `https://wega-api.com/get-przygotowane-status-przywoz/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        break;

      case "Uszlachetnianie bierne":
        verificationResponse = await axios.get(
          `https://wega-api.com/get-verification-status-wywoz/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        przygotowaneResponse = await axios.get(
          `https://wega-api.com/get-przygotowane-status-wywoz/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        break;

      case "Powrotny przywóz":
        verificationResponse = await axios.get(
          `https://wega-api.com/get-verification-status-przywoz/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        przygotowaneResponse = await axios.get(
          `https://wega-api.com/get-przygotowane-status-przywoz/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        break;

      case "Powrotny wywóz":
        verificationResponse = await axios.get(
          `https://wega-api.com/get-verification-status-wywoz/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        przygotowaneResponse = await axios.get(
          `https://wega-api.com/get-przygotowane-status-wywoz/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        break;
      default:
        throw new Error("Unsupported rodzaj value");
    }

    return {
      verificationData: verificationResponse.data,
      przygotowaneData: przygotowaneResponse.data,
    };
  }

  const handleEditSave = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem("token");
    const decoded = jwtDecode(token);
    const username = decoded.username;
    const role = decoded.role;
    try {
      const parseDate = (inputDate) => {
        const parts = inputDate.split("-");
        if (parts.length === 3) {
          return `${parts[2]}-${parts[1]}-${parts[0]}`;
        }
        return inputDate;
      };

      if (editedData.data && editedData.data !== rowData.data) {
        const dateFormatRegex =
          /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/;
        if (!editedData.data.match(dateFormatRegex)) {
          toast.error("Zły format danych. Powinien być np. 30-10-2023");
          return;
        }
      }

      if (role !== "admin") {
        if (
          editedData.statusRealizacji === "Przygotowane" &&
          rowData.SAD === ""
        ) {
          toast.error(
            "Nie można zmienić statusu na Przygotowane, brak wpisanego numeru SAD"
          );
          return;
        }

        if (
          editedData.statusRealizacji === "Przygotowane" &&
          rowData.plikiBox === 0
        ) {
          toast.error(
            "Nie można zmienić statusu na Przygotowane, brak zaznaczonego znacznika plik do zaczytania"
          );
          return;
        }

        if (
          editedData.statusRealizacji === "Zrealizowane" &&
          rowData.MRN === ""
        ) {
          toast.error(
            "Nie można zmienić statusu na zrealizowane, brak wpisanego numeru MRN"
          );
          return;
        }
      }

      if (
        rowData.rodzaj === "Import" ||
        rowData.rodzaj === "Eksport" ||
        rowData.rodzaj === "Eksport - wpis do rejestru" ||
        rowData.rodzaj === "Import - wpis do rejestru" ||
        rowData.rodzaj === "Import i Eksport" ||
        rowData.rodzaj === "Uszlachetnianie czynne" ||
        rowData.rodzaj === "Uszlachetnianie bierne" ||
        rowData.rodzaj === "Powrotny przywóz" ||
        rowData.rodzaj === "Powrotny wywóz"
      ) {
        const { verificationData, przygotowaneData } = await fetchData(
          id,
          rowData.rodzaj
        );

        //Zmiana statusu na Przygotowane z Do przygotowania
        //Na ten status moze zmienic tylko os. przygotowujaca, os.weryfikujaca lub admin
        //Lista kontrolna dla os. przygotowującej musi byc ukonczona
        //Sprawdzone i działa
        const isPrzygotowaneStatus =
          editedData.statusRealizacji === "Przygotowane";

        if (
          isPrzygotowaneStatus &&
          przygotowaneData.Przygotowane !== 1 &&
          (role !== "admin" ||
            username !== rowData.osobaWeryfikujacaOdprawe ||
            username !== rowData.osobaPrzygotowujacaOdprawe)
        ) {
          toast.error(
            "Nie można zmienić statusu na Przygotowane, ponieważ Lista kontrolna nie została w całości uzupełniona!"
          );
          return; // Nie kontynuuj zapisywania formularza
        }

        //Zmiana statusu na Do poprawy z Do weryfikacji
        //Musi być przeprowadzona weryfikacja w liscie kontrolnej aby moc zmienic ten status na Do poprawy
        if (
          editedData.statusRealizacji === "Do poprawy" &&
          rowData.statusRealizacji === "Do weryfikacji" &&
          (verificationData.Zweryfikowane === undefined ||
            verificationData.Zweryfikowane === null)
        ) {
          toast.error(
            "Nie można zmienić na status do poprawy jeżeli weryfikacja nie została przeprowadzona"
          );
          return;
        }

        if (
          editedData.statusRealizacji === "Do wysłania" &&
          rowData.statusRealizacji === "Do weryfikacji" &&
          (verificationData.Zweryfikowane === undefined ||
            verificationData.Zweryfikowane === null)
        ) {
          toast.error(
            "Nie można zmienić na status do wysłania jeżeli weryfikacja nie została przeprowadzona"
          );
          return;
        }

        //Zmiana statusu na Do wysłania z Do poprawy
        //Moze zmienic tylko os. przygotowujaca, os.weryfikujaca, rola admin
        //Musi zostac wykonana weryfikacja w liscie kontrolnej
        if (
          (verificationData.Zweryfikowane === 0 ||
            verificationData.Zweryfikowane === 1) &&
          editedData.statusRealizacji === "Do wysłania" &&
          rowData.statusRealizacji === "Do poprawy" &&
          username !== rowData.osobaPrzygotowujacaOdprawe &&
          username !== rowData.osobaWeryfikujacaOdprawe &&
          role !== "admin"
        ) {
          toast.error(
            "Nie można zmienić statusu na Do wysłania, ponieważ może zmienić ten status tylko osoba, która przygotowywała odprawę, osoba weryfikująca, administrator lub nie została przeprowadzona weryfikacja."
          );
          return; // Nie kontynuuj zapisywania formularza
        }
      }

      if (role !== "admin") {
        if (
          editedData.statusRealizacji === "Przygotowane" &&
          rowData.statusRealizacji !== "Do przygotowania"
        ) {
          toast.error(
            "Nie można zmienić statusu na Przygotowane, jeśli poprzedni status jest różny od Do przygotowania"
          );
          return;
        }

        if (
          editedData.statusRealizacji === "Do weryfikacji" &&
          rowData.statusRealizacji !== "Przygotowane"
        ) {
          toast.error(
            "Nie można zmienić statusu na Do weryfikacji, jeśli poprzedni status jest różny od Przygotowane"
          );
          return;
        }

        if (
          editedData.statusRealizacji === "Do poprawy" &&
          rowData.statusRealizacji !== "Do weryfikacji"
        ) {
          toast.error(
            "Nie można zmienić statusu na Do poprawy, jeśli poprzedni status jest różny od Do weryfikacji"
          );
          return;
        }

        if (
          editedData.statusRealizacji === "Do wysłania" &&
          rowData.statusRealizacji !== "Do weryfikacji" &&
          rowData.statusRealizacji !== "Do poprawy" &&
          rowData.statusRealizacji !== "Przygotowane"
        ) {
          toast.error(
            "Nie można zmienić statusu na Do wysłania, jeśli poprzedni status jest różny od Do weryfikacji lub Do poprawy lub Przygotowane"
          );
          return;
        }

        if (
          editedData.statusRealizacji === "Wysłane" &&
          rowData.statusRealizacji !== "Do wysłania"
        ) {
          toast.error(
            "Nie można zmienić statusu na Wysłane, jeśli poprzedni status jest różny od Do wysłania"
          );
          return;
        }

        if (
          editedData.statusRealizacji === "Zrealizowane" &&
          rowData.statusRealizacji !== "Wysłane"
        ) {
          toast.error(
            "Nie można zmienić statusu na Zrealizowane, jeśli poprzedni status jest różny od Wysłane"
          );
          return;
        }
      }

      let formattedEditedData = { ...editedData };
      if (editedData.data && rowData.data) {
        const editedDateFormatted = parseDate(editedData.data);
        const originalDateFormatted = parseDate(rowData.data);

        if (editedDateFormatted !== originalDateFormatted) {
          formattedEditedData = {
            ...editedData,
            data: editedDateFormatted,
          };
        }
      }

      await axios.put(
        `https://wega-api.com/update/${id}`,
        formattedEditedData,
        {
          headers: {
            username: username,
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      notify1();
      setEditField(null);
      setIsEditing(false);

      if (
        (rowData.statusRealizacji === "Anulowane" &&
          rowData.rodzajTransportu === "Zakończono") ||
        (rowData.statusRealizacji === "Zrealizowane" &&
          rowData.rodzajTransportu === "Zakończono")
      ) {
        return;
      } else {
        fetchRowData()
          .then((rowData) => {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve(rowData);
              }, 100);
            });
          })
          .then((rowData) => {
            return updateRodzajTransport(rowData);
          })
          .then(() => {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve();
              }, 100);
            });
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    } catch (error) {
      console.error("Error updating data:", error);
      toast.error("Error updating data.");
    }
  };

  const handleEditCancel = () => {
    setEditField(null);
    setIsEditing(false);
  };

  if (!rowData || !id) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress color="secondary" />
        <Typography variant="body1">Loading...</Typography>
      </div>
    );
  }

  const handleBack = () => {
    if (rowData.rodzaj === "Tranzyt") {
      navigate("/tranzyt");
    } else {
      navigate("/user");
    }
  };

  function shouldDisplayField(field, rowData, role) {
    // Filtrowanie pól dla admina
    if (role === "admin") {
      if (
        field !== "createdBy" &&
        field !== "utworzono" &&
        field !== "nrRef" &&
        field !== "endDate" &&
        field !== "id" &&
        !field.endsWith("Box") &&
        !(
          rowData.rodzaj !== "Tranzyt" &&
          [
            "waluta",
            "wagaNetto",
            "wagaBrutto",
            "rodzajTranzyt",
            "urzadDocelowy",
            "kosztT1",
            "nrObcegoTranzytu",
            "cloKwota",
            "nrPojazdu",
            "nrRejestracyjnyT1",
            "nrTranzytuWega",
            "DSK",
          ].includes(field)
        )
      ) {
        return true;
      }
      return false;
    } else {
      // Filtrowanie pól dla innych użytkowników
      if (
        field !== "createdBy" &&
        field !== "utworzono" &&
        field !== "nrRef" &&
        field !== "endDate" &&
        field !== "id" &&
        !field.endsWith("Box") &&
        !(
          (rowData.statusRealizacji === "Przygotowane" &&
            field === "osobaPrzygotowujacaOdprawe") ||
          (rowData.statusRealizacji === "Do wysłania" &&
            field === "osobaPrzygotowujacaOdprawe") ||
          (rowData.statusRealizacji === "Wysłane" &&
            field === "osobaPrzygotowujacaOdprawe") ||
          (rowData.statusRealizacji === "Zrealizowane" &&
            field === "osobaPrzygotowujacaOdprawe") ||
          (rowData.statusRealizacji === "Anulowane" &&
            field === "osobaPrzygotowujacaOdprawe")
        ) &&
        !(
          rowData.rodzaj !== "Tranzyt" &&
          [
            "waluta",
            "wagaNetto",
            "wagaBrutto",
            "rodzajTranzyt",
            "urzadDocelowy",
            "kosztT1",
            "nrObcegoTranzytu",
            "cloKwota",
            "nrPojazdu",
            "nrRejestracyjnyT1",
            "nrTranzytuWega",
            "DSK",
          ].includes(field)
        )
      ) {
        return true;
      }
      return false;
    }
  }

  const handlePrzywoz = (
    nrRef,
    osobaPrzygotowujaca,
    osobaWeryfikujaca,
    status,
    rodzajOdprawy
  ) => {
    navigate(`/PrzywozZwykly/${rowData.nrRef}/${rowData.id}`, {
      state: {
        nrRef: rowData.nrRef,
        osobaPrzygotowujaca: rowData.osobaPrzygotowujacaOdprawe,
        osobaWeryfikujaca: rowData.osobaWeryfikujacaOdprawe,
        status: rowData.statusRealizacji,
        rodzajOdprawy: rowData.rodzaj,
      },
    });
  };

  const handleEksport = (
    nrRef,
    osobaPrzygotowujaca,
    osobaWeryfikujaca,
    status,
    rodzajOdprawy
  ) => {
    navigate(`/WywozZwykly/${rowData.nrRef}/${rowData.id}`, {
      state: {
        nrRef: rowData.nrRef,
        osobaPrzygotowujaca: rowData.osobaPrzygotowujacaOdprawe,
        osobaWeryfikujaca: rowData.osobaWeryfikujacaOdprawe,
        status: rowData.statusRealizacji,
        rodzajOdprawy: rowData.rodzaj,
      },
    });
  };

  const handleEksportRejestr = (
    nrRef,
    osobaPrzygotowujaca,
    osobaWeryfikujaca,
    status,
    rodzajOdprawy
  ) => {
    navigate(`/WywozRejestr/${rowData.nrRef}/${rowData.id}`, {
      state: {
        nrRef: rowData.nrRef,
        osobaPrzygotowujaca: rowData.osobaPrzygotowujacaOdprawe,
        osobaWeryfikujaca: rowData.osobaWeryfikujacaOdprawe,
        status: rowData.statusRealizacji,
        rodzajOdprawy: rowData.rodzaj,
      },
    });
  };

  const handleImportRejestr = (
    nrRef,
    osobaPrzygotowujaca,
    osobaWeryfikujaca,
    status,
    rodzajOdprawy
  ) => {
    navigate(`/PrzywozRejestr/${rowData.nrRef}/${rowData.id}`, {
      state: {
        nrRef: rowData.nrRef,
        osobaPrzygotowujaca: rowData.osobaPrzygotowujacaOdprawe,
        osobaWeryfikujaca: rowData.osobaWeryfikujacaOdprawe,
        status: rowData.statusRealizacji,
        rodzajOdprawy: rowData.rodzaj,
      },
    });
  };

  //Import i Eksport
  const handleImportIEksport = (
    nrRef,
    osobaPrzygotowujaca,
    osobaWeryfikujaca,
    status,
    rodzajOdprawy
  ) => {
    navigate(`/ImportIEksport/${rowData.nrRef}/${rowData.id}`, {
      state: {
        nrRef: rowData.nrRef,
        osobaPrzygotowujaca: rowData.osobaPrzygotowujacaOdprawe,
        osobaWeryfikujaca: rowData.osobaWeryfikujacaOdprawe,
        status: rowData.statusRealizacji,
        rodzajOdprawy: rowData.rodzaj,
      },
    });
  };

  //Uszlachetnianie czynne
  const handleUszlachetnianieCzynne = (
    nrRef,
    osobaPrzygotowujaca,
    osobaWeryfikujaca,
    status,
    rodzajOdprawy
  ) => {
    navigate(`/UszlachetnianieCzynne/${rowData.nrRef}/${rowData.id}`, {
      state: {
        nrRef: rowData.nrRef,
        osobaPrzygotowujaca: rowData.osobaPrzygotowujacaOdprawe,
        osobaWeryfikujaca: rowData.osobaWeryfikujacaOdprawe,
        status: rowData.statusRealizacji,
        rodzajOdprawy: rowData.rodzaj,
      },
    });
  };

  //Uszlachetnianie bierne
  const handleUszlachetnianieBierne = (
    nrRef,
    osobaPrzygotowujaca,
    osobaWeryfikujaca,
    status,
    rodzajOdprawy
  ) => {
    navigate(`/UszlachetnianieBierne/${rowData.nrRef}/${rowData.id}`, {
      state: {
        nrRef: rowData.nrRef,
        osobaPrzygotowujaca: rowData.osobaPrzygotowujacaOdprawe,
        osobaWeryfikujaca: rowData.osobaWeryfikujacaOdprawe,
        status: rowData.statusRealizacji,
        rodzajOdprawy: rowData.rodzaj,
      },
    });
  };

  //Powrotny przywóz
  const handlePowrotnyPrzywoz = (
    nrRef,
    osobaPrzygotowujaca,
    osobaWeryfikujaca,
    status,
    rodzajOdprawy
  ) => {
    navigate(`/PowrotnyPrzywoz/${rowData.nrRef}/${rowData.id}`, {
      state: {
        nrRef: rowData.nrRef,
        osobaPrzygotowujaca: rowData.osobaPrzygotowujacaOdprawe,
        osobaWeryfikujaca: rowData.osobaWeryfikujacaOdprawe,
        status: rowData.statusRealizacji,
        rodzajOdprawy: rowData.rodzaj,
      },
    });
  };

  //Powrotny wywóz
  const handlePowrotnyWywoz = (
    nrRef,
    osobaPrzygotowujaca,
    osobaWeryfikujaca,
    status,
    rodzajOdprawy
  ) => {
    navigate(`/PowrotnyWywoz/${rowData.nrRef}/${rowData.id}`, {
      state: {
        nrRef: rowData.nrRef,
        osobaPrzygotowujaca: rowData.osobaPrzygotowujacaOdprawe,
        osobaWeryfikujaca: rowData.osobaWeryfikujacaOdprawe,
        status: rowData.statusRealizacji,
        rodzajOdprawy: rowData.rodzaj,
      },
    });
  };

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{ paper: classes.drawerPaper }}
      >
        <Toolbar />
        <List>
          <ListItem button onClick={handleBack}>
            <ListItemIcon>
              <ArrowBackIcon />
            </ListItemIcon>
            <ListItemText primary="Powrót do tabeli " />
          </ListItem>
          <ListItem button onClick={() => navigate("/login")}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Wyloguj" />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        <Typography variant="h4" component="h1" className={classes.tableTitle}>
          Szczegóły zgłoszenia o numerze Referencyjnym: {rowData.nrRef}
        </Typography>
        {editField ? (
          <form className={classes.editForm}>
            {editField === "lokalizacjaTowaru" && isEditing ? (
              <FormControl className={classes.formControlWidth}>
                <InputLabel id="select-list-label">
                  Lokalizacja towaru
                </InputLabel>
                <Select
                  labelId="select-list-label"
                  id="select-list"
                  value={editedData.lokalizacjaTowaru}
                  onChange={handleInputChange}
                  name={editField}
                  className={classes.marginBottom} // Apply marginBottom style to the TextField
                >
                  {lokalizacjaList.map((item) => (
                    <MenuItem key={item.id} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : editField === "osobaWysylajacaOdprawe" && isEditing ? (
              <FormControl className={classes.formControlWidth}>
                <InputLabel id="select-list-label">
                  Osoba wysyłająca odprawę
                </InputLabel>
                <Select
                  labelId="select-list-label"
                  id="select-list"
                  value={editedData.osobaWysylajacaOdprawe}
                  onChange={handleInputChange}
                  name={editField}
                  className={classes.marginBottom}
                >
                  <MenuItem value="">Brak przypisania osoby</MenuItem>
                  {uzytkownicyList.map((item) => (
                    <MenuItem key={item.id} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : editField === "osobaPrzygotowujacaOdprawe" && isEditing ? (
              <FormControl className={classes.formControlWidth}>
                <InputLabel id="select-list-label">
                  Osoba realizująca odprawę
                </InputLabel>
                <Select
                  labelId="select-list-label"
                  id="select-list"
                  value={editedData.osobaPrzygotowujacaOdprawe}
                  onChange={handleInputChange}
                  name={editField}
                  className={classes.marginBottom}
                >
                  <MenuItem value="">Brak przypisania osoby</MenuItem>
                  {uzytkownicyList.map((item) => (
                    <MenuItem key={item.id} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : editField === "osobaMonitorujacaOdprawe" && isEditing ? (
              <FormControl className={classes.formControlWidth}>
                <InputLabel id="select-list-label">
                  Osoba wysyłająca odprawę
                </InputLabel>
                <Select
                  labelId="select-list-label"
                  id="select-list"
                  value={editedData.osobaMonitorujacaOdprawe}
                  onChange={handleInputChange}
                  name={editField}
                  className={classes.marginBottom}
                >
                  <MenuItem value="">Brak przypisania osoby</MenuItem>
                  {uzytkownicyList.map((item) => (
                    <MenuItem key={item.id} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : editField === "osobaWeryfikujacaOdprawe" && isEditing ? (
              <FormControl className={classes.formControlWidth}>
                <InputLabel id="select-list-label">
                  Osoba weryfikująca odprawę
                </InputLabel>
                <Select
                  labelId="select-list-label"
                  id="select-list"
                  value={editedData.osobaMonitorujacaOdprawe}
                  onChange={handleInputChange}
                  name={editField}
                  className={classes.marginBottom}
                >
                  <MenuItem value="">Brak przypisania osoby</MenuItem>
                  {uzytkownicyList.map((item) => (
                    <MenuItem key={item.id} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : editField === "statusRealizacji" && isEditing ? (
              <FormControl className={classes.formControlWidth}>
                <InputLabel id="select-list-label">
                  Status realizacji
                </InputLabel>
                <Select
                  labelId="select-list-label"
                  id="select-list"
                  value={editedData.statusRealizacji}
                  onChange={handleInputChange}
                  name={editField}
                  className={classes.marginBottom}
                >
                  {role === "admin"
                    ? // Show all options if role is "admin"
                      [
                        "Do przygotowania",
                        "Przygotowane",
                        "Do weryfikacji",
                        "Do poprawy",
                        "Do wysłania",
                        "Wysłane",
                        "Zrealizowane",
                        "Anulowane",
                      ].map((status) => (
                        <MenuItem key={status} value={status}>
                          {status}
                        </MenuItem>
                      ))
                    : // Show subset of options based on rowData.statusRealizacji
                      [
                        "Do przygotowania",
                        "Przygotowane",
                        "Do weryfikacji",
                        "Do poprawy",
                        "Do wysłania",
                        "Wysłane",
                        "Zrealizowane",
                        "Anulowane",
                      ]
                        .filter((status) =>
                          // Add conditions based on rowData.statusRealizacji
                          rowData.statusRealizacji === "Do przygotowania"
                            ? true
                            : rowData.statusRealizacji === "Przygotowane" &&
                              [
                                "Do weryfikacji",
                                "Do poprawy",
                                "Do wysłania",
                                "Wysłane",
                                "Zrealizowane",
                                "Anulowane",
                              ].includes(status)
                            ? true
                            : rowData.statusRealizacji === "Do weryfikacji" &&
                              [
                                "Do poprawy",
                                "Do wysłania",
                                "Wysłane",
                                "Zrealizowane",
                                "Anulowane",
                              ].includes(status)
                            ? true
                            : rowData.statusRealizacji === "Do poprawy" &&
                              [
                                "Do wysłania",
                                "Wysłane",
                                "Zrealizowane",
                                "Anulowane",
                              ].includes(status)
                            ? true
                            : rowData.statusRealizacji === "Do wysłania" &&
                              ["Wysłane", "Zrealizowane", "Anulowane"].includes(
                                status
                              )
                            ? true
                            : rowData.statusRealizacji === "Wysłane" &&
                              ["Zrealizowane", "Anulowane"].includes(status)
                            ? true
                            : rowData.statusRealizacji === "Zrealizowane" &&
                              ["Zrealizowane"].includes(status)
                            ? true
                            : rowData.statusRealizacji === "Anulowane" &&
                              ["Anulowane"].includes(status)
                            ? true
                            : false
                        )
                        .map((status) => (
                          <MenuItem key={status} value={status}>
                            {status}
                          </MenuItem>
                        ))}
                </Select>
              </FormControl>
            ) : editField === "rodzaj" && isEditing ? (
              <FormControl className={classes.formControlWidth}>
                <InputLabel id="select-list-label">Rodzaj</InputLabel>
                <Select
                  labelId="select-list-label"
                  id="select-list"
                  value={editedData.rodzaj}
                  onChange={handleInputChange}
                  name={editField}
                  className={classes.marginBottom} // Apply marginBottom style to the TextField
                >
                  <MenuItem value="Import">Import</MenuItem>
                  <MenuItem value="Eksport">Eksport</MenuItem>
                  <MenuItem value="Tranzyt">Tranzyt</MenuItem>
                  <MenuItem value="Import i Eksport">Import i Eksport</MenuItem>
                  <MenuItem value="Wyprowadzenie ze składu">
                    Wyprowadzenie ze składu
                  </MenuItem>
                  <MenuItem value="Wprowadzenie na skład">
                    Wprowadzenie na skład
                  </MenuItem>
                  <MenuItem value="Uszlachetnianie czynne">
                    Uszlachetnianie czynne
                  </MenuItem>
                  <MenuItem value="Uszlachetnianie bierne">
                    Uszlachetnianie bierne
                  </MenuItem>
                  <MenuItem value="Powrotny przywóz">Powrotny przywóz</MenuItem>
                  <MenuItem value="Powrotny wywóz">Powrotny wywóz</MenuItem>
                  <MenuItem value="Eksport - wpis do rejestru">
                    Eksport - wpis do rejestru
                  </MenuItem>
                  <MenuItem value="Import - wpis do rejestru">
                    Import - wpis do rejestru
                  </MenuItem>
                </Select>
              </FormControl>
            ) : editField === "Rewizja" && isEditing ? (
              <FormControl
                component="fieldset"
                className={classes.formControlWidth}
              >
                <FormLabel component="legend">
                  Czy została przeprowadzona rewizja?
                </FormLabel>
                <RadioGroup
                  aria-label=" Czy została przeprowadzona rewizja?"
                  name="Rewizja"
                  value={editedData.Rewizja}
                  onChange={handleInputChange}
                >
                  <FormControlLabel
                    value="Tak"
                    control={<Radio color="primary" />}
                    label="Tak"
                  />
                  <FormControlLabel
                    value="Nie"
                    control={<Radio color="primary" />}
                    label="Nie"
                    className={classes.marginBottom} // Apply marginBottom style to the TextField
                  />
                </RadioGroup>
              </FormControl>
            ) : editField === "dokPrzedPrzybyciem" && isEditing ? (
              <FormControl
                component="fieldset"
                className={classes.formControlWidth}
              >
                <FormLabel component="legend">
                  Czy zostały dostarczone dokumenty przed przybyciem?
                </FormLabel>
                <RadioGroup
                  name="dokPrzedPrzybyciem"
                  value={editedData.dokPrzedPrzybyciem}
                  onChange={handleInputChange}
                >
                  <FormControlLabel
                    value="Tak"
                    control={<Radio color="primary" />}
                    label="Tak"
                  />
                  <FormControlLabel
                    value="Nie"
                    control={<Radio color="primary" />}
                    label="Nie"
                    className={classes.marginBottom} // Apply marginBottom style to the TextField
                  />
                </RadioGroup>
              </FormControl>
            ) : editField === "data" && isEditing ? (
              <TextField
                className={classes.inputField}
                label="Format DD-MM-YYYY"
                variant="outlined"
                name="data"
                value={editedData.data}
                onChange={handleInputChange}
                autoComplete="off"
              />
            ) : editField === "rodzajTransportu" && isEditing ? (
              <FormControl
                component="fieldset"
                className={classes.formControlWidth}
              >
                <FormLabel component="legend">Rodzaj transportu?</FormLabel>
                <RadioGroup
                  name="rodzajTransportu"
                  value={editedData.rodzajTransportu}
                  onChange={handleInputChange}
                >
                  <FormControlLabel
                    value="Kurier"
                    control={<Radio color="primary" />}
                    label="Kurier"
                  />
                  <FormControlLabel
                    value="Na Kołach"
                    control={<Radio color="primary" />}
                    label="Na Kołach"
                  />
                  <FormControlLabel
                    value="W drodze"
                    control={<Radio color="primary" />}
                    label="W drodze"
                  />
                </RadioGroup>
              </FormControl>
            ) : editField === "waluta" && isEditing ? (
              <FormControl
                component="fieldset"
                className={classes.formControlWidth}
              >
                <FormLabel component="legend">Waluta</FormLabel>
                <RadioGroup
                  aria-label="Waluta"
                  name="waluta"
                  value={editedData.waluta}
                  onChange={handleInputChange}
                >
                  <FormControlLabel
                    value="EUR"
                    control={<Radio color="primary" />}
                    label="Euro"
                  />
                  <FormControlLabel
                    value="USD"
                    control={<Radio color="primary" />}
                    label="USD"
                  />
                  <FormControlLabel
                    value="Zł"
                    control={<Radio color="primary" />}
                    label="Zł"
                  />
                  <FormControlLabel
                    value="GBP"
                    control={<Radio color="primary" />}
                    label="GBP"
                  />
                </RadioGroup>
              </FormControl>
            ) : editField === "rodzajTranzyt" && isEditing ? (
              <FormControl
                component="fieldset"
                className={classes.formControlWidth}
              >
                <FormLabel component="legend">Rodzaj</FormLabel>
                <RadioGroup
                  aria-label="Rodzaj"
                  name="rodzajTranzyt"
                  value={editedData.rodzajTranzyt}
                  onChange={handleInputChange}
                >
                  <FormControlLabel
                    value="Import wega"
                    control={<Radio color="primary" />}
                    label="Import wega"
                  />
                  <FormControlLabel
                    value="Import inna agencja"
                    control={<Radio color="primary" />}
                    label="Import inna agencja"
                  />
                  <FormControlLabel
                    value="Tranzyt"
                    control={<Radio color="primary" />}
                    label="Tranzyt"
                  />
                  <FormControlLabel
                    value="Wprowadzenie na mcs"
                    control={<Radio color="primary" />}
                    label="Wprowadzenie na mcs"
                  />
                </RadioGroup>
              </FormControl>
            ) : (
              <TextField
                className={classes.inputField}
                label={editField}
                variant="outlined"
                name={editField}
                autoComplete="off"
                value={
                  isEditing
                    ? editedData[editField] || ""
                    : rowData[editField] || ""
                }
                onChange={handleInputChange}
              />
            )}
            {/* Add other fields for editing */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleEditSave}
              type="submit"
            >
              Save
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleEditCancel}
            >
              Cancel
            </Button>
          </form>
        ) : (
          <>
            <div className={classes.fieldList}>
              <Typography variant="h6" className={classes.categoryHeading}>
                Wybierz pole do edycji:
              </Typography>
              {Object.entries(categories).map(
                ([categoryName, fieldsInCategory]) => {
                  // Warunek nie renderowania kategorii "Szczegóły Finansowe i Tranzytu"
                  if (
                    categoryName === "Szczegóły Finansowe i Tranzytu" &&
                    rowData.rodzaj !== "Tranzyt"
                  ) {
                    return null;
                  }

                  return (
                    <div key={categoryName} className={classes.categoryRow}>
                      <Typography
                        variant="h6"
                        className={classes.categoryHeading}
                      >
                        {categoryName}
                      </Typography>
                      <div className={classes.fieldBlocksRow}>
                        {fieldsInCategory.map((field) => {
                          if (shouldDisplayField(field, rowData, role)) {
                            const customLabel = fieldLabels[field] || field;
                            return (
                              <div
                                key={field}
                                className={classes.fieldBlock}
                                onClick={() => handleFieldSelect(field)}
                              >
                                <Typography className={classes.fieldLabel}>
                                  {customLabel}
                                </Typography>
                              </div>
                            );
                          }
                          return null;
                        })}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </>
        )}
        <hr className={classes.wider} />
        <Typography variant="h6">
          <strong>Rodzaj zgłoszenia:</strong> {rowData.rodzaj}
        </Typography>
        <Typography variant="h6">
          <strong>Lista kontrolna: </strong>{" "}
          {rowData.rodzaj === "Import" ? (
            <Button onClick={handlePrzywoz} variant="contained" color="primary">
              Lista
            </Button>
          ) : rowData.rodzaj === "Eksport" ? (
            <Button onClick={handleEksport} variant="contained" color="primary">
              Lista
            </Button>
          ) : rowData.rodzaj === "Eksport - wpis do rejestru" ? (
            <Button
              onClick={handleEksportRejestr}
              variant="contained"
              color="primary"
            >
              Lista
            </Button>
          ) : rowData.rodzaj === "Import - wpis do rejestru" ? (
            <Button
              onClick={handleImportRejestr}
              variant="contained"
              color="primary"
            >
              Lista
            </Button>
          ) : rowData.rodzaj === "Import i Eksport" ? (
            <Button
              onClick={handleImportIEksport}
              variant="contained"
              color="primary"
            >
              Lista
            </Button>
          ) : rowData.rodzaj === "Uszlachetnianie czynne" ? (
            <Button
              onClick={handleUszlachetnianieCzynne}
              variant="contained"
              color="primary"
            >
              Lista
            </Button>
          ) : rowData.rodzaj === "Uszlachetnianie bierne" ? (
            <Button
              onClick={handleUszlachetnianieBierne}
              variant="contained"
              color="primary"
            >
              Lista
            </Button>
          ) : rowData.rodzaj === "Powrotny przywóz" ? (
            <Button
              onClick={handlePowrotnyPrzywoz}
              variant="contained"
              color="primary"
            >
              Lista
            </Button>
          ) : rowData.rodzaj === "Powrotny wywóz" ? (
            <Button
              onClick={handlePowrotnyWywoz}
              variant="contained"
              color="primary"
            >
              Lista
            </Button>
          ) : null}
        </Typography>
        <Typography variant="h6">
          <strong>Status realizacji odprawy:</strong> {rowData.statusRealizacji}
        </Typography>
        <hr />
        <Typography variant="h6">
          <strong>Importer: </strong>
          {rowData.importer}
        </Typography>
        <Typography variant="h6">
          <strong>Eksporter:</strong> {rowData.eksporter}
        </Typography>

        <Typography variant="h6">
          <strong>Nr. Faktury:</strong> {rowData.nrFaktury}
        </Typography>
        <Typography variant="h6">
          <strong>Nr. Tranzytu</strong> {rowData.nrTranzytu}
        </Typography>
        <Typography variant="h6">
          <strong>Data wygaśnięcia T1:</strong>{" "}
          {moment(rowData.data).tz("Europe/Warsaw").format("DD-MM-YYYY")}
        </Typography>
        <hr />
        <Typography variant="h6">
          <strong>Dokumenty przed przybyciem:</strong>{" "}
          {rowData.dokPrzedPrzybyciem}
        </Typography>
        <Typography variant="h6">
          <strong>Osoba przygotowujaca odprawe:</strong>{" "}
          {rowData.osobaPrzygotowujacaOdprawe}
        </Typography>
        <Typography variant="h6">
          <strong>Osoba weryfikująca odprawe:</strong>{" "}
          {rowData.osobaWeryfikujacaOdprawe}
        </Typography>
        <Typography variant="h6">
          <strong>Osoba wysyłająca odprawe:</strong>{" "}
          {rowData.osobaWysylajacaOdprawe}
        </Typography>
        <Typography variant="h6">
          <strong>Osoba monitorująca odprawe:</strong>{" "}
          {rowData.osobaMonitorujacaOdprawe}
        </Typography>
        <Typography variant="h6">
          <strong>Osoba, która utworzyła odprawe:</strong> {rowData.createdBy}
        </Typography>
        <Typography variant="h6">
          <strong>Nr. rejestracyjny pojazdu/kuriera:</strong>{" "}
          {rowData.nrRejestracyjnyPojazdyKurier}
        </Typography>
        <Typography variant="h6">
          <strong>Lokalizacja towaru:</strong> {rowData.lokalizacjaTowaru}
        </Typography>
        <Typography variant="h6">
          <strong>Adresat dokumentów:</strong> {rowData.adresatDokumentow}
        </Typography>
        <Typography variant="h6">
          <strong>Email korespondecyjny:</strong>{" "}
          {rowData.emailKorespondencyjny}
        </Typography>
        <hr />
        <Typography variant="h6">
          <strong>Nr. SAD:</strong> {rowData.SAD}
        </Typography>
        <Typography variant="h6">
          <strong>Nr. MRN:</strong> {rowData.MRN}
        </Typography>
        <Typography variant="h6">
          <strong>Rewizja:</strong> {rowData.Rewizja}
        </Typography>
        <Typography variant="h6">
          <strong>Zgłoszenie utworzono dnia:</strong>{" "}
          {moment(rowData.utworzono)
            .tz("Europe/Warsaw")
            .format("HH:mm, DD-MM-YYYY")}
        </Typography>
        <Typography variant="h6">
          <strong>Zgłoszenie zakończono dnia:</strong>{" "}
          {moment(rowData.endDate)
            .tz("Europe/Warsaw")
            .format("HH:mm, DD-MM-YYYY")}
        </Typography>
        <Typography variant="h6">
          <strong>Ścieżka do plików:</strong> {rowData.pliki}
        </Typography>
        <Typography variant="body1">
          <a
            className={classes.link} // Add the link style class
            href={
              rowData.rodzaj === "Import" ? importRodzajURL : eksportRodzajURL
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            Otwórz folder na dysku Sharepoint
          </a>
        </Typography>
        <hr className={classes.wider} />
        {rowData.rodzaj === "Tranzyt" && (
          <>
            <Typography variant="h6">
              <strong>Waluta:</strong> {rowData.waluta}
            </Typography>
            <Typography variant="h6">
              <strong>Waga netto:</strong> {rowData.wagaNetto}
            </Typography>
            <Typography variant="h6">
              <strong>Waga brutto:</strong> {rowData.wagaBrutto}
            </Typography>
            <Typography variant="h6">
              <strong>Rodzaj tranzytu:</strong> {rowData.rodzajTranzyt}
            </Typography>
            <Typography variant="h6">
              <strong>Urząd docelowy:</strong> {rowData.urzadDocelowy}
            </Typography>
            <Typography variant="h6">
              <strong>Koszt T1:</strong> {rowData.kosztT1}
            </Typography>
            <Typography variant="h6">
              <strong>Numer obcego tranzytu:</strong> {rowData.nrObcegoTranzytu}
            </Typography>
            <Typography variant="h6">
              <strong>DSK:</strong> {rowData.DSK}
            </Typography>
            <Typography variant="h6">
              <strong>Cło / Kwota:</strong> {rowData.cloKwota}
            </Typography>
            <Typography variant="h6">
              <strong>Numer rejestracyjny pojazdu:</strong> {rowData.nrPojazdu}
            </Typography>
            <Typography variant="h6">
              <strong>Numer rejestracyjny T1:</strong>{" "}
              {rowData.nrRejestracyjnyT1}
            </Typography>
            <Typography variant="h6">
              <strong>Nr. tranzytu Wega:</strong> {rowData.nrTranzytuWega}
            </Typography>

            <hr className={classes.wider} />
          </>
        )}

        {/* Add other details as needed */}
        {/* Add the CommentSection component to render the comment section */}
        {id && <CommentSection id={id} />}
        <div>
          <EmailSender
            nrRef={nrRef}
            odbiorca={rowData.emailKorespondencyjny}
            rodzaj={rowData.rodzaj}
            importer={rowData.importer}
            eksporter={rowData.eksporter}
            lokalizacjaTowaru={rowData.lokalizacjaTowaru}
          />
        </div>

        <div>
          <Typography variant="h6" className={classes.komentarzeHeading}>
            Komentarze:
          </Typography>
          {comments.map((comment) => (
            <div key={comment.id}>
              <Typography variant="body2" className={classes.komentarzeBody}>
                {comment.comment}
              </Typography>
              <Typography variant="body2" className={classes.komentarzeBody}>
                Dodano dnia:{" "}
                {moment(comment.created_at).format("HH:mm, DD-MM-YYYY")}
              </Typography>
              <Typography variant="body2" className={classes.komentarzeBody}>
                Użytkownik: {comment.username}
              </Typography>
              <hr />
            </div>
          ))}
        </div>
        {/* <div>
          <Typography variant="h6" className={classes.komentarzeHeading}>
            Konwersja Plików:
          </Typography>
          <FileUpload />
          <AgregateData />
        </div> */}
      </main>
      <ToastContainer
        onClose={() => setTimeout(() => window.location.reload(), 100)} // Refresh the page after the toast notification is closed/>
      />
    </div>
  );
};

export default DetailPage;
