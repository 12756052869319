import { Route, Routes, Navigate } from "react-router-dom";
import { useState, useEffect } from "react"; // Import useState and useEffect
import Login from "./components/Login/Login";
import Signup from "./components/SignUp/SignUp";
import UserDashboard from "./components/UserDashboard/UserDashboard";
import DetailPage from "./components/DetailPage/DetailPage";
import Form from "./components/Form/Form";
import ChatApp from "./components/ChatApp/ChatApp";
import History from "./components/History/History";
import Lists from "./components/Lists/Lists";
import Calculator from "./components/Calculator/Calculator";
import Tranzyt from "./components/Tranzyt/Tranzyt";
import CalculatorZrealizowanePrzygotowane from "./components/Calculator/CalculatorZrealizowanePrzygotowane";
import Traces from "./components/Traces/Traces";
import AdminPanel from "./components/AdminPanel/AdminPanel";
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import PrzywozZwykly from "./components/CustomsDeclarationForms/PrzywozZwykly/PrzywozZwykly";
import WywozZwykly from "./components/CustomsDeclarationForms/WywozZwykly/WywozZwykly";
import WywozRejestr from "./components/CustomsDeclarationForms/WywozRejestr/WywozRejestr";
import PrzywozRejestr from "./components/CustomsDeclarationForms/PrzywozRejestr/PrzywozRejestr";
import RejestrWnioskow from "./components/RejestrWnioskow/RejestrWnioskow";
import NowyWniosek from "./components/RejestrWnioskow/NowyWniosek";
import EditWniosek1 from "./components/RejestrWnioskow/EditWniosek1";
Chart.register(ArcElement, Tooltip, Legend);

//import { AuthProvider } from './AuthContext';
function App() {
  // Check if the user is authenticated based on localStorage
  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("token") !== null
  );

  // Clear localStorage and reset authentication status on logout
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    setAuthenticated(false);
  };

  // Listen for changes in authentication status
  useEffect(() => {
    // Update authentication status based on localStorage
    setAuthenticated(localStorage.getItem("token") !== null);
  }, []);

  return (
      <Routes>
      <Route path="/" element={<Navigate to="/login" replace />} />
      <Route
        path="/login"
        element={<Login setAuthenticated={setAuthenticated} />}
      />
      <Route path="/signup" element={<Signup />} />
      <Route
        path="/user"
        element={
          authenticated ? (
            <UserDashboard handleLogout={handleLogout} />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
      <Route
        path="/detail/:nrRef/:id"
        element={
          authenticated ? <DetailPage /> : <Navigate to="/login" replace />
        }
      />
      <Route
        path="/form"
        element={authenticated ? <Form /> : <Navigate to="/login" replace />}
      />
      <Route
        path="/chat"
        element={authenticated ? <ChatApp /> : <Navigate to="/login" replace />}
      />
      <Route
        path="/history"
        element={authenticated ? <History /> : <Navigate to="/login" replace />}
      />
      <Route
        path="/list"
        element={authenticated ? <Lists /> : <Navigate to="/login" replace />}
      />
      <Route
        path="/calculator"
        element={authenticated ? <Calculator /> : <Navigate to="/login" replace />}
      />
      <Route
        path="/tranzyt"
        element={authenticated ? <Tranzyt /> : <Navigate to="/login" replace />}
      />
      <Route
        path="/calculatorZrealizowan&Przygotowane"
        element={authenticated ? <CalculatorZrealizowanePrzygotowane /> : <Navigate to="/login" replace />}
      />
      <Route
        path="/traces"
        element={authenticated ? <Traces /> : <Navigate to="/login" replace />}
      />
      <Route
        path="/adminPanel"
        element={authenticated ? <AdminPanel /> : <Navigate to="/login" replace />}
      />
      <Route
        path="/PrzywozZwykly/:nrRef/:id"
        element={
          authenticated ? <PrzywozZwykly /> : <Navigate to="/login" replace />
        }
      />
      <Route
        path="/WywozZwykly/:nrRef/:id"
        element={
          authenticated ? <WywozZwykly /> : <Navigate to="/login" replace />
        }
      />
      <Route
        path="/WywozRejestr/:nrRef/:id"
        element={
          authenticated ? <WywozRejestr /> : <Navigate to="/login" replace />
        }
      />
      <Route
        path="/PrzywozRejestr/:nrRef/:id"
        element={
          authenticated ? <PrzywozRejestr /> : <Navigate to="/login" replace />
        }
      />

      {/* Import and Export */}
      <Route
        path="/ImportIEksport/:nrRef/:id"
        element={
          authenticated ? <PrzywozZwykly /> : <Navigate to="/login" replace />
        }
      />

      {/* Uszlachetnianie czynne */}
      <Route
        path="/UszlachetnianieCzynne/:nrRef/:id"
        element={
          authenticated ? <PrzywozZwykly /> : <Navigate to="/login" replace />
        }
      />

      {/* Uszlachetnianie bierne */}
      <Route
        path="/UszlachetnianieBierne/:nrRef/:id"
        element={
          authenticated ? <WywozZwykly /> : <Navigate to="/login" replace />
        }
      />

      {/* Powrotny Przywoz */}
      <Route
        path="/PowrotnyPrzywoz/:nrRef/:id"
        element={
          authenticated ? <PrzywozZwykly /> : <Navigate to="/login" replace />
        }
      />

      {/* Powrotny Wywóz */}
      <Route
        path="/PowrotnyWywoz/:nrRef/:id"
        element={
          authenticated ? <WywozZwykly /> : <Navigate to="/login" replace />
        }
      />
      <Route
        path="/rejestrWnioskow"
        element={
          authenticated ? <RejestrWnioskow /> : <Navigate to="/login" replace />
        }
      />
      <Route
        path="/nowyWniosek"
        element={
          authenticated ? <NowyWniosek /> : <Navigate to="/login" replace />
        }
      />
      <Route
        path="/rejestrWnioskow/:id"
        element={
          authenticated ? <EditWniosek1 /> : <Navigate to="/login" replace />
        }
      />
    </Routes>
    
  );
}

export default App;
